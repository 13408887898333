import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { Card, Button, Header, Segment, Message } from 'semantic-ui-react';
import { retrieve } from './Encryption';
import './AdminViewAssessments.css';

const AdminGenerateReports = () => {
    const navigate=useNavigate()
   

    return (
        <div className="view-teachers-container" >
            <Header as="h2" textAlign="center" className='header'>
               Generate Assessments Reports
            </Header>

           
                <Segment>
                    <Button className="assign-button2" style={{ backgroundColor:"#8ac429" , fontFamily:"cursive" , fontSize:"17px"}} onClick={() => navigate('/view-admin-dashboard/generate-formative-report')}>
                       Generate Formative Assessments
                    </Button>
                    <Button className="assign-button2" style={{ backgroundColor: "#ffb134", fontFamily:"cursive", fontSize:"17px" }} onClick={() => navigate('/view-admin-dashboard/generate-summative-report')}>
                        Generate Summative Assessments
                    </Button>
                    <Button className="assign-button2" style={{ backgroundColor: "#ff615d",fontFamily:"cursive", fontSize:"17px" }} onClick={() => navigate('/view-admin-dashboard/generate-behaviour-goals-report')}>
                        Generate Behaviour Goals Assessments
                    </Button>
                </Segment>
            
        </div>
    );
};

export default AdminGenerateReports;
