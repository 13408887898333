import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from './Encryption'; // Ensure this utility is correctly implemented
import './EditStream.css'; // Ensure this CSS file exists and is correctly styled
import { FaArrowLeft } from 'react-icons/fa';

const EditBehaviourGoal = () => {
    const { goalId } = useParams(); // Extract behaviour goal ID from the URL parameters
    const navigate = useNavigate();
    const location = useLocation();
    const { grades, behaviourGoals,gradeName } = location.state;

    // Find the behaviour goal data in the passed location state
    const goalData = behaviourGoals.find(goal => goal.id === goalId);

    // Set initial state using location state data or show error if not found
    const [behaviourGoal, setBehaviourGoal] = useState({
        behaviour_goal: goalData ? goalData.behaviour_goal : '',
    });
    const [error, setError] = useState('');

    useEffect(() => {
        if (!goalData) {
            setError('Behaviour Goal not found in the provided data.');
        }
    }, [goalData]);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page refresh

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/behaviour_goals/${goalId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({
                    behaviour_goal: behaviourGoal.behaviour_goal,
                }), // Sending updated behaviour goal
            });

            if (response.ok) {
                alert('Behaviour Goal updated successfully.');
                navigate('/view-admin-dashboard/admin-view-behaviour-goals'); // Navigate back to the behaviour goals list
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update behaviour goal');
            }
        } catch (error) {
            setError('An error occurred while updating the behaviour goal.');
            console.error('Update Behaviour Goal Error:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBehaviourGoal(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="edit-stream-container">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2>Edit Behaviour Goal for {gradeName}</h2> {/* Dynamic header showing the grade */}
    
            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="behaviour_goal">Behaviour Goal:</label>
                        <input
                            type="text"
                            id="behaviour_goal"
                            name="behaviour_goal"
                            value={behaviourGoal.behaviour_goal}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" className="submit-button-special">Update Behaviour Goal</button>
                        <button
                            type="button"
                            className="cancel-button-special"
                            onClick={() => navigate('/view-admin-dashboard/admin-view-behaviour-goals')}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditBehaviourGoal;
