import React, { useEffect, useState } from 'react';
import { retrieve } from './Encryption';
import { useParams, useNavigate } from 'react-router-dom';

const EditLearningOutcome = () => {
  const { learningOutcomeId } = useParams(); 
  const navigate = useNavigate();
  const [learningOutcome, setLearningOutcome] = useState({
    learning_outcomes: '',
  
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch the substrand details when the component mounts
  useEffect(() => {
    const fetchLearningOutcomeDetails = async () => {
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/learning_outcomes/${learningOutcomeId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
           
          },
        });

        if (response.ok) {
          const data = await response.json();
          setLearningOutcome(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch Learning Outcome details.');
        }
      } catch (error) {
        setError('An error occurred while fetching learning Outcome details.');
      }
    };

    fetchLearningOutcomeDetails();
  }, [learningOutcomeId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLearningOutcome((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/learning_outcomes/${learningOutcomeId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
        body: JSON.stringify(learningOutcome),
      });

      if (response.ok) {
        setSuccessMessage('Learning Outcome updated successfully!');
        setTimeout(() => {
          navigate('/view-superadmin-dashboard/view-strands');
        }, 2000); // Redirect after 2 seconds
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to update learning outcome.');
      }
    } catch (error) {
      setError('An error occurred while updating the learning outcome.');
    }
  };

  return (
    <div className="edit-substrand-container">
      <h2>Edit Learning Outcome</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="learning_outcomes">Learning Outcome Name:</label>
          <input
            type="text"
            id="learning_outcomes"
            name="learning_outcomes"
            value={learningOutcome.learning_outcomes}
            onChange={handleChange}
            required
          />
        </div>

        

        <button type="submit" className="ui button green">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditLearningOutcome;
