import React, { useEffect, useState } from 'react';
import { FaSave, FaSpinner ,FaArrowLeft} from "react-icons/fa";
import { retrieve } from './Encryption'; // Adjust the import based on your project structure
import './AssignGradeToTeacher.css'; // Ensure this CSS file exists and is correctly styled
import { useNavigate } from 'react-router-dom';

const AssignTeacherGrade = () => {
    const [teachers, setTeachers] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [loadingTeachers, setLoadingTeachers] = useState(true);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [assigningGrades, setAssigningGrades] = useState(false);
    const [errorTeachers, setErrorTeachers] = useState('');
    const [errorGrades, setErrorGrades] = useState('');
    const [errorAssignment, setErrorAssignment] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [alreadyAssignedGrades, setAlreadyAssignedGrades] = useState([]);
    const navigate = useNavigate();
    // Fetch all teachers on component mount
    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/teachers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTeachers(data);
                } else {
                    const errorData = await response.json();
                    setErrorTeachers(errorData.error || 'Failed to fetch teachers.');
                }
            } catch (error) {
                setErrorTeachers('An error occurred while fetching teachers.');
                console.error('Fetch Teachers Error:', error);
            } finally {
                setLoadingTeachers(false);
            }
        };

        fetchTeachers();
    }, []);

    // Fetch all grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades.');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Handle selection of teacher
    const handleTeacherChange = (e) => {
        setSelectedTeacher(e.target.value);
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedGrades([]);
    };

    // Handle selection of a grade checkbox
    const handleGradeCheckboxChange = (e) => {
        const gradeId = e.target.value;
        if (e.target.checked) {
            setSelectedGrades([...selectedGrades, gradeId]);
        } else {
            setSelectedGrades(selectedGrades.filter(id => id !== gradeId));
        }
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedGrades([]);
    };

    // Handle form submission to assign grades
    const handleAssignGrades = async (e) => {
        e.preventDefault();

        // Validation
        if (!selectedTeacher) {
            setErrorAssignment('Please select a teacher.');
            return;
        }
        if (selectedGrades.length === 0) {
            setErrorAssignment('Please select at least one grade.');
            return;
        }

        setAssigningGrades(true);
        setErrorAssignment('');
        setSuccessMessage('');
        setAlreadyAssignedGrades([]);

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/assign_grade_to_teacher', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({
                    staff_id: selectedTeacher,
                    grade_ids: selectedGrades,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || 'Grades assigned successfully.');
                setSelectedTeacher('');
                setSelectedGrades([]);
            } else if (response.status === 400) {
                const errorData = await response.json();
                setErrorAssignment(errorData.message || 'Failed to assign grades.');
                if (errorData.already_assigned_grades) {
                    setAlreadyAssignedGrades(errorData.already_assigned_grades);
                }
            } else {
                const errorData = await response.json();
                setErrorAssignment(errorData.message || 'Failed to assign grades.');
            }
        } catch (error) {
            setErrorAssignment('An error occurred while assigning grades.');
            console.error('Assign Grades Error:', error);
        } finally {
            setAssigningGrades(false);
        }
    };

    return (
        <div className="assign-grade-container">
              <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>

            <h2 className="header">Assign Grades to Teacher</h2>

            <form className="assign-grade-form" onSubmit={handleAssignGrades}>
                {/* Teacher Selection */}
                <div className="form-group">
                    <label htmlFor="teacher">Select Teacher:</label>
                    {loadingTeachers ? (
                        <p>Loading teachers...</p>
                    ) : errorTeachers ? (
                        <p className="error-message">{errorTeachers}</p>
                    ) : (
                        <select
                            id="teacher"
                            value={selectedTeacher}
                            onChange={handleTeacherChange}
                        >
                            <option value="">-- Select Teacher --</option>
                            {teachers.map(teacher => (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.first_name} {teacher.last_name} (Payroll: {teacher.payroll_number})
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Grade Selection as Checkboxes */}
                <div className="form-group">
                    <label>Select Grade(s):</label>
                    {loadingGrades ? (
                        <p>Loading grades...</p>
                    ) : errorGrades ? (
                        <p className="error-message">{errorGrades}</p>
                    ) : (
                        <div className="checkbox-group">
                        {grades.map(grade => (
                            <div key={grade.id} className="checkbox-item">
                                <input
                                    type="checkbox"
                                    id={`grade-${grade.id}`}
                                    value={grade.id}
                                    checked={selectedGrades.includes(grade.id)}
                                    onChange={handleGradeCheckboxChange}
                                />
                                <label htmlFor={`grade-${grade.id}`}>{grade.grade}</label>
                            </div>
                        ))}
                    </div>

                    )}
                </div>

                {/* Assignment Feedback */}
                {errorAssignment && <p className="error-message">{errorAssignment}</p>}
                {alreadyAssignedGrades.length > 0 && (
                    <div className="error-message">
                        <p>The following grade(s) are already assigned to this teacher:</p>
                        <ul>
                            {alreadyAssignedGrades.map((grade, index) => (
                                <li key={index}>{grade}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {successMessage && <p className="success-message">{successMessage}</p>}

                {/* Submit Button */}
                <button
                    type="submit"
                    className="assign-button3"
                    disabled={assigningGrades}
                >
                    {assigningGrades ? (
                        <>
                            <FaSpinner className="spinner" /> Assigning...
                        </>
                    ) : (
                        <>
                            <FaSave /> Assign Grades
                        </>
                    )}
                </button>
            </form>
        </div>
    );
};

export default AssignTeacherGrade;
