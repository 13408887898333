import React, { useState, useEffect } from 'react';
import { retrieve } from './Encryption';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { FaDownload } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const TeacherFormativePerformance = ({ teacherId }) => {
  const [year, setYear] = useState('');
  const [years, setYears] = useState([]);
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [performanceData, setPerformanceData] = useState([]);
  const [averagePerformance, setAveragePerformance] = useState(0);
  const [showInsightsButton, setShowInsightsButton] = useState(true);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  

  // Fetch available years
  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/years', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then(response => response.json())
      .then(data => setYears(data))
      .catch(error => console.error('Error fetching years:', error));
  }, []);

  // Fetch available grades
  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/grades', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then(response => response.json())
      .then(data => setGrades(data))
      .catch(error => console.error('Error fetching grades:', error));
  }, []);

  // Fetch teachers for the selected grade
  useEffect(() => {
    if (grade) {
      fetch(`https://cbc-orfl.onrender.com/teachers/grades/${grade}`, {
        headers: { Authorization: `Bearer ${retrieve().access_token}` },
      })
        .then(response => response.json())
        .then(data => {
          const teacherList = data.map(teacher => ({
            teacher_id: teacher.id,
            teacher_name: `${teacher.first_name} ${teacher.last_name}`,
          }));
          setTeachers(teacherList);
        })
        .catch(error => console.error('Error fetching teachers:', error));
    }
  }, [grade]);

  // Fetch performance insights for the selected teacher
  const fetchPerformanceInsights = () => {
    fetch(
      `https://cbc-orfl.onrender.com/teacher_performance_insights_formative/${selectedTeacher}?year_id=${year}`,
      {
        headers: { Authorization: `Bearer ${retrieve().access_token}` },
      }
    )
      .then(response => response.json())
      .then(data => {
        setPerformanceData(data);
        const totalAverage = data.reduce((sum, item) => sum + item.performance_data.average_performance, 0);
        setAveragePerformance(totalAverage / data.length);
        setShowInsightsButton(false); // Hide insights button
        setShowDownloadButton(true); // Show download button
      })
      .catch(error => console.error('Error fetching teacher performance insights:', error));
  };

  // Download report as PDF
  const handleDownloadReport = async() => {
    const doc = new jsPDF();
    doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
      
      // Center the title by calculating the X position
      const title = 'Teacher Formative Performance Report';
      const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
      doc.text(title, titleX, 42);

      doc.setFontSize(10);
      const fontSizeSmall = 6; // Font size for smaller text like address and contact
      
      const fetchLogo = async () => {
        try {
            let logoUrl = schoolLogo;

            // Replace 'http' with 'https' if necessary
            if (logoUrl.startsWith('http://')) {
                logoUrl = logoUrl.replace('http://', 'https://');
            }

            const response = await fetch(logoUrl);
            if (!response.ok) throw new Error("Failed to fetch logo");

            const blob = await response.blob();
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Failed to load logo:", error);
            return null; // Provide a fallback or skip adding the logo
        }
    };

    const logoBase64 = await fetchLogo();

    // Add the logo if successfully fetched
    if (logoBase64) {
        const imageWidth = 10; // Set the small width for the logo
        const imageHeight = 10; // Set the small height for the logo
        const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
        const yPosition = 10; // Top margin for the logo

        // Draw circular border around the logo
        const centerX = xPosition + imageWidth / 2;
        const centerY = yPosition + imageHeight / 2;
        const radius = imageWidth / 2;

        doc.setLineWidth(0.5);
        doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
    }

    // Center the school name and contact details
    const textX = pageWidth / 2;
    doc.text(schoolName, textX, 25, { align: 'center' });
    doc.setFontSize(fontSizeSmall);
    doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
    doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
    doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });

    const columns = ['Grade', 'Stream', 'Subject', 'Performance Percentage'];
    const rows = performanceData.flatMap(item =>
      item.performance_data.subject_performance.map(subject => [
        item.grade,
        item.stream,
        subject.subject_name,
        `${subject.performance_percentage.toFixed(2)}%`,
      ])
    );

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 47,
      styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
      headStyles: { halign: 'center' }, // Center align header cells
      bodyStyles: { halign: 'center' }, // Center align body cells
    });

    doc.save('Teacher_Formative_Performance_Report.pdf');
  };

  // Reset UI when any dropdown changes
  const handleDropdownChange = (setter) => (event) => {
    setter(event.target.value);
    setPerformanceData([]);
    setShowInsightsButton(true);
    setShowDownloadButton(false);
  };

  return (
    <div className="container">
      <h2 className="header">View Teacher Formative Performance</h2>
      <div>
        <label>
          Select Year:
          <select value={year} onChange={handleDropdownChange(setYear)}>
            <option value="">--Select Year--</option>
            {years.map(yearItem => (
              <option key={yearItem.id} value={yearItem.id}>
                {yearItem.year_name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div>
        <label>
          Select Grade:
          <select value={grade} onChange={handleDropdownChange(setGrade)}>
            <option value="">--Select Grade--</option>
            {grades.map(gradeItem => (
              <option key={gradeItem.id} value={gradeItem.id}>
                {gradeItem.grade}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div>
        <label>
          Select Teacher:
          <select
            value={selectedTeacher}
            onChange={handleDropdownChange(setSelectedTeacher)}
            disabled={!grade}
          >
            <option value="">--Select Teacher--</option>
            {teachers.map(teacher => (
              <option key={teacher.teacher_id} value={teacher.teacher_id}>
                {teacher.teacher_name}
              </option>
            ))}
          </select>
        </label>
      </div>

      {showInsightsButton && (
        <button
          onClick={fetchPerformanceInsights}
          disabled={!year || !grade || !selectedTeacher}
          style={{ marginTop: '20px' }}
        >
          Get Performance Insights
        </button>
      )}
       {showDownloadButton && (
            <button onClick={handleDownloadReport} style={{ marginTop: '20px' }}>
                 <FaDownload style={{ marginRight: '10px' }}/>Download Report
            </button>
          )}

      {performanceData.length > 0 && (
        <div>
          <table style={{ marginTop: '20px', width: '100%' }}>
            <thead>
              <tr>
                <th>Grade</th>
                <th>Stream</th>
                <th>Subject</th>
                <th>Performance Percentage</th>
              </tr>
            </thead>
            <tbody>
              {performanceData.map((item, index) =>
                item.performance_data.subject_performance.map((subject, subIndex) => (
                  <tr key={`${index}-${subIndex}`}>
                    <td>{item.grade}</td>
                    <td>{item.stream}</td>
                    <td>{subject.subject_name}</td>
                    <td>{subject.performance_percentage.toFixed(2)}%</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
         
        </div>
      )}

      {performanceData.length === 0 && !showInsightsButton && (
        <p>No performance data available for the selected teacher and year.</p>
      )}
    </div>
  );
};

export default TeacherFormativePerformance;
