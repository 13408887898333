import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';
import "./form.css";


const AddStudent = () => {
    const [formData, setFormData] = useState({
        admission_number: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        birth_certificate_number: '',
        joined_date: '',
        grade_id: '',
        stream_id: '',
        gender: '',
    });
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [grades, setGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Fetch grades
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const gradesResponse = await fetch('https://cbc-orfl.onrender.com/grades', {
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (gradesResponse.ok) {
                    const gradesData = await gradesResponse.json();
                    setGrades(gradesData);
                } else {
                    throw new Error('Failed to fetch grades');
                }
            } catch (error) {
                console.error('Error fetching grades:', error);
                setError('Unable to fetch grades');
            }
        };
        fetchGrades();
    }, []);

    // Fetch streams when grade is selected
    useEffect(() => {
        const fetchStreamsByGrade = async () => {
            if (formData.grade_id) {
                try {
                    const streamsResponse = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${formData.grade_id}`, {
                        headers: {
                            Authorization: `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (streamsResponse.ok) {
                        const streamsData = await streamsResponse.json();
                        setStreams(streamsData);
                    } else {
                        throw new Error('Failed to fetch streams for the selected grade');
                    }
                } catch (error) {
                    console.error('Error fetching streams:', error);
                    setError('Unable to fetch streams for the selected grade');
                }
            } else {
                setStreams([]); // Clear streams if no grade is selected
            }
        };
        fetchStreamsByGrade();
    }, [formData.grade_id]);

    const MAX_FILE_SIZE = 10000000; // 10MB
    const validFileExtensions = ["jpg", "png", "jpeg", "webp"];

    const getExtension = (fileName) => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLowerCase();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const size = file.size;
            const extension = getExtension(file.name);
            const isValid = validFileExtensions.includes(extension);
            if (size > MAX_FILE_SIZE) {
                setError("The file is too large. Maximum size is 10MB.");
                setProfilePhoto(null);
            } else if (!isValid) {
                setError("The file type is not supported. Allowed types: jpg, jpeg, png, webp.");
                setProfilePhoto(null);
            } else {
                setError(''); // Clear any previous error
                setProfilePhoto(file); // Set the profile photo
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGradeChange = (e) => {
        const selectedGradeId = e.target.value;
        setFormData(prevData => ({
            ...prevData,
            grade_id: selectedGradeId,
        }));
    };

    const handleStreamChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            stream_id: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object
        const form = new FormData();
        Object.entries(formData).forEach(([key, value]) => form.append(key, value));
        if (profilePhoto) {
            form.append('profile_photo', profilePhoto);
        }

        // Define fetch options
        const options = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
                // Note: When using FormData, do NOT set the 'Content-Type' header.
                // The browser will set it automatically, including the boundary.
            },
            body: form,
        };

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/students', options);
            if (response.ok) {
                setSuccess('Student added successfully!');
                setError('');
                setTimeout(() => navigate('/view-admin-dashboard/admin-view-students'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to add student.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>

            <h2 className='header'>Add New Student</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}
                    
                    {/* Render input fields except 'grade_id', 'stream_id', and 'gender' */}
                    {Object.entries(formData).map(([key, value]) => (
                        key !== 'grade_id' && key !== 'stream_id' && key !== 'gender' && (
                            <div className="field" key={key}>
                                <label>{key.replace(/_/g, ' ').toUpperCase()}:</label>
                                <input
                                    type={key.includes('date') ? 'date' : 'text'}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    required={key !== 'alternative_contact'} // Adjust as needed
                                />
                            </div>
                        )
                    ))}

                    {/* Grade Dropdown */}
                    <div className="field">
                        <label>GRADE:</label>
                        <select
                            name="grade_id"
                            value={formData.grade_id}
                            onChange={handleGradeChange}
                            required
                        >
                            <option value="">Select Grade</option>
                            {grades.map((grade) => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade} {/* Correct property: 'grade' */}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Stream Dropdown */}
                    <div className="field">
                        <label>STREAM:</label>
                        <select
                            name="stream_id"
                            value={formData.stream_id}
                            onChange={handleStreamChange}
                            required
                        >
                            <option value="">Select Stream</option>
                            {streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>
                                    {stream.stream_name} {/* Adjust based on actual property */}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Gender Dropdown */}
                    <div className="field">
                        <label>GENDER:</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    
                    {/* Profile Photo Upload */}
                    <div className="field">
                        <label>PROFILE PHOTO:</label>
                        <input
                            type="file"
                            name="profile_photo"
                            accept=".jpg,.jpeg,.png,.webp"
                            onChange={handleFileChange}
                        />
                        {error && error.toLowerCase().includes("file") && (
                            <div className="error-message">{error}</div>
                        )}
                    </div>

                    {/* Submit Button */}
                    <div className="">
                        <button type="submit"  className='submit'>
                            Add Student
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddStudent;
