import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import { retrieve } from './Encryption';
import './ViewStaff.css';
import { MdLockReset } from "react-icons/md";
const TeacherViewStaff = () => {
    const [staffData, setStaffData] = useState([]);
    const [filteredStaffData, setFilteredStaffData] = useState([]); // For filtered data
    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // Search term state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/designations', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setDesignations(result);
                } else {
                    const errorData = await response.json();
                    console.error(errorData.error || 'Failed to fetch designations');
                }
            } catch (error) {
                console.error('An error occurred while fetching designations:', error);
            }
        };

        const fetchStaffData = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/staffs', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setStaffData(result);
                    setFilteredStaffData(result); // Initially set filtered data
                } else {
                    const errorData = await response.json();
                    setError(errorData.error || 'Failed to fetch staff data');
                }
            } catch (error) {
                setError('An error occurred while fetching staff data');
            } finally {
                setLoading(false);
            }
        };

        fetchDesignations();
        fetchStaffData();
    }, []);

 
  

    // Handle search term changes and filter data
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        const filtered = staffData.filter(staff =>
            staff.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            staff.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            staff.payroll_number.toString().toLowerCase().includes(e.target.value)
        );
        setFilteredStaffData(filtered);
    };

    if (loading) {
        return <p>Loading staff data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }
   
    return (
        <div className="view-staff-container">
             <h2 
    className="header"
    
>
    Staff List
</h2>

          
            <input
                type="text"
                placeholder="Search staff..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
            />
            <table className="ui striped table">
                <thead>
                    <tr>
                        <th>Payroll Number</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Designation</th>
                        <th>Profile Photo</th>
                      
                    </tr>
                </thead>
                <tbody>
                    {filteredStaffData.map(staff => (
                        <tr key={staff.id}>
                            <td>{staff.payroll_number}</td>
                            <td>{staff.first_name}</td>
                            <td>{staff.last_name}</td>
                            <td>{staff.email_address}</td>
                            <td>{staff.phone_number}</td>
                            <td>{staff.gender}</td>
                            <td>{designations.find(designation => designation.id === staff.designation_id)?.designation_name}</td>
                            <td>
                                {staff.photo_url ? (
                                    <img
                                        src={staff.photo_url}
                                        alt={`${staff.first_name} ${staff.last_name}`}
                                        width="50"
                                        height="50"
                                    />
                                ) : (
                                    'No Photo'
                                )}
                            </td>
                           
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TeacherViewStaff;
