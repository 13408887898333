import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';
import "./form.css";

const EditParent = () => {
    const { parentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { parents } = location.state;

    const parentData = parents.find(parent => parent.id === parentId);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        alternative_contact: '',
        email_address: '',
        gender: '',
        relationship: '',
        active_status: true,
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (parentData) {
            setFormData({
                first_name: parentData.first_name || '',
                last_name: parentData.last_name || '',
                date_of_birth: parentData.date_of_birth || '',
                phone_number: parentData.phone_number || '',
                alternative_contact: parentData.alternative_contact || '',
                email_address: parentData.email_address || '',
                gender: parentData.gender || '',
                relationship: parentData.relationship || '',
                active_status: parentData.active_status !== undefined ? parentData.active_status : true,
            });
        }
    }, [parentData, parentId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        };

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}`, options);
            if (response.ok) {
                setSuccess('Parent details updated successfully!');
                setError('');
                setTimeout(() => navigate(-1), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update parent');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className="content-wrapper">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>
            <h2 className="header">Edit Parent Details</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}

                    <div className="field">
                        <label>First Name:</label>
                        <input
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Date of Birth:</label>
                        <input
                            type="date"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Phone Number:</label>
                        <input
                            type="tel"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Alternative Contact:</label>
                        <input
                            type="tel"
                            name="alternative_contact"
                            value={formData.alternative_contact}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Email Address:</label>
                        <input
                            type="email"
                            name="email_address"
                            value={formData.email_address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label>Gender:</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div className="field">
                        <label>Relationship:</label>
                        <select
                            name="relationship"
                            value={formData.relationship}
                            onChange={handleChange}
                        >
                            <option value="">-- Select Relationship --</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Guardian">Guardian</option>
                        </select>
                    </div>
                    <div className="field">
                        <label>Active Status:</label>
                        <input
                            type="checkbox"
                            name="active_status"
                            checked={formData.active_status}
                            onChange={() => setFormData(prev => ({ ...prev, active_status: !prev.active_status }))}
                        />
                    </div>
                    <div>
                        <button type="submit" className="submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditParent;
