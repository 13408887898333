import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import { FaArrowLeft, FaDownload } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const StudentSummativeYearPerformance = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { student, year_id,year_name, grade_name, stream_name } = location.state || {};

    const [yearlyData, setYearlyData] = useState(null);
    const [termNames, setTermNames] = useState({});
    const [error, setError] = useState('');
    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();


    useEffect(() => {
        if (student?.id && year_id) {
            // Fetch yearly performance data
            fetch(`https://cbc-orfl.onrender.com/compare_yearly_performance/${student.id}/${year_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${retrieve().access_token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('No reports found for the given year.');
                    }
                    return response.json();
                })
                .then((data) => {
                    setYearlyData(data.performance_by_subject);
                })
                .catch((err) => {
                    setError(err.message);
                });

            // Fetch term names
            fetch(`https://cbc-orfl.onrender.com/terms`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${retrieve().access_token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Unable to fetch terms.');
                    }
                    return response.json();
                })
                .then((data) => {
                    const terms = data.reduce((acc, term) => {
                        acc[term.id] = term.name;
                        return acc;
                    }, {});
                    setTermNames(terms);
                })
                .catch((err) => {
                    setError(err.message);
                });
        }
    }, [student, year_id]);

    const handleDownloadReport =async () => {
        if (!yearlyData) return;

        const doc = new jsPDF();
        doc.setFontSize(16);
  

        const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
            const fontSizeHeader = 10; // Font size for headers
            const fontSizeBody = 8; // Font size for body text
            const fontSizeSmall = 6; // Font size for smaller text like address and contact
        
            const fetchLogo = async () => {
                try {
                    let logoUrl = schoolLogo;
        
                    // Replace 'http' with 'https' if necessary
                    if (logoUrl.startsWith('http://')) {
                        logoUrl = logoUrl.replace('http://', 'https://');
                    }
        
                    const response = await fetch(logoUrl);
                    if (!response.ok) throw new Error("Failed to fetch logo");
        
                    const blob = await response.blob();
                    const reader = new FileReader();
        
                    return new Promise((resolve, reject) => {
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                } catch (error) {
                    console.error("Failed to load logo:", error);
                    return null; // Provide a fallback or skip adding the logo
                }
            };
        
            const logoBase64 = await fetchLogo();
        
            // Add the logo if successfully fetched
            if (logoBase64) {
                const imageWidth = 10; // Set the small width for the logo
                const imageHeight = 10; // Set the small height for the logo
                const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
                const yPosition = 10; // Top margin for the logo
        
                // Draw circular border around the logo
                const centerX = xPosition + imageWidth / 2;
                const centerY = yPosition + imageHeight / 2;
                const radius = imageWidth / 2;
        
                doc.setLineWidth(0.5);
        
                doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
            }
        
            // // Find the selected subject's name from the subjects array
            // const subject = subjects.find((subject) => subject.id === selectedSubject);
            // const selectedSubjectName = subject ? subject.subject_name : "Unknown Subject";
            
            // Add school name below the logo
            doc.setFontSize(fontSizeHeader);
            doc.text(`${schoolName}`, pageWidth / 2, 25, { align: 'center' });
        
            // Add school contact information
            doc.setFontSize(fontSizeSmall);
            doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 30, { align: 'center' });
            doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 33, { align: 'center' });
            doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 36, { align: 'center' });
    
            doc.setFontSize(fontSizeHeader);
          
        
            doc.text(
              `Summative Yearly Performance Report: ${student.first_name} ${student.last_name}`,
              pageWidth / 2,
              43,
              { align: 'center' }
            );
            doc.setFontSize(fontSizeSmall);
            doc.text(`Grade: ${grade_name}, Stream: ${stream_name}`, 14, 46);
            doc.text(`Year: ${year_name}`, 14, 49)
           
            
            
            

        const columns = ['Subject', 'Term', 'Average Grade', 'Performance Percentage'];
        const rows = [];

        Object.entries(yearlyData).forEach(([subject, terms]) => {
            Object.entries(terms).forEach(([termId, data]) => {
                rows.push([
                    subject,
                    termNames[termId] || termId.replace(/_/g, ' ').toUpperCase(),
                    data.average_grade,
                    `${data.performance_percentage}%`,
                ]);
            });
        });

        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 47,
            styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
            headStyles: { halign: 'center' }, // Center align header cells
            bodyStyles: { halign: 'center' }, // Center align body cells
        });

        doc.save(`${student.first_name}_${student.last_name}_Summative_Yearly_Performance_Report.pdf`);
    };

    if (error) return <div>{error}</div>;
    if (!yearlyData) return <div>Loading...</div>;

    return (
        <div className='performance-container'>
           <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <button
                    onClick={handleDownloadReport}
                    style={{
                        marginTop: '20px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        padding: '10px 15px',
                        cursor: 'pointer',
                         marginLeft:"750px"
                        
                    }}
                >
                    <FaDownload style={{ marginRight: '10px' }} />
                    Download Report
                </button>

            <h2>Yearly Performance for {student.first_name} {student.last_name}</h2>
            <p>Grade: {grade_name}</p>
            <p>Stream: {stream_name}</p>

            <table className='ui celled table' style={{ width: '100%', marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Subject</th>
                        {Object.keys(yearlyData[Object.keys(yearlyData)[0]]).map((termId) => (
                            <th key={termId}>{termNames[termId] || termId.replace(/_/g, ' ').toUpperCase()}</th>
                        ))}
                        <th>Average Grade</th>
                        <th>Performance Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(yearlyData).map(([subject, terms]) => (
                        <tr key={subject}>
                            <td>{subject}</td>
                            {Object.entries(terms).map(([termId, data]) => (
                                <React.Fragment key={termId}>
                                    <td>{data.average_grade}</td>
                                    <td>{data.performance_percentage}%</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StudentSummativeYearPerformance;
