import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { retrieve } from './Encryption'; // Ensure this utility is correctly implemented
import './ViewStreams.css'; // Updated CSS file name for clarity

const TeacherViewStreams = () => {
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [streams, setStreams] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingStreams, setLoadingStreams] = useState(false);
    const [errorGrades, setErrorGrades] = useState('');
    const [errorStreams, setErrorStreams] = useState('');
    const navigate = useNavigate();

    // Fetch grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);

                    if (data.length > 0) {
                        setSelectedGrade(data[0].id); // Default to the first grade
                    }
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch streams when selectedGrade changes
    useEffect(() => {
        const fetchStreams = async () => {
            if (selectedGrade) {
                setLoadingStreams(true);
                setErrorStreams('');
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setStreams(data);
                        setErrorStreams(''); // Clear any previous error
                    } else {
                        const errorData = await response.json();
                        const backendMessage = errorData.error || errorData.message || 'Failed to fetch streams';
                        setErrorStreams(backendMessage);
                        setStreams([]); // Clear streams on error
                    }
                } catch (error) {
                    setErrorStreams('An error occurred while fetching streams.');
                    console.error('Fetch Streams Error:', error);
                    setStreams([]);
                } finally {
                    setLoadingStreams(false);
                }
            } else {
                setStreams([]);
            }
        };

        fetchStreams();
    }, [selectedGrade]);





    return (
        <div className="view-streams-container">
            <h2 className="header">View Streams by Grade</h2>
            <div className="controls">
                <div className="grade-select2">
                    <label htmlFor="grade">Select Grade:</label>
                    {loadingGrades ? (
                        <p>Loading grades...</p>
                    ) : errorGrades ? (
                        <p className="error-message">{errorGrades}</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                            style={{backgroundColor:"#8ac429"}}
                        >
                            <option value="">-- Select Grade --</option>
                            {grades.map(grade => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
               
             
               
            </div>

            {selectedGrade && (
                <div className="streams-table">
                    {loadingStreams ? (
                        <p>Loading streams...</p>
                    ) : errorStreams ? (
                        <p className="error-message">{errorStreams}</p>
                    ) : streams.length === 0 ? (
                        <p>No streams found for the selected grade.</p>
                    ) : (
                        <table className="ui celled table">
                            <thead>
                                <tr>
                                    <th>Grade Name</th>
                                    <th>Stream Name</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {streams.map(stream => {
                                    // Find the grade name using the grade_id from the stream
                                    const grade = grades.find(g => g.id === stream.grade_id);
                                    return (
                                        <tr key={stream.id}>
                                            <td>{grade ? grade.grade : 'Unknown Grade'}</td>
                                            <td>{stream.stream_name}</td>
                                      

                                                                                </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default TeacherViewStreams;


