import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import { FaArrowLeft, FaDownload } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const StudentSummativeTermPerformance = () => {
    const location = useLocation();
    const { student, year_id, year_name, grade_id, stream_id, grade_name, stream_name } = location.state || {};

    const [performanceData, setPerformanceData] = useState(null);
    const [error, setError] = useState('');
    const [terms, setTerms] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState('');
    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();

    const navigate = useNavigate();

    useEffect(() => {
        fetchTerms();
    }, []);

    const fetchTerms = async () => {
        try {
            const response = await fetch('https://cbc-orfl.onrender.com/terms', {
                headers: { Authorization: `Bearer ${retrieve().access_token}` },
            });
            const data = await response.json();
            setTerms(data);
        } catch (err) {
            setError('Failed to fetch terms');
        }
    };

    useEffect(() => {
        if (student && selectedTerm && year_id) {
            fetchPerformanceData();
        }
    }, [student, selectedTerm, year_id]);

    const fetchPerformanceData = async () => {
        try {
            const response = await fetch(
                `https://cbc-orfl.onrender.com/calculate_term_performance/${student.id}/${selectedTerm}/${year_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${retrieve().access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                throw new Error('No report found for the given term and year.');
            }
            const data = await response.json();
            setPerformanceData(data);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDownloadReport = async () => {
        if (!performanceData) return;

        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const fontSizeHeader = 10; // Font size for headers
        const fontSizeBody = 8; // Font size for body text
        const fontSizeSmall = 6; // Font size for smaller text like address and contact

        const fetchLogo = async () => {
            try {
                let logoUrl = schoolLogo.startsWith('http://')
                    ? schoolLogo.replace('http://', 'https://')
                    : schoolLogo;

                const response = await fetch(logoUrl);
                if (!response.ok) throw new Error('Failed to fetch logo');

                const blob = await response.blob();
                const reader = new FileReader();

                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch {
                return null;
            }
        };

        const logoBase64 = await fetchLogo();
        if (logoBase64) {
            doc.addImage(logoBase64, 'JPEG', (pageWidth - 20) / 2, 10, 20, 20);
        }

        doc.setFontSize(10);
        doc.text(schoolName, pageWidth / 2, 35, { align: 'center' });
        doc.setFontSize(6);
        doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 40, { align: 'center' });
        doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 45, { align: 'center' });
        doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 50, { align: 'center' });

        doc.setFontSize(12);
        doc.text(
            `Summative Termly Performance Report: ${student.first_name} ${student.last_name}`,
            pageWidth / 2,
            60,
            { align: 'center' }
        );

        doc.setFontSize(10);
        // Draw a bordered and shaded box for student information
        const studentInfoX = 34; // X position for the box
        const studentInfoY = 47; // Y position for the box
        const studentInfoWidth = pageWidth - 65; // Box width (centered with 20px padding on each side)
        const studentInfoHeight = 23; // Box height
    
        // Set border and fill colors
        doc.setLineWidth(0.1); // Reduced border weight for a thinner line      
        doc.setFillColor(243, 243, 244); // Light gray fill (RGB)
    
        // Draw the rectangle
        doc.rect(studentInfoX, studentInfoY, studentInfoWidth, studentInfoHeight, 'FD'); // 'FD' = Fill and Draw
    
        // Add the report title and student details inside the box
      
        
        doc.setFontSize(fontSizeBody);
        doc.text(`Name: ${student.first_name} ${student.last_name}`, pageWidth / 2, studentInfoY + 5, { align: 'center' });
        doc.text(`Grade: ${grade_name}, Stream: ${stream_name}`, pageWidth / 2, studentInfoY + 10, { align: 'center' });
        doc.text(`Year: ${year_name}, Term: ${terms.find(term => term.id === selectedTerm)?.term_name}`, pageWidth / 2, studentInfoY + 15, {
            align: 'center',
        });

        const columns = ['Subject', 'Average Marks', 'Performance Percentage'];
        const rows = Object.entries(performanceData.subject_performance).map(([subject, performance]) => [
            subject,
            performance.average_marks,
            `${performance.performance_percentage}%`,
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 80,
            styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
            headStyles: { halign: 'center' }, // Center align header cells
            bodyStyles: { halign: 'center' }, // Center align body cells
        });

        doc.save(`${student.first_name}_${student.last_name}_Performance_Report.pdf`);
    };


    if (error) return <div>{error}</div>;
    if (!terms.length) return <div>Loading terms...</div>;

    return (
        <div className="performance-container">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>
            <h2 className="header">
                Term Performance for {student.first_name} {student.last_name} - Year {year_name}
            </h2>
            <div className="student-details" style={{ marginTop: '30px' }}>
                <p>
                    <strong>Grade:</strong> {grade_name}
                </p>
                <p>
                    <strong>Stream:</strong> {stream_name}
                </p>
            </div>
            <div
                className="select-term"
                style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '30px' }}
            >
                <label style={{ fontSize: '14px' }}>Select Term:</label>
                <select
                    style={{
                        fontSize: '14px',
                        backgroundColor: '#8ac429',
                        fontFamily: 'cursive',
                    }}
                    value={selectedTerm}
                    onChange={(e) => setSelectedTerm(e.target.value)}
                >
                    <option value="">--Select Term--</option>
                    {terms.map((term) => (
                        <option key={term.id} value={term.id}>
                            {term.term_name}
                        </option>
                    ))}
                </select>
            </div>
            {selectedTerm && (
                <button
                onClick={handleDownloadReport}
                style={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    marginBottom:" 40px"
                }}
            >
                <FaDownload /> Download Report
            </button>
            )}
            {performanceData && (
                <div>
                    <h2>Performance Data</h2>
                    <table className="performance-table3">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Average Marks</th>
                                <th>Performance Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(performanceData.subject_performance).map(([subject, performance]) => (
                                <tr key={subject}>
                                    <td>{subject}</td>
                                    <td>{performance.average_marks}</td>
                                    <td>{performance.performance_percentage}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default StudentSummativeTermPerformance;