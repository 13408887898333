import React, { useState, useEffect } from 'react';
import { retrieve } from "./Encryption";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './SubjectSummativeReport.css';
import { FaArrowLeft, FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const SubjectSummativeReport = () => {
    const [viewType, setViewType] = useState(null);
    const [viewBy, setViewBy] = useState('');
    const [gradeId, setGradeId] = useState('');
    const [streamId, setStreamId] = useState('');
    const [grades, setGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [performanceResults, setPerformanceResults] = useState([]);
    const [showFetchButton, setShowFetchButton] = useState(true); // New state for fetch button visibility
    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  
    const navigate = useNavigate();

    const fetchGrades = async () => {
        const response = await fetch('https://cbc-orfl.onrender.com/grades', {
            headers: { 'Authorization': `Bearer ${retrieve().access_token}` }
        });
        const data = await response.json();
        setGrades(data);
    };

    const fetchStreams = async (gradeId) => {
        const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
            headers: { 'Authorization': `Bearer ${retrieve().access_token}` }
        });
        const data = await response.json();
        setStreams(data);
    };

    const fetchPerformance = async (gradeId, streamId) => {
        let endpoint;
        if (viewType === 'termly') {
            endpoint = streamId 
                ? `https://cbc-orfl.onrender.com/subject_performance_summative_term_stream/${gradeId}/${streamId}` 
                : `https://cbc-orfl.onrender.com/subject_performance_summative_term_grade/${gradeId}`;
        } else {
            endpoint = streamId 
                ? `https://cbc-orfl.onrender.com/subject_performance_summative_year_stream/${gradeId}/${streamId}` 
                : `https://cbc-orfl.onrender.com/subject_performance_summative_year_grade/${gradeId}`;
        }

        const response = await fetch(endpoint, {
            headers: { 'Authorization': `Bearer ${retrieve().access_token}` }
        });
        const data = await response.json();
        setPerformanceResults(Array.isArray(data) ? data : data.performance || []);
        setShowFetchButton(false); // Hide the fetch button after fetching performance
    };

    useEffect(() => {
        fetchGrades();
    }, []);

    const handleViewTypeChange = (type) => {
        setViewType(type);
        setGradeId('');
        setStreamId('');
        setStreams([]);
        setPerformanceResults([]);
        setShowFetchButton(true); // Show fetch button when view type changes
    };

    const handleGradeChange = (e) => {
        const selectedGradeId = e.target.value;
        setGradeId(selectedGradeId);
        setStreamId('');
        fetchStreams(selectedGradeId);
        setPerformanceResults([]);
        setShowFetchButton(true); // Show fetch button when grade changes
    };

    const handleStreamChange = (e) => {
        setStreamId(e.target.value);
        setShowFetchButton(true); // Show fetch button when stream changes
    };

    const handleFetchPerformance = () => {
        fetchPerformance(gradeId, streamId);
    };

    const handleDownloadReport = async () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
        
        // Center the title by calculating the X position
        const title = 'Subject Summative Performance Report';
        const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
        doc.text(title, titleX, 42);

        doc.setFontSize(10);
        const fontSizeSmall = 6; // Font size for smaller text like address and contact
        
        const fetchLogo = async () => {
            try {
                let logoUrl = schoolLogo;

                // Replace 'http' with 'https' if necessary
                if (logoUrl.startsWith('http://')) {
                    logoUrl = logoUrl.replace('http://', 'https://');
                }

                const response = await fetch(logoUrl);
                if (!response.ok) throw new Error("Failed to fetch logo");

                const blob = await response.blob();
                const reader = new FileReader();

                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error("Failed to load logo:", error);
                return null; // Provide a fallback or skip adding the logo
            }
        };

        const logoBase64 = await fetchLogo();

        // Add the logo if successfully fetched
        if (logoBase64) {
            const imageWidth = 10; // Set the small width for the logo
            const imageHeight = 10; // Set the small height for the logo
            const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
            const yPosition = 10; // Top margin for the logo

            // Draw circular border around the logo
            const centerX = xPosition + imageWidth / 2;
            const centerY = yPosition + imageHeight / 2;
            const radius = imageWidth / 2;

            doc.setLineWidth(0.5);
            doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
        }

        // Center the school name and contact details
        const textX = pageWidth / 2;
        doc.text(schoolName, textX, 25, { align: 'center' });
        doc.setFontSize(fontSizeSmall);
        doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
        doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
        doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });
        const columns = ["Subject Name", "Average Score", "Performance Percentage"];
        const rows = performanceResults.map(result => [
            result.subject_name,
            result.average_score,
            `${result.performance_percentage}%`,
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 47,
            styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
            headStyles: { halign: 'center' }, // Center align header cells
            bodyStyles: { halign: 'center' }, // Center align body cells
            columnStyles: { 0: { cellWidth: 60 }, 1: { cellWidth: 40 }, 2: { cellWidth: 40 } },
        });

        doc.save("Subject_Summative_Report.pdf");
    };

    return (
        <div className="container">
            <h2 className="header">View Subject Summative Performance</h2>

            <div className="button-group" style={{ marginTop: "60px" }}>
                <button
                    onClick={() => handleViewTypeChange('termly')}
                    className={`assign-button2 ${viewType === 'termly' ? 'button-selected' : ''}`}
                    style={{ backgroundColor: "#8ac429" }}
                >
                    Termly Report
                </button>
                <button
                    onClick={() => handleViewTypeChange('yearly')}
                    className={`assign-button2 ${viewType === 'yearly' ? 'button-selected' : ''}`}
                    style={{ backgroundColor: "#ffb134" }}
                >
                    Yearly Report
                </button>
            </div>

            {viewType && (
                <div className="form-group">
                    <label className="label">
                        Select View Type:
                        <select value={viewBy} onChange={(e) => setViewBy(e.target.value)} className="select">
                            <option value="">--Select View Type--</option>
                            <option value="grade">View by Grade</option>
                            <option value="stream">View by Stream</option>
                        </select>
                    </label>

                    <label className="label">
                        Select Grade:
                        <select value={gradeId} onChange={handleGradeChange} className="select">
                            <option value="">--Select Grade--</option>
                            {grades.map((grade) => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    </label>

                    {viewBy === 'stream' && gradeId && (
                        <label className="label">
                            Select Stream:
                            <select value={streamId} onChange={handleStreamChange} className="select">
                                <option value="">--Select Stream--</option>
                                {streams.map((stream) => (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.stream_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    )}
                    <div className="form-group5">
                        {showFetchButton && (
                            <button className="submit-button4" onClick={handleFetchPerformance}>Fetch Performance</button>
                        )}
                    </div>
                </div>
            )}

            {performanceResults.length > 0 && (
                <div className="results-container">
                    <button  onClick={handleDownloadReport} >
                        <FaDownload /> Download Report
                    </button>
                    <h3 className="results-title">
                        {viewType === 'termly' ? 'Termly' : 'Yearly'} Performance Results
                    </h3>
                    
                    <table className="performance-table">
                        <thead>
                            <tr>
                                <th>Subject Name</th>
                                <th>Average Score</th>
                                <th>Performance Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {performanceResults.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.subject_name}</td>
                                    <td>{result.average_score}</td>
                                    <td>{result.performance_percentage}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default SubjectSummativeReport;
