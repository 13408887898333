import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; 
import { retrieve } from './Encryption';

const IdleLogout = () => {
  const [isIdle, setIsIdle] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(15 * 60 * 1000); // 15 minutes idle time
  const navigate = useNavigate();

  const idleTimerRef = useRef(null);
  const tokenCheckIntervalRef = useRef(null);

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      try {
        const response = await fetch("https://cbc-orfl.onrender.com/refresh", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${retrieve().refresh_token}`
          },
        });
        const data = await response.json();
        if (data.access_token) {
          localStorage.setItem("access_token", data.access_token);
          console.log("Access token refreshed");
        } else {
          logoutUser();
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        logoutUser();
      }
    } else {
      logoutUser();
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/");
  };

  const resetIdleTimer = () => {
    setIsIdle(false);
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    idleTimerRef.current = setTimeout(logoutUser, idleTimeout);
  };

  const checkTokenValidity = async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decodedToken.exp < currentTime) {
          console.warn("Access token has expired. Attempting to refresh.");
          await refreshAccessToken();
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        logoutUser();
      }
    } else {
      logoutUser();
    }
  };

  // Adjust app scale dynamically
  useEffect(() => {
    const adjustScale = () => {
      const rootElement = document.getElementById("root");
      const screenWidth = window.innerWidth;

      // Adjust scale based on screen size
      if (screenWidth <= 768) {
        // Small screens: no scaling
        rootElement.style.transform = `scale(1)`;
        rootElement.style.width = "100%";
        rootElement.style.height = "100%";
        rootElement.style.transformOrigin = "center top";
      } else {
        // Larger screens: 67% scale
        const scale = 0.80;
        rootElement.style.transform = `scale(${scale})`;
        rootElement.style.transformOrigin = "top left";
        rootElement.style.width = `${100 / scale}%`;
        rootElement.style.height = `${100 / scale}%`;
      }
    };

    adjustScale();

    window.addEventListener("resize", adjustScale);

    return () => {
      window.removeEventListener("resize", adjustScale);
    };
  }, []);

  useEffect(() => {
    idleTimerRef.current = setTimeout(logoutUser, idleTimeout);
    tokenCheckIntervalRef.current = setInterval(checkTokenValidity, 6 * 60 * 60 * 1000); // Every 6 hours

    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);
    window.addEventListener("click", resetIdleTimer);

    return () => {
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
      if (tokenCheckIntervalRef.current) {
        clearInterval(tokenCheckIntervalRef.current);
      }
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
      window.removeEventListener("click", resetIdleTimer);
    };
  }, [idleTimeout, navigate]);

  return null;
};

export default IdleLogout;
