import React, { useEffect, useState } from 'react';
import { useParams, useLocation,useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './AdminViewStudentFormativeReport.css'; // Import CSS for styling
import { FaEdit, FaDownload , FaArrowLeft } from "react-icons/fa";
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import { Box, CssBaseline,Typography } from '@mui/material';


const ParentViewStudentFormativeReport = () => {
    const { studentId } = useParams();
    const location = useLocation();
    const { student, gradeId, streamId,grade,streamName } = location.state; // Access the student, grade, and stream passed as props
    const [subjects, setSubjects] = useState([]); // Initialize as empty array
    const [selectedSubject, setSelectedSubject] = useState('');
    const [reportData, setReportData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Adjust the number of rows per page as needed
    const [selectedRubrics, setSelectedRubrics] = useState({}); 
    const [canEdit, setCanEdit] = useState(false); // Track if the teacher can edit the report
  
    const [editableSubjects, setEditableSubjects] = useState(new Set());
   
    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();

  
    const navigate=useNavigate()

    

    // Fetch subjects by grade
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/subjects/grade/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSubjects(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching subjects:', error);
            }
        };

        

        fetchSubjects();

     
      
        setEditableSubjects(new Set());
    }, [gradeId, streamId]);
    // This effect runs when the selectedSubject changes

 
  

    const isTeacherAssignedToSubject = async (subjectId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/subject/${subjectId}/${gradeId}/${streamId}/teacher`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            return data.teacher_id === userId;
        } catch (error) {
            console.error('Error checking teacher assignment:', error);
            return false;
        }
    };

    useEffect(() => {
        const fetchEditableSubjects = async () => {
            const editableSubjectsSet = new Set();
            for (const subject of subjects) {
                const isAssigned = await isTeacherAssignedToSubject(subject.id);
                if (isAssigned) {
                    editableSubjectsSet.add(subject.id);
                }
            }
            setEditableSubjects(editableSubjectsSet);
        };
        fetchEditableSubjects();
    }, [subjects, userId]);
    

    // Handle subject selection and fetch report details
    const handleSubjectChange = (e) => {
        const subjectId = e.target.value;
        setSelectedSubject(subjectId);
        setReportData(null); // Reset report data when subject changes

        if (subjectId) { // Fetch report data only if a subject is selected
            fetch(`https://cbc-orfl.onrender.com/get_student_report/${gradeId}/${subjectId}/${studentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setReportData(data); // Update reportData with fetched data
                    setCurrentPage(1); 
                })
                .catch(error => console.error('Error fetching report data:', error));
        } else {
            setReportData(null); // Reset report data if no subject is selected
        }
    };

    const handleDownloadPDF = async () => {
        if (!reportData) return;
        
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
        const fontSizeHeader = 10; // Font size for headers
        const fontSizeBody = 8; // Font size for body text
        const fontSizeSmall = 6; // Font size for smaller text like address and contact
    
        const fetchLogo = async () => {
            try {
                let logoUrl = schoolLogo;
    
                // Replace 'http' with 'https' if necessary
                if (logoUrl.startsWith('http://')) {
                    logoUrl = logoUrl.replace('http://', 'https://');
                }
    
                const response = await fetch(logoUrl);
                if (!response.ok) throw new Error("Failed to fetch logo");
    
                const blob = await response.blob();
                const reader = new FileReader();
    
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error("Failed to load logo:", error);
                return null; // Provide a fallback or skip adding the logo
            }
        };
    
        const logoBase64 = await fetchLogo();
    
        // Add the logo if successfully fetched
        if (logoBase64) {
            const imageWidth = 10; // Set the small width for the logo
            const imageHeight = 10; // Set the small height for the logo
            const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
            const yPosition = 10; // Top margin for the logo
    
            // Draw circular border around the logo
            const centerX = xPosition + imageWidth / 2;
            const centerY = yPosition + imageHeight / 2;
            const radius = imageWidth / 2;
    
            doc.setLineWidth(0.5);
    
            doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
        }
    
        // Find the selected subject's name from the subjects array
        const subject = subjects.find((subject) => subject.id === selectedSubject);
        const selectedSubjectName = subject ? subject.subject_name : "Unknown Subject";
        
        // Add school name below the logo
        doc.setFontSize(fontSizeHeader);
        doc.text(`${schoolName}`, pageWidth / 2, 25, { align: 'center' });
    
        // Add school contact information
        doc.setFontSize(fontSizeSmall);
        doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 30, { align: 'center' });
        doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 33, { align: 'center' });
        doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 36, { align: 'center' });

        doc.setFontSize(fontSizeHeader);
        doc.text(`${selectedSubjectName} Formative Report `, pageWidth / 2, 43, { align: 'center' });
    
    
        // Draw a bordered and shaded box for student information
        const studentInfoX = 34; // X position for the box
        const studentInfoY = 47; // Y position for the box
        const studentInfoWidth = pageWidth - 65; // Box width (centered with 20px padding on each side)
        const studentInfoHeight = 19; // Box height
    
        // Set border and fill colors
        doc.setLineWidth(0.1); // Reduced border weight for a thinner line      
        doc.setFillColor(243, 243, 244); // Light gray fill (RGB)
    
        // Draw the rectangle
        doc.rect(studentInfoX, studentInfoY, studentInfoWidth, studentInfoHeight, 'FD'); // 'FD' = Fill and Draw
    
        // Add the report title and student details inside the box
      
        
        doc.setFontSize(fontSizeBody);
        doc.text(`Name: ${student.first_name} ${student.last_name}`, pageWidth / 2, studentInfoY + 5, { align: 'center' });
        doc.text(`Admission Number: ${student.admission_number}`, pageWidth / 2, studentInfoY + 10, { align: 'center' });
        doc.text(`Grade: ${grade} | Stream: ${streamName}`, pageWidth / 2, studentInfoY + 15, { align: 'center' });
    
        const columns = ["Strand", "Sub-Strand", "Learning Outcome", "Assessment Rubric"];
        const rows = [];
    
        // Iterate through strands and build rows with formatted rubric info
        reportData.strands.forEach((strand) => {
            strand.sub_strands.forEach((subStrand) => {
                subStrand.learning_outcomes.forEach((learningOutcome) => {
                    // Format rubric names with [X] or [ ] and align checkboxes
                    const rubricInfo = learningOutcome.assessment_rubrics
                        .map((rubric) => {
                            const isSelected = rubric.assessment_rubic_id === learningOutcome.grade_type;
                            return `${rubric.assessment_rubic_name} ${isSelected ? "[X]" : "[ ]"}`;
                        })
                        .join("\n"); // Join rubric names with a new line
    
                    rows.push([
                        strand.strand_name,
                        subStrand.sub_strand_name,
                        learningOutcome.learning_outcome_name,
                        rubricInfo,
                    ]);
                });
            });
        });
    
        // Generate PDF table with adjusted styles
        doc.autoTable(columns, rows, {
            startY: 70, // Adjust start position to avoid overlapping
            styles: {
                fontSize: 6, // Reduced font size for better fit
                cellPadding: 2, // Adjust cell padding
            },
            headStyles: {
                halign: 'center', // Center align the table headers
            },
            columnStyles: {
                0: { cellWidth: 30 }, // Set width for Strand
                1: { cellWidth: 25 }, // Set width for Sub-Strand
                2: { cellWidth: 30 }, // Set width for Learning Outcome
                3: { cellWidth: 95 }, // Set width for Assessment Rubric
            },
        });
    
        // Save or output the PDF document
        doc.save(`${student.first_name}_${student.last_name}_Formative_Report.pdf`);
    };
    
    
    
    
    
    
    const isDownloadButtonVisible = reportData &&
        reportData.strands?.length > 0 &&
        reportData.strands.some(strand =>
            strand.sub_strands?.length > 0 &&
            strand.sub_strands.some(subStrand =>
                subStrand.learning_outcomes?.length > 0 &&
                subStrand.learning_outcomes.some(outcome =>
                    outcome.assessment_rubrics?.length > 0
                )
            )
        );
  
  
    // Handle rubric selection, track both rubric ID and mark
const handleRubricSelection = (learningOutcomeId, rubricId, rubricMark) => {
    // Update the selected rubrics state
    setSelectedRubrics(prevState => ({
        ...prevState,
        [learningOutcomeId]: { rubricId, rubricMark },  // Save both the ID and the mark
    }));

    // Optionally, directly update the grade_type in reportData
    setReportData(prevData => {
        const updatedStrands = prevData.strands.map(strand => ({
            ...strand,
            sub_strands: strand.sub_strands.map(subStrand => ({
                ...subStrand,
                learning_outcomes: subStrand.learning_outcomes.map(outcome => {
                    if (outcome.learning_outcome_id === learningOutcomeId) {
                        return { ...outcome, grade_type: rubricId }; // Update grade_type to selected rubric ID
                    }
                    return outcome;
                })
            }))
        }));

        return { ...prevData, strands: updatedStrands };
    });
};

    // Submit selected rubrics to the backend
    const handleUpdateReport = () => {
        const rubrics = Object.entries(selectedRubrics).map(([learningOutcomeId, { rubricId, rubricMark }]) => ({
            assessment_rubic_id: rubricId,
            single_mark: rubricMark,  // Include the mark in the payload
        }));

        fetch(`https://cbc-orfl.onrender.com/update_student_report/${gradeId}/${selectedSubject}/${studentId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${retrieve().access_token}`,
            },
            body: JSON.stringify({ rubrics }),  // Send the payload
        })
        .then(response => {
            if (!response.ok) throw new Error('Failed to update report');
            return response.json();
        })
        .then(() => {
            alert('Report updated successfully!');
        })
        .catch(error => {
            console.error('Error updating report:', error);
        });
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(reportData.strands.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startRow = (currentPage - 1) * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const paginatedStrands = reportData?.strands.slice(startRow, endRow) || [];
    useEffect(() => {
        if (selectedSubject) {
            setCanEdit(editableSubjects.has(selectedSubject));
        } else {
            setCanEdit(false);
        }
    }, [selectedSubject, editableSubjects]);
    
    
    return (
        <div className="report-container5">
              <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>

                <CssBaseline />
      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {/* Display the school logo */}
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px', // Set the desired width
                height: '100px', // Set the desired height
                objectFit: 'contain', // Ensure the image fits within the dimensions
                borderRadius: '50%', // Optional: Makes the logo circular
                border: '2px solid #ccc', // Optional: Adds a border
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
  {schoolName ? `${schoolName}` : "School Name"}
</Typography>

        
      </Box>

            <h1>Formative Report for {student.first_name} {student.last_name}</h1>
         

            <div  className="student-info"  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%", // Optional: ensures it takes up full height if needed
  }}>
                <p><strong>Admission Number:</strong> {student.admission_number}</p>
                <p><strong>Gender:</strong> {student.gender}</p>
                <p><strong>Stream:</strong> {streamName}</p>
                <p><strong>Grade:</strong> {grade}</p>
                {selectedSubject && (
                    <p><strong>Subject:</strong> {subjects.find(subject => subject.id === selectedSubject)?.subject_name}</p>
                )}
            </div>

            <label htmlFor="subject-select">Select Subject:</label>
            <select id="subject-select" onChange={handleSubjectChange} value={selectedSubject}>
                <option value="">--Select a Subject--</option>
                {Array.isArray(subjects) && subjects.map(subject => (
                    <option key={subject.id} value={subject.id}>{subject.subject_name}</option>
                ))}
            </select>
            {/* {editableSubjects.has(selectedSubject) && (
                <button
                            style={{ marginTop: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                            onClick={handleUpdateReport}
                        >
                            <FaEdit /> Update report
                        </button>
                    )} */}
            <h2 style={{ marginTop:"10px" }}>Report for {subjects.find(subject => subject.id === selectedSubject)?.subject_name}</h2>
           {isDownloadButtonVisible && (
                <button   style={{ marginTop: '10px', backgroundColor: '#4CAF50', marginLeft:"600px", color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}  onClick={handleDownloadPDF}>
                 <FaDownload /> Download Formative Report
                </button>
            )}

            {/* {reportData && reportData.strands && reportData.strands.length > 0 ? (
                <div>                 
                        
                               
                    
                    
                  
                  
                    <table className="ui celled padded table">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Strand</th>
                                <th style={{ textAlign: 'center' }}>Sub-Strand</th>
                                <th style={{ textAlign: 'center' }}>Learning Outcome</th>
                                <th style={{ textAlign: 'center' }}>Assessment Rubric</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedStrands.map(strand => (
                                <React.Fragment key={strand.strand_id}>
                                    {strand.sub_strands.map(subStrand => (
                                        <React.Fragment key={subStrand.sub_strand_id}>
                                            {subStrand.learning_outcomes.map(learningOutcome => (
                                                <tr key={learningOutcome.learning_outcome_id}>
                                                    <td>{strand.strand_name}</td>
                                                    <td>{subStrand.sub_strand_name}</td>
                                                    <td>{learningOutcome.learning_outcome_name}</td>
                                                    <td >
                                                    {learningOutcome.assessment_rubrics.map(rubric => (
                                                        <div key={rubric.assessment_rubic_id} className="rubric-row">
                                                        <span className="rubric-name">{rubric.assessment_rubic_name}</span>
                                                        <input
                                                          className="rubric-cell"
                                                          type="radio"
                                                          name={`rubric-${learningOutcome.learning_outcome_id}`}
                                                          value={rubric.assessment_rubic_id}
                                                          checked={learningOutcome.grade_type === rubric.assessment_rubic_id} // Check if the rubric ID matches the selected grade_type
                                                          onChange={() =>
                                                            handleRubricSelection(
                                                              learningOutcome.learning_outcome_id,
                                                              rubric.assessment_rubic_id,
                                                              rubric.assessment_rubic_mark
                                                            )
                                                          }
                                                          disabled={!canEdit}
                                                        />
                                                      </div>
                                                      
                                                    ))}

                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {Math.ceil(reportData.strands.length / rowsPerPage)}</span>
                        <button onClick={handleNextPage} disabled={currentPage >= Math.ceil(reportData.strands.length / rowsPerPage)}>
                            Next
                        </button>
                    </div>
                </div>
            ) : (
                <p>No report data available for the selected subject.</p>
            )} */}
        </div>
    );
};

export default ParentViewStudentFormativeReport;