import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaTrash } from "react-icons/fa";
import { retrieve } from './Encryption';
import './AddBehaviourGoals.css'; // Ensure this CSS file exists for styling
import { FaArrowLeft } from 'react-icons/fa';

const AddBehaviourGoals = () => {
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [behaviourGoals, setBehaviourGoals] = useState([{ behaviour_goal: '', grade_id: '' }]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [errorGrades, setErrorGrades] = useState('');
    const navigate = useNavigate();

    // Fetch grades when component mounts
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                    if (data.length > 0) {
                        setSelectedGrade(data[0].id); // Default to the first grade
                    }
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Handle input change for behaviour goals
    const handleGoalChange = (index, event) => {
        const newGoals = [...behaviourGoals];
        newGoals[index].behaviour_goal = event.target.value;
        newGoals[index].grade_id = selectedGrade;
        setBehaviourGoals(newGoals);
    };

    // Add a new behaviour goal input field
    const addBehaviourGoal = () => {
        setBehaviourGoals([...behaviourGoals, { behaviour_goal: '', grade_id: selectedGrade }]);
    };

    // Remove a behaviour goal input field
    const removeBehaviourGoal = (index) => {
        const newGoals = behaviourGoals.filter((_, i) => i !== index);
        setBehaviourGoals(newGoals);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://cbc-orfl.onrender.com/behaviour_goals', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({ behaviour_goals: behaviourGoals }),
            });

            if (response.ok) {
                alert('Behaviour goals added successfully!');
                navigate('/view-admin-dashboard/admin-view-behaviour-goals'); // Navigate to the view behavior goals page
            } else {
                const errorData = await response.json();
                alert(errorData.error || 'Failed to add behaviour goals');
            }
        } catch (error) {
            console.error('An error occurred while adding behaviour goals:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
      
            <div className="add-behaviour-goals-container">
                <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
                <h2 className='header'>Add Behaviour Goals</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grade-select-goal">
                        <label htmlFor="grade">Select Grade:</label>
                        {loadingGrades ? (
                            <p>Loading grades...</p>
                        ) : errorGrades ? (
                            <p style={{ color: 'red' }}>{errorGrades}</p>
                        ) : (
                            <select
                                id="grade"
                                value={selectedGrade}
                                onChange={(e) => setSelectedGrade(e.target.value)}
                            >
                                {grades.map(grade => (
                                    <option key={grade.id} value={grade.id}>
                                        {grade.grade}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
        
                    {behaviourGoals.map((goal, index) => (
                        <div key={index} className="goal-input-goal">
                            <input
                                type="text"
                                placeholder="Enter Behaviour Goal"
                                value={goal.behaviour_goal}
                                onChange={(event) => handleGoalChange(index, event)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => removeBehaviourGoal(index)}
                                className="remove-goal-button"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    ))}
        
                    <div className="button-container-goal">
                        <button
                            type="button" 
                            className="add-goal-button"
                            onClick={addBehaviourGoal}
                        >
                            <FaPlus /> Add Another Behaviour Goal
                        </button>
                        <button type="submit" className="submit-goals-button">
                            Submit Behaviour Goals
                        </button>
                    </div>
                </form>
            </div>
        );
        
};

export default AddBehaviourGoals;
