import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from "./Encryption";
import "./form.css"
import { FaArrowLeft } from 'react-icons/fa';

const AdminEditStaff = () => {
    const { staffId } = useParams();
    const location = useLocation();
    const { staffData, designations } = location.state;

    // Find the specific staff data from staffData list using staffId
    const data = staffData.find(staff => staff.id === staffId);

    const [formData, setFormData] = useState({
        payroll_number: data?.payroll_number || '',
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        date_of_birth: data?.date_of_birth || '',
        phone_number: data?.phone_number || '',
        alternative_contact: data?.alternative_contact || '',
        email_address: data?.email_address || '',
        designation_id: data?.designation_id || '',
        gender: data?.gender || '',
        is_class_teacher: data?.is_class_teacher || false,
        active_status: data?.active_status || false,
        photo_url: data?.photo_url || '',
    });

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePhoto(file);
        }
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== undefined && key !== 'photo_url') {
                form.append(key, formData[key]);
            }
        });

        if (profilePhoto) {
            form.append('profile_photo', profilePhoto);
        }

        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
            },
            body: form,
        };

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${staffId}`, options);
            if (response.ok) {
                const updatedStaffData = await response.json();
                setFormData(prevData => ({
                    ...prevData,
                    photo_url: updatedStaffData.photo_url,
                }));
                setSuccess('Staff updated successfully!');
                setError('');
                setTimeout(() => navigate('/view-admin-dashboard/admin-view-staff'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update staff.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error updating staff:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2 className='header'> Edit Staff</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {/* Profile Photo */}
                    <div className="field">
                        <label>
                            Profile Photo:
                            <div>
                                {formData.photo_url ? (
                                    <img src={formData.photo_url} alt="Profile" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                ) : (
                                    <img src="/path/to/placeholder.png" alt="Placeholder" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                )}
                            </div>
                        </label>
                    </div>

                    {/* Input Fields */}
                    {['payroll_number', 'first_name', 'last_name', 'date_of_birth', 'phone_number', 'alternative_contact', 'email_address'].map((field) => (
                        <div className="field" key={field}>
                            <label>
                                {formatLabel(field)}:
                                <input
                                    type={determineInputType(field)}
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    required={field !== 'alternative_contact'}
                                />
                            </label>
                        </div>
                    ))}

                    {/* Designation Field */}
                    <div className="field">
                        <label>
                            Designation:
                            <select
                                name="designation_id"
                                value={formData.designation_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Designation</option>
                                {designations.map((designation) => (
                                    <option key={designation.id} value={designation.id}>
                                        {designation.designation_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    {/* Gender Field */}
                    <div className="field">
                        <label>
                            Gender:
                            <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </label>
                    </div>

                    {/* Class Teacher Checkbox */}
                    <div className="field">
                        <label>
                            Class Teacher:
                            <input
                                type="checkbox"
                                name="is_class_teacher"
                                checked={formData.is_class_teacher}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </div>

                    {/* Active Status Checkbox */}
                    <div className="field">
                        <label>
                            Active Status:
                            <input
                                type="checkbox"
                                name="active_status"
                                checked={formData.active_status}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </div>

                    {/* File Upload for Profile Photo */}
                    <div className="field">
                        <label>
                            Upload Profile Photo:
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            type="submit"
                            className='submit'
                            style={{ width: "100%", maxWidth: "200px" }}
                        >
                            Update Staff
                        </button>
                    </div>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                </form>
            </div>
        </div>
    );

    function formatLabel(key) {
        return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    function determineInputType(key) {
        return key.includes('date') ? 'date' : 'text';
    }
};

export default AdminEditStaff;
