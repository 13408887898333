import React, { useState, useEffect } from "react";
import { retrieve } from './Encryption';
import { FaDownload } from "react-icons/fa";
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ClassPerformanceSummative = () => {
  const [viewType, setViewType] = useState(null);
  const [reportType, setReportType] = useState("");
  const [years, setYears] = useState([]);
  const [terms, setTerms] = useState([]);
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedStream, setSelectedStream] = useState("");
  const [performanceData, setPerformanceData] = useState(null);
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  

  useEffect(() => {
    fetchData("https://cbc-orfl.onrender.com/years", setYears);
    fetchData("https://cbc-orfl.onrender.com/terms", setTerms);
    fetchData("https://cbc-orfl.onrender.com/grades", setGrades);
  }, []);

  const fetchData = async (endpoint, setter) => {
    try {
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching ${endpoint}: ${response.statusText}`);
      }
      const data = await response.json();
      setter(data);
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  };

  const handleGradeChange = async (gradeId) => {
    setSelectedGrade(gradeId);
    setSelectedStream(""); // Reset stream on grade change
    setPerformanceData(null); // Clear performance data
    if (viewType === "stream") {
      fetchData(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, setStreams);
    }
  };

  const handleSelectionChange = (setter, value) => {
    setter(value);
    setPerformanceData(null); // Clear performance data on any selection change
  };

  const handleSubmit = async () => {
    let url;
    if (viewType === "grade") {
      url = reportType === "termly" 
        ? `https://cbc-orfl.onrender.com/class_performance_by_term_grade/${selectedGrade}/${selectedYear}/${selectedTerm}`
        : `https://cbc-orfl.onrender.com/class_performance_over_terms_grade/${selectedGrade}/${selectedYear}`;
    } else if (viewType === "stream") {
      url = reportType === "termly"
        ? `https://cbc-orfl.onrender.com/class_performance_by_term/${selectedGrade}/${selectedStream}/${selectedYear}/${selectedTerm}`
        : `https://cbc-orfl.onrender.com/class_performance_over_terms/${selectedGrade}/${selectedStream}/${selectedYear}`;
    }

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching class performance: ${response.statusText}`);
      }
      const data = await response.json();
      setPerformanceData(data);
    } catch (error) {
      console.error("Error fetching class performance:", error);
    }
  };

  const resetState = () => {
    setReportType("");
    setSelectedYear("");
    setSelectedTerm("");
    setSelectedGrade("");
    setSelectedStream("");
    setPerformanceData(null);
  };

  const getTermNameById = (id) => {
    const term = terms.find(term => term.id === id);
    return term ? term.term_name : "Unknown Term";
  };

  const isFetchButtonDisabled = !(
    reportType &&
    selectedYear &&
    selectedGrade &&
    (reportType === "yearly" || (reportType === "termly" && selectedTerm)) &&
    (viewType === "grade" || (viewType === "stream" && selectedStream))
  );
  const handleDownloadPDF = async () => {
    if (!performanceData) return;

    const doc = new jsPDF();
    doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
      
      // Center the title by calculating the X position
      const title = `Class Summative Performance Report - ${reportType.charAt(0).toUpperCase() + reportType.slice(1)} Report`;

      const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
      doc.text(title, titleX, 42);

      doc.setFontSize(10);
      const fontSizeSmall = 6; // Font size for smaller text like address and contact
      
      const fetchLogo = async () => {
        try {
            let logoUrl = schoolLogo;

            // Replace 'http' with 'https' if necessary
            if (logoUrl.startsWith('http://')) {
                logoUrl = logoUrl.replace('http://', 'https://');
            }

            const response = await fetch(logoUrl);
            if (!response.ok) throw new Error("Failed to fetch logo");

            const blob = await response.blob();
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Failed to load logo:", error);
            return null; // Provide a fallback or skip adding the logo
        }
    };

    const logoBase64 = await fetchLogo();

    // Add the logo if successfully fetched
    if (logoBase64) {
        const imageWidth = 10; // Set the small width for the logo
        const imageHeight = 10; // Set the small height for the logo
        const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
        const yPosition = 10; // Top margin for the logo

        // Draw circular border around the logo
        const centerX = xPosition + imageWidth / 2;
        const centerY = yPosition + imageHeight / 2;
        const radius = imageWidth / 2;

        doc.setLineWidth(0.5);
        doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
    }

    // Center the school name and contact details
    const textX = pageWidth / 2;
    doc.text(schoolName, textX, 25, { align: 'center' });
    doc.setFontSize(fontSizeSmall);
    doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
    doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
    doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });

    const columns = ['Term', 'Subject', 'Average Marks', 'Performance Percentage'];
    const rows = (reportType === "termly"
      ? performanceData.performance.map((subject) => [
          getTermNameById(selectedTerm),
          subject.subject_name,
          subject.average_marks,
           `${subject.performance_percentage}%`
        ])
      : Object.entries(performanceData.performance_by_term).flatMap(([termId, subjects]) =>
          subjects.map((subject) => [
            getTermNameById(termId),
            subject.subject_name,
            subject.average_marks,
            `${subject.performance_percentage}%`
          ])
        )
    );

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 47,
      styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
      headStyles: { halign: 'center' }, // Center align header cells
      bodyStyles: { halign: 'center' }, // Center align body cells
      columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 50 }, 2: { cellWidth: 50 }, 3: { cellWidth: 50 } },
    });

    doc.save('Class_Performance_Summative_Report.pdf');
  };

  return (
    <div className="container">
      <h2 className="header">View Class Summative Performance</h2>
      <div className="button-group" style={{ marginTop: "60px" }}>
        <button className="assign-button2" style={{ backgroundColor: "#8ac429" }} onClick={() => { resetState(); setViewType("grade"); }}>View by Grade</button>
        <button className="assign-button2" style={{ backgroundColor: "#ffb134" }} onClick={() => { resetState(); setViewType("stream"); }}>View by Stream</button>
      </div>

      {(viewType === "grade" || viewType === "stream") && (
        <div>
          <h3>Select Options for {viewType === "grade" ? "Grade" : "Stream"} View</h3>

          <div>
            <label>Report Type:</label>
            <select value={reportType} onChange={(e) => handleSelectionChange(setReportType, e.target.value)}>
              <option value="">Select Report Type</option>
              <option value="termly">Termly Report</option>
              <option value="yearly">Yearly Report</option>
            </select>
          </div>

          {reportType && (
            <div>
              <label>Year:</label>
              <select value={selectedYear} onChange={(e) => handleSelectionChange(setSelectedYear, e.target.value)}>
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year.id} value={year.id}>{year.year_name}</option>
                ))}
              </select>
            </div>
          )}

          {reportType === "termly" && (
            <div>
              <label>Term:</label>
              <select value={selectedTerm} onChange={(e) => handleSelectionChange(setSelectedTerm, e.target.value)}>
                <option value="">Select Term</option>
                {terms.map((term) => (
                  <option key={term.id} value={term.id}>{term.term_name}</option>
                ))}
              </select>
            </div>
          )}

          {reportType && (
            <div>
              <label>Grade:</label>
              <select value={selectedGrade} onChange={(e) => handleGradeChange(e.target.value)}>
                <option value="">Select Grade</option>
                {grades.map((grade) => (
                  <option key={grade.id} value={grade.id}>{grade.grade}</option>
                ))}
              </select>
            </div>
          )}

          {viewType === "stream" && reportType && (
            <div>
              <label>Stream:</label>
              <select value={selectedStream} onChange={(e) => handleSelectionChange(setSelectedStream, e.target.value)}>
                <option value="">Select Stream</option>
                {streams.map((stream) => (
                  <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
                ))}
              </select>
            </div>
          )}

          {!performanceData && (
            <button
              className="submit-button4"
              style={{ marginTop: "30px" }}
              onClick={handleSubmit}
              disabled={isFetchButtonDisabled}
            >
              Fetch Performance
            </button>
          )}

          {performanceData && (
            <div>
              <button
                className="download-button"
                style={{ marginTop: "20px", backgroundColor: "#4CAF50", color: "white" }}
                onClick={handleDownloadPDF}
              >
                <FaDownload style={{ marginRight: "5px" }} />
                Download Report
              </button>
              <h3 style={{ marginTop: "30px" }}>Class Performance Data</h3>
              <table className="performance-table">
                <thead>
                  <tr>
                    <th>Term</th>
                    <th>Subject</th>
                    <th>Average Marks</th>
                    <th>Performance Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {reportType === "termly" && performanceData.performance.map((subject, index) => (
                    <tr key={index}>
                      <td>{getTermNameById(selectedTerm)}</td>
                      <td>{subject.subject_name}</td>
                      <td>{subject.average_marks}</td>
                      <td>{subject.performance_percentage}%</td>
                    </tr>
                  ))}
                  {reportType === "yearly" && Object.entries(performanceData.performance_by_term).flatMap(([termId, subjects], termIndex) =>
                    subjects.map((subject, subjectIndex) => (
                      <tr key={`${termIndex}-${subjectIndex}`}>
                        <td>{getTermNameById(termId)}</td>
                        <td>{subject.subject_name}</td>
                        <td>{subject.average_marks}</td>
                        <td>{subject.performance_percentage}%</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassPerformanceSummative;
