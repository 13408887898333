// AssignClassTeacher.jsx
import React, { useEffect, useState } from 'react';
import { FaSave, FaSpinner,FaArrowLeft } from 'react-icons/fa';
import { retrieve } from './Encryption'; // Adjust the import based on your project structure
import './AssignClassTeacher.css'; // Ensure this CSS file exists and is correctly styled
import { useNavigate } from 'react-router-dom';

const AssignClassTeacher = () => {
    const [teachers, setTeachers] = useState([]);
    const [grades, setGrades] = useState([]);
    const [assignedGrades, setAssignedGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [loadingTeachers, setLoadingTeachers] = useState(true);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingAssignedGrades, setLoadingAssignedGrades] = useState(false);
    const [loadingStreams, setLoadingStreams] = useState(false);
    const [assigningTeacher, setAssigningTeacher] = useState(false);
    const [errorAssignment, setErrorAssignment] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Fetch all teachers on component mount
    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/teachers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTeachers(data);
                } else {
                    const errorData = await response.json();
                    setErrorAssignment(errorData.error || 'Failed to fetch teachers.');
                }
            } catch (error) {
                setErrorAssignment('An error occurred while fetching teachers.');
                console.error('Fetch Teachers Error:', error);
            } finally {
                setLoadingTeachers(false);
            }
        };

        fetchTeachers();
    }, []);

    // Fetch all grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorAssignment(errorData.error || 'Failed to fetch grades.');
                }
            } catch (error) {
                setErrorAssignment('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch grades assigned to the selected teacher
    useEffect(() => {
        const fetchAssignedGrades = async () => {
            if (!selectedTeacher) {
                setAssignedGrades([]);
                return;
            }

            setLoadingAssignedGrades(true);
            setAssignedGrades([]);
            setErrorAssignment('');
            setSuccessMessage('');

            try {
                // Fetch all grades and determine which are assigned to the teacher
                const assigned = [];

                // Create an array of fetch promises
                const fetchPromises = grades.map(async (grade) => {
                    try {
                        const response = await fetch(`https://cbc-orfl.onrender.com/teachers/grades/${grade.id}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${retrieve().access_token}`,
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            // Check if the selected teacher is in the list
                            const teacherExists = data.some(teacher => teacher.id === selectedTeacher);
                            if (teacherExists) {
                                assigned.push(grade);
                            }
                        } else {
                            // Handle non-OK responses if necessary
                            console.error(`Failed to fetch teachers for grade ${grade.id}`);
                        }
                    } catch (error) {
                        console.error(`Error fetching teachers for grade ${grade.id}:`, error);
                    }
                });

                // Wait for all fetches to complete
                await Promise.all(fetchPromises);

                setAssignedGrades(assigned);
            } catch (error) {
                console.error('Error fetching assigned grades:', error);
                setErrorAssignment('An error occurred while fetching assigned grades.');
            } finally {
                setLoadingAssignedGrades(false);
            }
        };

        fetchAssignedGrades();
    }, [selectedTeacher, grades]);

    // Fetch streams when a grade is selected
    useEffect(() => {
        const fetchStreams = async () => {
            if (!selectedGrade) {
                setStreams([]);
                return;
            }

            setLoadingStreams(true);
            setStreams([]);
            setErrorAssignment('');
            setSuccessMessage('');

            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setStreams(data);
                } else {
                    const errorData = await response.json();
                    setErrorAssignment(errorData.message || 'Failed to fetch streams.');
                }
            } catch (error) {
                setErrorAssignment('An error occurred while fetching streams.');
                console.error('Fetch Streams Error:', error);
            } finally {
                setLoadingStreams(false);
            }
        };

        fetchStreams();
    }, [selectedGrade]);

    // Handle selection of teacher
    const handleTeacherChange = (e) => {
        setSelectedTeacher(e.target.value);
        setSelectedGrade('');
        setSelectedStream('');
        setStreams([]);
        setAssignedGrades([]);
        setSuccessMessage('');
        setErrorAssignment('');
    };

    // Handle selection of grade
    const handleGradeChange = (e) => {
        setSelectedGrade(e.target.value);
        setSelectedStream('');
        setStreams([]);
        setSuccessMessage('');
        setErrorAssignment('');
    };

    // Handle selection of stream
    const handleStreamChange = (e) => {
        setSelectedStream(e.target.value);
        setSuccessMessage('');
        setErrorAssignment('');
    };

    // Handle form submission to assign class teacher
    const handleAssignClassTeacher = async (e) => {
        e.preventDefault();

        // Validation
        if (!selectedTeacher) {
            setErrorAssignment('Please select a teacher.');
            return;
        }
        if (!selectedGrade) {
            setErrorAssignment('Please select a grade.');
            return;
        }
        if (!selectedStream) {
            setErrorAssignment('Please select a stream.');
            return;
        }

        setAssigningTeacher(true);
        setErrorAssignment('');
        setSuccessMessage('');

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/assign_class_teacher', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({
                    staff_id: selectedTeacher,
                    grade_id: selectedGrade,
                    stream_id: selectedStream,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || 'Class teacher assigned successfully.');
                // Reset selections
                setSelectedTeacher('');
                setSelectedGrade('');
                setSelectedStream('');
                setStreams([]);
                setAssignedGrades([]);
            } else {
                const errorData = await response.json();
                setErrorAssignment(errorData.message || 'Failed to assign class teacher.');
            }
        } catch (error) {
            setErrorAssignment('An error occurred while assigning class teacher.');
            console.error('Assign Class Teacher Error:', error);
        } finally {
            setAssigningTeacher(false);
        }
    };

    return (
        <div className="assign-class-teacher-container">
              <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2 className="header">Assign Class Teacher</h2>

            {/* Feedback Messages */}
            {errorAssignment && <p className="error-message">{errorAssignment}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <form className="assign-class-teacher-form" onSubmit={handleAssignClassTeacher}>
                {/* Teacher Selection */}
                <div className="form-group">
                    <label htmlFor="teacher">Select Teacher:</label>
                    {loadingTeachers ? (
                        <p>Loading teachers...</p>
                    ) : teachers.length === 0 ? (
                        <p>No teachers available.</p>
                    ) : (
                        <select
                            id="teacher"
                            value={selectedTeacher}
                            onChange={handleTeacherChange}
                        >
                            <option value="">-- Select Teacher --</option>
                            {teachers.map((teacher) => (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.first_name} {teacher.last_name} (Payroll: {teacher.payroll_number})
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Grade Selection */}
                <div className="form-group">
                    <label htmlFor="grade">Select Grade:</label>
                    {loadingAssignedGrades ? (
                        <p>Loading assigned grades...</p>
                    ) : assignedGrades.length === 0 ? (
                        <p>No grades assigned to this teacher.</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={handleGradeChange}
                        >
                            <option value="">-- Select Grade --</option>
                            {assignedGrades.map((grade) => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Stream Selection */}
                {selectedGrade && (
                    <div className="form-group">
                        <label htmlFor="stream">Select Stream:</label>
                        {loadingStreams ? (
                            <p>Loading streams...</p>
                        ) : streams.length === 0 ? (
                            <p>No streams available for this grade.</p>
                        ) : (
                            <select
                                id="stream"
                                value={selectedStream}
                                onChange={handleStreamChange}
                            >
                                <option value="">-- Select Stream --</option>
                                {streams.map((stream) => (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.stream_name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                )}

                {/* Submit Button */}
                <button
                    type="submit"
                    className="assign-button3"
                    disabled={assigningTeacher}
                    
                >
                    {assigningTeacher ? (
                        <>
                            <FaSpinner className="spinner" /> Assigning...
                        </>
                    ) : (
                        <>
                            <FaSave /> Assign Class Teacher
                        </>
                    )}
                </button>
            </form>
        </div>
    );
};

export default AssignClassTeacher;
