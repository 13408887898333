import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrieve } from "./Encryption";
import { FaArrowLeft } from "react-icons/fa";
import * as XLSX from "xlsx";
import "./form.css";

const BulkAddParents = () => {
    const [excelFile, setExcelFile] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const isExcel = file.name.endsWith(".xlsx") || file.name.endsWith(".xls");
            if (isExcel) {
                setExcelFile(file);
                setError('');
            } else {
                setError("Invalid file type. Please upload an Excel file.");
                setExcelFile(null);
            }
        }
    };

    const handleUpload = async () => {
        if (!excelFile) {
            setError("Please upload an Excel file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", excelFile);

        try {
            const response = await fetch("https://cbc-orfl.onrender.com/parents/bulk-upload", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setSuccess("Parents uploaded successfully!");
                setError('');
                setTimeout(() => navigate("/view-admin-dashboard/admin-view-parents"), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || "Failed to upload parents.");
                setSuccess('');
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            setError("Something went wrong. Please try again.");
            setSuccess('');
        }
    };

    const downloadTemplate = () => {
        const templateData = [
            ["first_name", "last_name", "gender", "phone_number", "alternative_contact", "email_address", "date_of_birth", "relationship"]
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(templateData);
        // Apply text format to all cells
        Object.keys(worksheet).forEach((key) => {
            if (key.startsWith("!")) return; // Skip metadata
            worksheet[key].z = "@"; // Set the text format
        });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
        XLSX.writeFile(workbook, "parent_upload_template.xlsx");
    };

    return (
        <div className="content-wrapper">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>

            <h2 className="header">Bulk Add Parents</h2>
            <div>
                <button type="button" className="download-template" onClick={downloadTemplate} style={{ marginBottom: "10px" }}>
                    Download Excel Template
                </button>
            </div>
            <div className="form-container">
                {error && <div className="ui negative message">{error}</div>}
                {success && <div className="ui positive message">{success}</div>}

                <div className="field">
                    <label>Upload Excel File:</label>
                    <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
                </div>

                <div>
                    <button type="button" className="submit" onClick={handleUpload}>
                        Upload Parents
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BulkAddParents;