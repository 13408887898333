import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { Table, Header, Message, Segment, Dropdown } from 'semantic-ui-react';
import { retrieve } from './Encryption';

const ViewFormativeAssessments = () => {
    const { gradeId } = useParams();
    const navigate = useNavigate(); // useNavigate for navigation
    const [students, setStudents] = useState([]);
    const [loadingStudents, setLoadingStudents] = useState(true);
    const [errorStudents, setErrorStudents] = useState('');
    const [grade, setGrade] = useState('');
    const [streams, setStreams] = useState({});
    const [selectedStream, setSelectedStream] = useState('all'); // Add selected stream state

    useEffect(() => {
        // Fetch students
        const fetchStudents = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/students/grade/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setStudents(data);
                } else {
                    const errorData = await response.json();
                    setErrorStudents(errorData.error || 'Failed to fetch students');
                }
            } catch (error) {
                setErrorStudents('An error occurred while fetching students.');
                console.error('Fetch Students Error:', error);
            } finally {
                setLoadingStudents(false);
            }
        };

        // Fetch grade details
        const fetchGrade = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/grades/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrade(data.grade);
                } else {
                    console.error('Failed to fetch grade details');
                }
            } catch (error) {
                console.error('Fetch Grade Error:', error);
            }
        };

        // Fetch stream details
        const fetchStreams = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const streamMap = data.reduce((acc, stream) => {
                        acc[stream.id] = stream.stream_name;
                        return acc;
                    }, {});
                    setStreams(streamMap);
                } else {
                    console.error('Failed to fetch stream details');
                }
            } catch (error) {
                console.error('Fetch Streams Error:', error);
            }
        };

        fetchStudents();
        fetchGrade();
        fetchStreams();
    }, [gradeId]);

    const handleStreamChange = (e, { value }) => {
        setSelectedStream(value);
    };

    const filteredStudents = selectedStream === 'all'
        ? students
        : students.filter(student => student.stream_id === selectedStream);

    const streamOptions = [
        { key: 'all', text: 'All Streams', value: 'all' },
        ...Object.entries(streams).map(([id, name]) => ({
            key: id,
            text: name,
            value: id,
        })),
    ];

    const handleRowClick = (student) => {
        // Assuming the stream ID is part of the student object
        navigate(`/view-teacher-dashboard/edit-formative-assessments/${student.id}`, { 
            state: { 
                student, 
                gradeId, // Pass gradeId here
                streamId: student.stream_id // Pass the streamId here from the student object
            } 
        });
    };
    

    return (
        <Segment>
            <Header as="h2" textAlign="center">
                Formative Report for Grade: {grade}
            </Header>

            {loadingStudents ? (
                <Message info>
                    <Message.Header>Loading student data...</Message.Header>
                </Message>
            ) : errorStudents ? (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorStudents}</p>
                </Message>
            ) : students.length === 0 ? (
                <Message warning>
                    <Message.Header>No Students Found</Message.Header>
                    <p>No students are available for this grade.</p>
                </Message>
            ) : (
                <>
                    {/* Stream filter dropdown */}
                    <label>Filter By Stream</label>
                    <Dropdown
                        placeholder="Select Stream"
                        selection
                        options={streamOptions}
                        onChange={handleStreamChange}
                        value={selectedStream}
                        style={{ marginBottom: '20px' }} // Add space between dropdown and table
                    />

                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Admission Number</Table.HeaderCell>
                                <Table.HeaderCell>First Name</Table.HeaderCell>
                                <Table.HeaderCell>Last Name</Table.HeaderCell>
                                <Table.HeaderCell>Gender</Table.HeaderCell>
                                <Table.HeaderCell>Birth Certificate No</Table.HeaderCell>
                                <Table.HeaderCell>Joined Date</Table.HeaderCell>
                                <Table.HeaderCell>Grade</Table.HeaderCell>
                                <Table.HeaderCell>Stream</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Photo</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {filteredStudents.map((student) => (
                                <Table.Row key={student.id} onClick={() => handleRowClick(student)} style={{ cursor: 'pointer' }}>
                                    <Table.Cell>{student.admission_number}</Table.Cell>
                                    <Table.Cell>{student.first_name}</Table.Cell>
                                    <Table.Cell>{student.last_name}</Table.Cell>
                                    <Table.Cell>{student.gender}</Table.Cell>
                                    <Table.Cell>{student.birth_certificate_number}</Table.Cell>
                                    <Table.Cell>{student.joined_date}</Table.Cell>
                                    <Table.Cell>{grade}</Table.Cell>
                                    <Table.Cell>{streams[student.stream_id] || 'N/A'}</Table.Cell>
                                    <Table.Cell>{student.active_status ? 'Active' : 'Deactivated'}</Table.Cell>
                                    <Table.Cell>
                                        <img
                                            src={student.photo_url}
                                            alt={`${student.first_name} ${student.last_name}`}
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )}
        </Segment>
    );
};

export default ViewFormativeAssessments;
