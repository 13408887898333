import React from 'react';
import './DashboardLayout.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import { RiLogoutCircleRFill } from "react-icons/ri";
import { FaSignOutAlt } from 'react-icons/fa'; 

const Header = () => {
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = async () => {
    try {
      // Make a request to the backend logout endpoint
      const response = await fetch('/logout', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (response.ok) {
        // Clear tokens from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        
        // Redirect to the login page
        navigate('/');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div>
      <div style={{ height: '30px', backgroundColor:"#8ac429" }}></div>
    <header className="header" style={{ height: '50px' }}>
      <div className="header-content">
        <img src="/logo.png" alt="Logo" className="logo" />
        {/* Add the logout button */}
        <div onClick={handleLogout} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginRight: '25px' }}>
      <FaSignOutAlt style={{ color: '#ff615d', fontSize: '1.5em' }} />
      <span style={{ color: '#ff615d', fontSize: '0.9em' }}>Logout</span>
    </div>
      </div>
    </header>
    </div>
  );
};

export default Header;
