import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { retrieve } from './Encryption';
import './EditTermlyMarks.css';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft,FaDownload } from 'react-icons/fa6';


const EditTermlyMarks = () => {
    const { studentId, subjectId } = useParams();
    const location = useLocation();
    const { report, selectedTermId, gradeId,grade,streamName } = location.state || {}; 

    const [marks, setMarks] = useState({
        exam1: report?.term_exam1_marks ?? '',
        exam2: report?.term_exam2_marks ?? '',
        exam3: report?.term_exam3_marks ?? ''
    });

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();

    function parseJwt(token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );
        return JSON.parse(jsonPayload);
    }

    useEffect(() => {
        const retrievedData = retrieve();
        if (retrievedData && retrievedData.access_token) {
            const decodedToken = parseJwt(retrievedData.access_token);
            setUserId(decodedToken.staff_id);
        } else {
            console.log("No access token found");
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarks((prevMarks) => ({
            ...prevMarks,
            [name]: value === '' ? '' : parseFloat(value),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const updatedMarks = {};

        if (marks.exam1 !== report?.term_exam1_marks) {
            updatedMarks.term_exam1_marks = marks.exam1;
        }
        if (marks.exam2 !== report?.term_exam2_marks) {
            updatedMarks.term_exam2_marks = marks.exam2;
        }
        if (marks.exam3 !== report?.term_exam3_marks) {
            updatedMarks.term_exam3_marks = marks.exam3;
        }

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/edit_term_summative_report/${gradeId}/${studentId}/${selectedTermId}/${subjectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify(updatedMarks),
            });

            const data = await response.json();
                if (response.ok) {
                    setMessage('Marks updated successfully!');
                    setIsLoading(false);  // Ensure loading stops before navigation
                   
                  
                setTimeout(() => {
                    navigate(-1);
                }, 5000); // 20 seconds
                } else {
                    setMessage(data.error || 'An error occurred while updating marks.');
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error updating marks:', error);
                setMessage('An error occurred while updating marks.');
            } finally {
                setIsLoading(false);
            }
            
    };

    return (
        <div className="content-wrapper">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
            </div>
            <h2 className="header">Edit Termly Marks for {report?.subject_name}</h2>

            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="field">
                        <label>Exam 1 Marks:</label>
                        <input
                            type="number"
                            name="exam1"
                            value={marks.exam1}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="field">
                        <label>Exam 2 Marks:</label>
                        <input
                            type="number"
                            name="exam2"
                            value={marks.exam2}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="field">
                        <label>Exam 3 Marks:</label>
                        <input
                            type="number"
                            name="exam3"
                            value={marks.exam3}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="button-container">
                    <button type="submit" disabled={isLoading}   className="submit" style={{ color:"black", marginLeft:"150px" }}>
                        {isLoading ? 'Updating...' : 'Update Marks'}
                    </button>
                    </div>
        
                </form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default EditTermlyMarks;
