import React, { useState, useEffect } from 'react';
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import { retrieve } from './Encryption';
import './ViewStudents.css';
import { useNavigate } from 'react-router-dom';

const AdminViewParents = () => {
    const [grades, setGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [parents, setParents] = useState([]);
    const [errorParents, setErrorParents] = useState('');
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingStreams, setLoadingStreams] = useState(false);
    const [loadingParents, setLoadingParents] = useState(true);
   
    const [deletingParent, setDeletingParent] = useState(false);
    
    const [viewingParent, setViewingParent] = useState(null); // New state for viewing parent


   
    const [errorGrades, setErrorGrades] = useState('');

    const [errorStreams, setErrorStreams] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleViewParentDetails = (parentId) => {
        // Navigate to parent details page
        navigate(`/view-admin-dashboard/parent-details/${parentId}`, {
            state: {
                parents,
                grades,
            }
        });
    };

    // Fetch grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch streams when selectedGrade changes
    useEffect(() => {
        const fetchStreams = async () => {
            if (selectedGrade) {
                setLoadingStreams(true);
                setErrorStreams('');
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setStreams(data);
                    } else {
                        const errorData = await response.json();
                        setErrorStreams(errorData.error || 'Failed to fetch streams');
                    }
                } catch (error) {
                    setErrorStreams('An error occurred while fetching streams.');
                    console.error('Fetch Streams Error:', error);
                } finally {
                    setLoadingStreams(false);
                }
            } else {
                setStreams([]);
            }
        };

        fetchStreams();
    }, [selectedGrade]);

    // Fetch parents based on filters or fetch all parents
    useEffect(() => {
        const fetchParents = async () => {
            setLoadingParents(true);
            setErrorParents('');

            let url = 'https://cbc-orfl.onrender.com/parents'; // Default to fetch all parents

            if (selectedGrade && selectedStream) {
                url = `https://cbc-orfl.onrender.com/parents_by_grade_and_stream/${selectedGrade}/${selectedStream}`;
            } else if (selectedGrade) {
                url = `https://cbc-orfl.onrender.com/parents_by_grade/${selectedGrade}`;
            }

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setParents(data.parents || data); // Adjust based on API response structure
                } else {
                    const errorData = await response.json();
                    setErrorParents(errorData.error || 'Failed to fetch parents');
                    setParents([]);
                }
            } catch (error) {
                setErrorParents('An error occurred while fetching parents.');
                console.error('Fetch Parents Error:', error);
                setParents([]);
            } finally {
                setLoadingParents(false);
            }
        };

        fetchParents();
    }, [selectedGrade, selectedStream]);

    // Filter parents based on search term
    const filteredParents = parents.filter(parent =>
        parent.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        parent.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAddParent = () => {
        // Navigate to the add parent form (or show a modal)
        navigate('/view-admin-dashboard/add-parent',{
            state: { grades}
        });
    };

    const handleEditParent = (parentId) => {
        // Navigate to the edit parent form
        navigate(`/view-admin-dashboard/edit-parent/${parentId}`, {
            state: { parents }
        });
    };

    const handleDeleteParent = async  (parentId) => {
        // Confirm and delete the parent
        if (window.confirm("Are you sure you want to delete this parent?")) {
            setDeletingParent(true);
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}`, {
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${retrieve().access_token}` },
                });

                if (response.ok) {
                    setParents(prevData => prevData.filter(parent => parent.id !== parentId));
                    alert('parent deleted successfully.');
                } else {
                    const errorData = await response.json();
                    alert(errorData.error || 'Failed to delete parent');
                }
            } catch (error) {
                console.error('An error occurred while deleting the parent', error);
                alert('An error occurred. Please try again.');
            } finally {
                setDeletingParent(false);
            }
        }
    };

    return (
        <div className='view-students-container'>
            <h1 className='header'>View Parents</h1>
            <div >
                <div  >
                    <label>
                        Grade:
                        <select 
                            value={selectedGrade} 
                            onChange={(e) => {
                                setSelectedGrade(e.target.value);
                                setSelectedStream(''); // Reset stream when grade changes
                            }}
                        >
                            <option value="">Select Grade</option>
                            {grades.map(grade => (
                                <option key={grade.id} value={grade.id}>{grade.grade}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div >
                    <label>
                        Stream:
                        <select
                            value={selectedStream}
                            onChange={(e) => setSelectedStream(e.target.value)}
                            disabled={!selectedGrade}
                        >
                            <option value="">Select Stream</option>
                            {streams.map(stream => (
                                <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <input
                    type="text"
                    placeholder="Search by first_name or last_name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="button-container-add" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>

                <button className="add-student-button"style={{ marginRight: '10px' }} onClick={handleAddParent}>Add Parent</button>
                  <button
            className="add-student-button"
            onClick={() => navigate('/view-admin-dashboard/add-parents-in-bulk')}
        >
            Add Parents in Bulk
        </button>
                </div>

            <table className="ui striped table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
    {filteredParents.map(parent => (
        <tr 
            key={parent.id}
            onClick={() => handleViewParentDetails(parent.id)}
            style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickable row
        >
            <td>{`${parent.first_name} ${parent.last_name}`}</td>
            <td>{parent.email_address}</td>
            <td>{parent.phone_number}</td>
            <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div 
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginRight: '15px' }} 
                        onClick={(e) => { e.stopPropagation(); handleEditParent(parent.id); }}
                    >
                        <FaUserEdit style={{ color: 'green', fontSize: '20px', marginRight: '5px' }} />Edit
                    </div>
                    <div 
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                        onClick={(e) => { e.stopPropagation(); handleDeleteParent(parent.id); }}
                    >
                        <FaUserTimes style={{ color: 'red', fontSize: '20px', marginRight: '5px' }} />Delete
                    </div>
                </div>
            </td>
        </tr>
    ))}
</tbody>
            </table>
        </div>
    );
};

export default AdminViewParents;
