import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from "./Encryption";
import "./form.css";
import { FaArrowLeft } from 'react-icons/fa';

const EditStudent = () => {
    const { studentId } = useParams();
    const location = useLocation();
    const { grades, students } = location.state;
    const navigate = useNavigate();
    
    // Find the specific student data from students list using studentId
    const studentData = students.find(student => student.id === studentId);

    const [formData, setFormData] = useState({
        admission_number: studentData?.admission_number || '',
        first_name: studentData?.first_name || '',
        last_name: studentData?.last_name || '',
        gender: studentData?.gender || '',
        active_status: studentData?.active_status || false,
        date_of_birth: studentData?.date_of_birth?.split('T')[0] || '',
        joined_date: studentData?.joined_date?.split('T')[0] || '',
        birth_certificate_number: studentData?.birth_certificate_number || '',
        photo_url: studentData?.photo_url || '',
        grade_id: studentData?.grade_id || '',
        stream_id: studentData?.stream_id || '',
    });

    const [streams, setStreams] = useState([]);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Fetch streams based on selected grade_id
    const fetchStreams = async (gradeId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setStreams(data);
            } else {
                setStreams([]); // Reset streams if fetch fails
                throw new Error('Failed to fetch streams');
            }
        } catch (error) {
            console.error('Error fetching streams:', error);
            setError('Unable to fetch streams');
        }
    };

    // Trigger fetching streams when grade_id changes
    useEffect(() => {
        if (formData.grade_id) {
            fetchStreams(formData.grade_id);
        }
    }, [formData.grade_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePhoto(file);
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== undefined && key !== 'photo_url') {
                form.append(key, formData[key]);
            }
        });

        if (profilePhoto) {
            form.append('profile_photo', profilePhoto);
        }

        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
            },
            body: form,
        };

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/students/${studentId}`, options);
            if (response.ok) {
                const updatedStudentData = await response.json();
                setFormData(prevData => ({
                    ...prevData,
                    photo_url: updatedStudentData.photo_url,
                }));
                setSuccess('Student updated successfully!');
                setError('');
                setTimeout(() => navigate('/view-admin-dashboard/admin-view-students'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update student.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error updating student:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
                 <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>

            <h2 className='header'> Edit Student</h2>

            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {/* Profile Photo */}
                    <div className="field">
                        <label>
                            Profile Photo:
                            <div>
                                {formData.photo_url ? (
                                    <img src={formData.photo_url} alt="Profile" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                ) : (
                                    <img src="/path/to/placeholder.png" alt="Placeholder" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                )}
                            </div>
                        </label>
                    </div>

                    {/* Input Fields */}
                    {['admission_number', 'first_name', 'last_name'].map((field) => (
                        <div className="field" key={field}>
                            <label>
                                {formatLabel(field)}:
                                <input
                                    type="text"
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                    ))}

                    {/* Date Fields */}
                    {['joined_date', 'date_of_birth'].map((dateField) => (
                        <div className="field" key={dateField}>
                            <label>
                                {formatLabel(dateField)}:
                                <input
                                    type="date"
                                    name={dateField}
                                    value={formData[dateField]}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                    ))}

                    {/* Remaining Fields */}
                    {['birth_certificate_number'].map((field) => (
                        <div className="field" key={field}>
                            <label>
                                {formatLabel(field)}:
                                <input
                                    type="text"
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                    ))}

                    {/* Grade Field */}
                    <div className="field">
                        <label>
                            Grade:
                            <select
                                name="grade_id"
                                value={formData.grade_id}
                                onChange={(e) => {
                                    handleChange(e);
                                    fetchStreams(e.target.value); // Fetch streams for selected grade
                                }}
                                required
                            >
                                <option value="">Select Grade</option>
                                {grades.map((grade) => (
                                    <option key={grade.id} value={grade.id}>
                                        {grade.grade}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    {/* Stream Field */}
                    <div className="field">
                        <label>
                            Stream:
                            <select
                                name="stream_id"
                                value={formData.stream_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Stream</option>
                                {streams.map((stream) => (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.stream_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    {/* Active Status Checkbox */}
                    <div className="field">
                        <label>
                            Active Status:
                            <input
                                type="checkbox"
                                name="active_status"
                                checked={formData.active_status}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </div>

                    {/* File Upload for Profile Photo */}
                    <div className="field">
                        <label>
                            Upload Profile Photo:
                            <input type="file" onChange={handleFileChange} accept="image/*" />
                        </label>
                    </div>

                    {/* Error and Success Messages */}
                    {error && <div className="error">{error}</div>}
                    {success && <div className="success">{success}</div>}

                    {/* Submit Button */}
                    <div className="field">
                        <button type="submit" className='submit'>Update Student</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Helper function to format labels
const formatLabel = (field) => {
    return field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export default EditStudent;
