import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import SchoolStats from './SchoolStats';
import StudentChart from './StudentChart';
import TeacherSubjectDistribution from './TeacherSubjectDistribution';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const DashBoardTeacher= () => {
  // Access schoolId from Outlet context
  const { schoolId,schoolName,schoolLogo,schoolContact,schoolAddress,schoolEmail } = useOutletContext();

 
  return (
    <Box
      className="dashboard-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        position: 'relative',
        zIndex: 0,
      }}
    >

      <CssBaseline />
      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {/* Display the school logo */}
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px', // Set the desired width
                height: '100px', // Set the desired height
                objectFit: 'contain', // Ensure the image fits within the dimensions
                borderRadius: '50%', // Optional: Makes the logo circular
                border: '2px solid #ccc', // Optional: Adds a border
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
          {schoolName ? `${schoolName} Dashboard` : "School Dashboard"}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Overview of school metrics
        </Typography>
      </Box>

      {/* Dashboard Content */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <SchoolStats />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <StudentChart />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <TeacherSubjectDistribution />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashBoardTeacher;
