import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { store, retrieve } from "./Encryption";
import "./login.css";

const Login = () => {
  const [email_address, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const togglePasswordVisibility = () => setShowPassword(!showPassword); // Toggle password visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email_address, password };

    fetch("https://cbc-orfl.onrender.com/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Login failed");
        }
      })
      .then((result) => {
        store(result);
        const decryptedToken = retrieve();

        if (decryptedToken && decryptedToken.access_token) {
          const decodedToken = jwtDecode(decryptedToken.access_token);
          const designationCode = decodedToken.designation;
          const schoolCode=decodedToken.school_id;

          if (result.change_password_required) {
            // Redirect to change password component
            navigate("/login-change-password");
          } else {
            // Navigate to the appropriate dashboard if password change is not required
            navigateToDashboard(designationCode);
          }
        } else {
          setError("Failed to decrypt token.");
        }
      })
      .catch(() => setError("Invalid Email or Password: Please try again."));
  };


  const navigateToDashboard = (designationCode) => {
    if (designationCode === 100) navigate("/view-superadmin-dashboard");
    else if (designationCode === 101) navigate("/view-admin-dashboard");
    else if (designationCode === 102) navigate("/view-teacher-dashboard");
    else if (designationCode === 103) navigate("/view-parent-dashboard");
    else setError("Invalid user role");
  };

  return (
    <div className="login-container2">
      <div className="login-content">
        <div className="login-image">
          <img src="/login-image.png" alt="Login Illustration" />
        </div>
        <div className="login-form">
          <div className="form-card">
            <h1>Welcome Back!</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={email_address}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password:</label>
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle input type
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? "🙈" : "👁️"} {/* Eye icon */}
                  </span>
                </div>
              </div>
              <button type="submit" className="login-button">
                Login
              </button>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
