import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { retrieve } from './Encryption';
import { FaUserTimes, FaArrowLeft } from "react-icons/fa";

const ParentDetails = () => {
    const [parent, setParent] = useState(null);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const { parentId } = useParams(); // Extract parent ID from URL
    const location = useLocation();
    const { grades } = location.state;

    useEffect(() => {
        const fetchParentDetails = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setParent(data); // Set the full parent object
                    setStudents(data.students || []); // Extract students array
                } else {
                    const errorData = await response.json();
                    setError(errorData.error || 'Failed to fetch parent details');
                }
            } catch (err) {
                setError('An error occurred while fetching parent details.');
                console.error('Fetch Parent Details Error:', err);
            }
        };

        fetchParentDetails();
    }, [parentId]);

    const handleDelete = async (studentId) => {
        if (window.confirm("Are you sure you want to delete this student?")) {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}/students/${studentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (response.ok) {
                setStudents((prev) => prev.filter((student) => student.id !== studentId));
            } else {
                const errorData = await response.json();
                alert(errorData.error || 'Failed to delete student linkage');
            }
        } catch (err) {
            console.error('Delete Student Linkage Error:', err);
            alert('An error occurred while deleting the student linkage.');
        }
    };
}

    const handleAddLinkage = () => {
        navigate(`/view-admin-dashboard/link-parents-with-students/${parentId}`, {
            state: { grades, parent },
        });
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!parent) {
        return <div>Loading...</div>;
    }

    return (
        <div className='view-students-container'> 
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>
            <h1 className="header">Parent Details</h1>

            <h2>Staff Details</h2>
            {parent.staff_details ? (
                <>
                    <p><strong>Name:</strong> {`${parent.staff_details.first_name} ${parent.staff_details.last_name}`}</p>
                    <p><strong>Email:</strong> {parent.staff_details.email_address}</p>
                    <p><strong>Phone:</strong> {parent.staff_details.phone_number}</p>
                   
                </>
            ) : (
                <p>No staff details available.</p>
            )}
            {parent.relationship ? (
                <>
                          <p><strong>Relationship:</strong> {parent.relationship}</p>
                   
                </>
            ) : (
                <p>No relationship available.</p>
            )}

              <button className='add-student-button'
                onClick={handleAddLinkage}
                style={{
                    marginTop: '20px',
                   
                    padding: '10px 15px',
                    border: 'none',
                    borderRadius: '5px',
                }}
            >
                Link a student
            </button>
       

            <h2>Linked Students</h2>
            {students.length === 0 ? (
                <p>No students linked to this parent.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Admission Number</th>
                            <th>Grade</th>
                            <th>Stream</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => (
                            <tr key={student.id}>
                                <td>{`${student.first_name} ${student.last_name}`}</td>
                                <td>{student.admission_number}</td>
                                <td>{student.grade || 'N/A'}</td>
                                <td>{student.stream_name || 'N/A'}</td>
                                <td>
                                    <FaUserTimes
                                        style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}
                                        onClick={() => handleDelete(student.id)} 
                                    /> Delete
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

          

           
        </div>
    );
};

export default ParentDetails;
