// AssignTeacherSubject.jsx
import React, { useEffect, useState } from 'react';
import { FaSave, FaSpinner,FaArrowLeft } from "react-icons/fa";
import { retrieve } from './Encryption'; // Adjust the import based on your project structure
import './AssignTeacherSubject.css'; // Ensure this CSS file exists and is correctly styled
import { useNavigate } from 'react-router-dom';

const AssignTeacherSubject = () => {
    const [teachers, setTeachers] = useState([]);
    const [grades, setGrades] = useState([]);
    const [assignedGrades, setAssignedGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [loadingTeachers, setLoadingTeachers] = useState(true);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingAssignedGrades, setLoadingAssignedGrades] = useState(false);
    const [loadingStreams, setLoadingStreams] = useState(false);
    const [loadingSubjects, setLoadingSubjects] = useState(false);
    const [assigningAssignment, setAssigningAssignment] = useState(false);
    const [errorTeachers, setErrorTeachers] = useState('');
    const [errorGrades, setErrorGrades] = useState('');
    const [errorAssignment, setErrorAssignment] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [alreadyAssignedErrors, setAlreadyAssignedErrors] = useState([]);
    const navigate = useNavigate();

    // Fetch all teachers on component mount
    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/teachers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTeachers(data);
                } else {
                    const errorData = await response.json();
                    setErrorTeachers(errorData.error || 'Failed to fetch teachers.');
                }
            } catch (error) {
                setErrorTeachers('An error occurred while fetching teachers.');
                console.error('Fetch Teachers Error:', error);
            } finally {
                setLoadingTeachers(false);
            }
        };

        fetchTeachers();
    }, []);

    // Fetch all grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades.');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch grades assigned to the selected teacher
    useEffect(() => {
        const fetchAssignedGrades = async () => {
            if (!selectedTeacher) {
                setAssignedGrades([]);
                return;
            }

            setLoadingAssignedGrades(true);
            setAssignedGrades([]);
            setErrorAssignment('');
            setAlreadyAssignedErrors([]);

            try {
                // Fetch all grades and determine which are assigned to the teacher
                const assigned = [];

                // Create an array of fetch promises
                const fetchPromises = grades.map(async (grade) => {
                    try {
                        const response = await fetch(`https://cbc-orfl.onrender.com/teachers/grades/${grade.id}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${retrieve().access_token}`,
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            // Check if the selected teacher is in the list
                            const teacherExists = data.some(teacher => teacher.id === selectedTeacher);
                            if (teacherExists) {
                                assigned.push(grade);
                            }
                        } else {
                            // Handle non-OK responses if necessary
                            console.error(`Failed to fetch teachers for grade ${grade.id}`);
                        }
                    } catch (error) {
                        console.error(`Error fetching teachers for grade ${grade.id}:`, error);
                    }
                });

                // Wait for all fetches to complete
                await Promise.all(fetchPromises);

                setAssignedGrades(assigned);
            } catch (error) {
                console.error('Error fetching assigned grades:', error);
                setErrorAssignment('An error occurred while fetching assigned grades.');
            } finally {
                setLoadingAssignedGrades(false);
            }
        };

        fetchAssignedGrades();
    }, [selectedTeacher, grades]);

    // Fetch streams when a grade is selected
    useEffect(() => {
        const fetchStreams = async () => {
            if (!selectedGrade) {
                setStreams([]);
                return;
            }

            setLoadingStreams(true);
            setStreams([]);
            setErrorAssignment('');

            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setStreams(data);
                } else {
                    const errorData = await response.json();
                    setErrorAssignment(errorData.message || 'Failed to fetch streams.');
                }
            } catch (error) {
                setErrorAssignment('An error occurred while fetching streams.');
                console.error('Fetch Streams Error:', error);
            } finally {
                setLoadingStreams(false);
            }
        };

        fetchStreams();
    }, [selectedGrade]);

    // Fetch subjects when a grade is selected
    useEffect(() => {
        const fetchSubjects = async () => {
            if (!selectedGrade) {
                setSubjects([]);
                return;
            }

            setLoadingSubjects(true);
            setSubjects([]);
            setErrorAssignment('');

            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/subjects/grade/${selectedGrade}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setSubjects(data);
                } else {
                    const errorData = await response.json();
                    setErrorAssignment(errorData.message || 'Failed to fetch subjects.');
                }
            } catch (error) {
                setErrorAssignment('An error occurred while fetching subjects.');
                console.error('Fetch Subjects Error:', error);
            } finally {
                setLoadingSubjects(false);
            }
        };

        fetchSubjects();
    }, [selectedGrade]);

    // Handle selection of teacher
    const handleTeacherChange = (e) => {
        setSelectedTeacher(e.target.value);
        setSelectedGrade('');
        setSelectedStream('');
        setSelectedSubject('');
        setStreams([]);
        setSubjects([]);
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedErrors([]);
    };

    // Handle selection of grade
    const handleGradeChange = (e) => {
        setSelectedGrade(e.target.value);
        setSelectedStream('');
        setSelectedSubject('');
        setStreams([]);
        setSubjects([]);
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedErrors([]);
    };

    // Handle selection of stream
    const handleStreamChange = (e) => {
        setSelectedStream(e.target.value);
        setSelectedSubject('');
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedErrors([]);
    };

    // Handle selection of subject
    const handleSubjectChange = (e) => {
        setSelectedSubject(e.target.value);
        setSuccessMessage('');
        setErrorAssignment('');
        setAlreadyAssignedErrors([]);
    };

    // Handle form submission to assign subject
    const handleAssignSubject = async (e) => {
        e.preventDefault();

        // Validation
        if (!selectedTeacher) {
            setErrorAssignment('Please select a teacher.');
            return;
        }
        if (!selectedGrade) {
            setErrorAssignment('Please select a grade.');
            return;
        }
        if (!selectedStream) {
            setErrorAssignment('Please select a stream.');
            return;
        }
        if (!selectedSubject) {
            setErrorAssignment('Please select a subject.');
            return;
        }

        setAssigningAssignment(true);
        setErrorAssignment('');
        setSuccessMessage('');
        setAlreadyAssignedErrors([]);

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/assign_teacher_subject', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({
                    staff_id: selectedTeacher,
                    grade_id: selectedGrade,
                    stream_id: selectedStream,
                    subject_id: selectedSubject,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || 'Subject assigned successfully.');
                // Reset selections
                setSelectedTeacher('');
                setSelectedGrade('');
                setSelectedStream('');
                setSelectedSubject('');
                setStreams([]);
                setSubjects([]);
                setAssignedGrades([]);
            } else if (response.status === 409) { // Conflict
                const errorData = await response.json();
                setErrorAssignment(errorData.error || 'Failed to assign subject.');
            } else {
                const errorData = await response.json();
                setErrorAssignment(errorData.message || 'Failed to assign subject.');
            }
        } catch (error) {
            setErrorAssignment('An error occurred while assigning subject.');
            console.error('Assign Subject Error:', error);
        } finally {
            setAssigningAssignment(false);
        }
    };

    return (
        <div className="assign-subject-container">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2 className="header">Assign Subject to Teacher</h2>
           

            <form className="assign-subject-form" onSubmit={handleAssignSubject}>
                {/* Teacher Selection */}
                <div className="form-group">
                    <label htmlFor="teacher">Select Teacher:</label>
                    {loadingTeachers ? (
                        <p>Loading teachers...</p>
                    ) : errorTeachers ? (
                        <p className="error-message">{errorTeachers}</p>
                    ) : (
                        <select
                            id="teacher"
                            value={selectedTeacher}
                            onChange={handleTeacherChange}
                        >
                            <option value="">-- Select Teacher --</option>
                            {teachers.map(teacher => (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.first_name} {teacher.last_name} (Payroll: {teacher.payroll_number})
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Grade Selection */}
                <div className="form-group">
                    <label htmlFor="grade">Select Grade:</label>
                    {loadingAssignedGrades ? (
                        <p>Loading assigned grades...</p>
                    ) : assignedGrades.length === 0 ? (
                        <p>No grades assigned to this teacher.</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={handleGradeChange}
                        >
                            <option value="">-- Select Grade --</option>
                            {assignedGrades.map(grade => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Stream Selection */}
                {selectedGrade && (
                    <div className="form-group">
                        <label htmlFor="stream">Select Stream:</label>
                        {loadingStreams ? (
                            <p>Loading streams...</p>
                        ) : errorAssignment ? (
                            <p className="error-message">{errorAssignment}</p>
                        ) : streams.length === 0 ? (
                            <p>No streams available for this grade.</p>
                        ) : (
                            <select
                                id="stream"
                                value={selectedStream}
                                onChange={handleStreamChange}
                            >
                                <option value="">-- Select Stream --</option>
                                {streams.map(stream => (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.stream_name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                )}

                {/* Subject Selection */}
                {selectedGrade && (
                    <div className="form-group">
                        <label htmlFor="subject">Select Subject:</label>
                        {loadingSubjects ? (
                            <p>Loading subjects...</p>
                        ) : errorAssignment ? (
                            <p className="error-message">{errorAssignment}</p>
                        ) : subjects.length === 0 ? (
                            <p>No subjects available for this grade.</p>
                        ) : (
                            <select
                                id="subject"
                                value={selectedSubject}
                                onChange={handleSubjectChange}
                            >
                                <option value="">-- Select Subject --</option>
                                {subjects.map(subject => (
                                    <option key={subject.id} value={subject.id}>
                                        {subject.subject_name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                )}

                {/* Assignment Feedback */}
                {errorAssignment && <p className="error-message">{errorAssignment}</p>}
                {alreadyAssignedErrors.length > 0 && (
                    <div className="error-message">
                        <p>The following assignment(s) already exist:</p>
                        <ul>
                            {alreadyAssignedErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {successMessage && <p className="success-message">{successMessage}</p>}

                {/* Submit Button */}
                <button
                    type="submit"
                    className="assign-button3"
                    disabled={assigningAssignment}
                >
                    {assigningAssignment ? (
                        <>
                            <FaSpinner className="spinner" /> Assigning...
                        </>
                    ) : (
                        <>
                            <FaSave /> Assign Subject
                        </>
                    )}
                </button>
            </form>
        </div>
    );

};

export default AssignTeacherSubject;
