import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption'; // Assuming you have an encryption utility for handling tokens
import './form.css';
import { FaArrowLeft } from 'react-icons/fa';

const AddStream = () => {
    const [formData, setFormData] = useState({
        grade_id: '',
        stream_name: '',
    });
    const [grades, setGrades] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Fetch available grades
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    throw new Error('Failed to fetch grades');
                }
            } catch (error) {
                console.error('Error fetching grades:', error);
                setError('Unable to fetch grades');
            }
        };
        fetchGrades();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/streams', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccess('Stream added successfully!');
                setTimeout(() => navigate('/view-admin-dashboard/admin-view-streams'), 2000); // Redirect after success
            } else if (response.status === 409) {
                setError('Stream already exists for this school.');
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to add stream.');
            }
        } catch (error) {
            console.error('Error adding stream:', error);
            setError('Something went wrong. Please try again.');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2 className='header'>Add New Stream</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}
                    
                    {/* Grade Dropdown */}
                    <div className="field">
                        <label>Grade:</label>
                        <select
                            name="grade_id"
                            value={formData.grade_id}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Grade</option>
                            {grades.map((grade) => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade} {/* Adjust to correct property if different */}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Stream Name Input */}
                    <div className="field">
                        <label>Stream Name:</label>
                        <input
                            type="text"
                            name="stream_name"
                            value={formData.stream_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="">
                        <button type="submit" className="submit">
                            Add Stream
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddStream;
