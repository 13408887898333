import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import './EditBehaviourComments.css';
import { FaArrowLeft } from 'react-icons/fa';

const EditYearClassTeachersComments = () => {
    const location = useLocation();
    const { studentId } = useParams();
    const { gradeId, streamName, grade, student, classTeachersComments, classTeachersRemarks } = location.state;
    const [comments, setComments] = useState(classTeachersComments || '');
    const [remarks, setRemarks] = useState(classTeachersRemarks || '');
    
    const navigate = useNavigate();

    // Separate handleInputChange functions for comments and remarks
    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    const handleRemarksChange = (e) => {
        setRemarks(e.target.value);
    };

    

    // Function to submit the updated comments to the backend
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/year_comments_remarks/${gradeId}/${studentId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({ class_teachers_comments: comments, general_remarks: remarks }),
            });

            if (response.ok) {
                const data = await response.json();
             
                // Navigate back to the main report page
                navigate(-1);
            } else {
                console.error('Failed to update comments');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="edit-comments-container">
              <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
            </div>
            <h2>Edit Class Teacher's Year Comments </h2>
            <div className="personal-details">
            <p><strong>Name</strong> {student.first_name} {student.last_name}</p>
            <p><strong>Grade:</strong> {grade}</p>
            <p><strong>Stream:</strong> {streamName}</p>
            </div>
            <form onSubmit={handleSubmit} className="comments-form">
                <label htmlFor="remarks">General Remarks:</label>
                <textarea
                    id="remarks"
                    value={remarks}
                    onChange={handleRemarksChange}
                    rows="5"
                    placeholder="Enter your general remarks here..."
                />
                <label htmlFor="comments">Class Teacher's Comments:</label>
                <textarea
                    id="comments"
                    value={comments}
                    onChange={handleCommentsChange}
                    rows="5"
                    placeholder="Enter your comments here..."
                />
        
                <div className="button-container">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="cancel-button2"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="submit" style={{ color:"black" }}>
                        Save Comments
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditYearClassTeachersComments;
