import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Header, Segment, Table, Message, Dropdown, Input } from 'semantic-ui-react';
import { retrieve } from './Encryption';
import { FaPlus, FaChalkboardTeacher, FaBook, FaUserShield } from "react-icons/fa";
import "./GradeDetails.css";



const TeacherViewGradeDetails = () => {
    const { gradeId } = useParams();
    const [gradeDetails, setGradeDetails] = useState(null);
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [streams, setStreams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedStream, setSelectedStream] = useState(null); 
    const [visibleSection, setVisibleSection] = useState(null);
    const [studentSearchTerm, setStudentSearchTerm] = useState('');
    const [teacherSearchTerm, setTeacherSearchTerm] = useState('');
    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [filteredStudentsSearch, setFilteredStudentsSearch] = useState([]);
    
    useEffect(() => {
        const fetchGradeDetails = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/grades/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setGradeDetails(data);
                } else {
                    setError('Failed to fetch grade details');
                }
            } catch (error) {
                setError('An error occurred while fetching grade details.');
                console.error('Fetch Grade Details Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGradeDetails();
        fetchStreams(gradeId);
    }, [gradeId]);

    const fetchStudents = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/students/grade/${gradeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            if (response.ok) {
                const studentsData = await response.json();
                setStudents(studentsData);
                setFilteredStudents(studentsData);
                setVisibleSection('students');
            } else {
                setError('Failed to fetch students');
            }
        } catch (error) {
            setError('An error occurred while fetching students.');
            console.error('Fetch Students Error:', error);
        }
    };

    const fetchTeachers = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/teachers/grades/${gradeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setTeachers(data);
                setVisibleSection('teachers');
            } else {
                setError('Failed to fetch teachers');
            }
        } catch (error) {
            setError('An error occurred while fetching teachers.');
            console.error('Fetch Teachers Error:', error);
        }
    };
    const fetchSubjects = async () => {
      try {
          const response = await fetch(`https://cbc-orfl.onrender.com/subjects_teachers/grade/${gradeId}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${retrieve().access_token}`,
              },
          });
          if (response.ok) {
              const data = await response.json();
              setSubjects(data);  // Expecting subjects with teachers and streams
              setVisibleSection('subjects');
          } else {
              setError('Failed to fetch subjects');
          }
      } catch (error) {
          setError('An error occurred while fetching subjects.');
          console.error('Fetch Subjects Error:', error);
      }
  };
  const fetchStreams = async (gradeId) => {
    try {
        const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${retrieve().access_token}`,
            },
        });
        if (response.ok) {
            const streamData = await response.json();
            setStreams(streamData);
        } else {
            setError('Failed to fetch streams');
        }
    } catch (error) {
        setError('An error occurred while fetching streams.');
        console.error('Fetch Streams Error:', error);
    }
};

    useEffect(() => {
        setFilteredStudentsSearch(
            students.filter(student =>
                student.first_name.toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
                student.last_name.toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
                student.admission_number.toString().toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
                student.birth_certificate_number.toString().includes(studentSearchTerm.toLowerCase())
            )
        );
    }, [studentSearchTerm, students]);

    useEffect(() => {
        setFilteredTeachers(
            teachers.filter(teacher =>
                teacher.first_name.toLowerCase().includes(teacherSearchTerm.toLowerCase()) ||
                teacher.last_name.toLowerCase().includes(teacherSearchTerm.toLowerCase()) ||
                teacher.payroll_number.toString().toLowerCase().includes(teacherSearchTerm.toLowerCase())
            )
        );
    }, [teacherSearchTerm, teachers]);

    return (
        <Segment>
            {loading ? (
                <Message info>
                    <Message.Header>Loading grade details...</Message.Header>
                </Message>
            ) : error ? (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{error}</p>
                </Message>
            ) : (
                <div>
                <Header as="h2">{gradeDetails?.grade}</Header>
                <div className='assign-buttons' >
                    <Segment className="button-container" style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop:"40px" }}>
                        <Button className="assign-button" onClick={fetchStudents} style={{ fontSize: "17px", backgroundColor: "#8ac429", fontFamily: "cursive" }}>
                            <FaUserShield style={{ marginRight: '8px' }} />
                            View Students
                        </Button>
                        <Button className="assign-button" onClick={fetchSubjects} style={{ fontSize: "17px", backgroundColor: "#ffb134", fontFamily: "cursive"}}>
                            <FaBook style={{ marginRight: '8px' }} />
                            View Subjects
                        </Button>
                        <Button className="assign-button" onClick={fetchTeachers} style={{ fontSize: "17px", backgroundColor: "#ff615d", fontFamily: "cursive" }}>
                            <FaChalkboardTeacher style={{ marginRight: '8px' }} />
                            View Teachers
                        </Button>
                    </Segment>
                    </div>

                    {visibleSection === 'students' && (
                        
                        <>
                            {streams.length > 0 && (
                                <div className="stream-filter">
                                <label as="h4" className="filter-label">Filter by Stream</label>
                                <Dropdown
                                    placeholder="Select Stream"
                                    fluid
                                    selection
                                    options={streams.map(stream => ({
                                        key: stream.id,
                                        text: stream.stream_name,
                                        value: stream.id,
                                    }))}
                                    onChange={(e, { value }) => setSelectedStream(value)}
                                    value={selectedStream}
                                    clearable
                                />
                            </div>

                            )}
                            <Input
                                placeholder="Search students by Name, Adm No, Birth Certificate No"
                                value={studentSearchTerm}
                                onChange={(e) => setStudentSearchTerm(e.target.value)}
                                className="search-input"
                            />
                            <Header as="h3">Students</Header>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>ADM NO</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Gender</Table.HeaderCell>
                                        <Table.HeaderCell>DOB</Table.HeaderCell>
                                        <Table.HeaderCell>Birth Certificate NO</Table.HeaderCell>
                                        <Table.HeaderCell>Grade</Table.HeaderCell>
                                        <Table.HeaderCell>Stream</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {filteredStudentsSearch.map(student => (
                                        <Table.Row key={student.id}>
                                            <Table.Cell>{student.admission_number}</Table.Cell>
                                            <Table.Cell>{student.first_name} {student.last_name}</Table.Cell>
                                            <Table.Cell>{student.gender}</Table.Cell>
                                            <Table.Cell>{student.date_of_birth}</Table.Cell>
                                            <Table.Cell>{student.birth_certificate_number}</Table.Cell>
                                            <Table.Cell>{gradeDetails?.grade}</Table.Cell>
                                            <Table.Cell>{streams.find(s => s.id === student.stream_id)?.stream_name}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                    {/* Subjects Section */}
                    {visibleSection === 'subjects' && subjects.length > 0 && (
                        <div>
                            <Header as="h3" style={{ marginTop:"30px" }}>Subjects</Header>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Subject Name</Table.HeaderCell>
                                        <Table.HeaderCell>Teachers</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {subjects.map(subject => (
                                        <Table.Row key={subject.subject_name}>
                                            <Table.Cell>{subject.subject_name}</Table.Cell>
                                            <Table.Cell>
                                                {subject.teachers.map(teacher => (
                                                    <div key={teacher.teacher_name}>
                                                        <strong>{teacher.teacher_name}</strong> - Stream {teacher.stream_name}
                                                    </div>
                                                ))}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    )}


                    {visibleSection === 'teachers' && (
                        <>
                            <Input
                                placeholder="Search teachers..."
                                value={teacherSearchTerm}
                                onChange={(e) => setTeacherSearchTerm(e.target.value)}
                                className='search-input'
                            />
                            <Header as="h3">Teachers</Header>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Payroll Number</Table.HeaderCell>
                                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                                        <Table.HeaderCell>Email Address</Table.HeaderCell>
                                        <Table.HeaderCell>Is Class Teacher?</Table.HeaderCell>
                                        <Table.HeaderCell>Grade</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {filteredTeachers.map(teacher => (
                                        <Table.Row key={teacher.id}>
                                            <Table.Cell>{teacher.payroll_number}</Table.Cell>
                                            <Table.Cell>{teacher.first_name} {teacher.last_name}</Table.Cell>
                                            <Table.Cell>{teacher.first_name} {teacher.last_name}</Table.Cell>
                                            <Table.Cell>{teacher.phone_number}</Table.Cell>
                                            <Table.Cell>{teacher.email_address}</Table.Cell>
                                            <Table.Cell>{teacher.is_class_teacher ? 'Yes' : 'No'}</Table.Cell>
                                            <Table.Cell>{gradeDetails?.grade}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </div>
            )}
        </Segment>
    );
};

export default TeacherViewGradeDetails;