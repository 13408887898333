import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from './Encryption';
import './EditBehaviourGoalsReports.css'; // Assuming you create a CSS file for styling
import { FaArrowLeft } from 'react-icons/fa';


const EditBehaviourGoalsReport = () => {
    const { studentId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Extract the data passed through navigate state
    const { gradeId, student, behaviourGoalsReports } = location.state || {};

    useEffect(() => {
        const fetchOrSetReport = async () => {
            if (behaviourGoalsReports && behaviourGoalsReports.length > 0) {
                setReport({ behaviour_goals: behaviourGoalsReports });
                setLoading(false);
            } else {
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/behaviour_reports/${studentId}/${gradeId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setReport({
                            ...data.behaviour_reports,
                            behaviour_goals: data.behaviour_reports.behaviour_goals_reports || [],
                        });
                    } else {
                        const errorData = await response.json();
                        setError(errorData.error || 'Failed to fetch report details');
                    }
                } catch (error) {
                    setError('An error occurred while fetching report details.');
                    console.error('Fetch Report Error:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchOrSetReport();
    }, [gradeId, studentId, behaviourGoalsReports]);

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/update_behaviour_reports/${gradeId}/${studentId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({
                    behaviour_goal_assessments: report.behaviour_goals.map(goal => ({
                        behaviour_goal_id: goal.behaviour_goal_id,
                        behaviour_goal_assessment: goal.behaviour_goal_assessment || '',
                    })),
                }),
            });

            if (!response.ok) {
                setError('Failed to update the report.');
            } else {
                navigate(-1); // Change to the appropriate route
            }
        } catch (error) {
            setError('An error occurred while updating the report.');
            console.error('Update Report Error:', error);
        }
    };

    if (loading) {
        return <p className="loading-text">Loading...</p>;
    }

    if (error) {
        return <p className="error-text">{error}</p>;
    }

    // Render the report editing form
    return (
        <div className="report-edit-container">
             <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
            </div>

            <h2>Edit Behaviour Goal Report for {student.first_name} {student.last_name}</h2>
            <form onSubmit={handleSubmit} className="edit-form">
                {report.behaviour_goals.map((goal, index) => (
                    <div key={goal.behaviour_goal_id} className="goal-item">
                        <label className="goal-label">
                            {goal.behaviour_goal}
                            <input
                                type="text"
                                value={goal.behaviour_goal_assessment || ''}
                                onChange={(e) => {
                                    const updatedGoals = [...report.behaviour_goals];
                                    updatedGoals[index].behaviour_goal_assessment = e.target.value;
                                    setReport({ ...report, behaviour_goals: updatedGoals });
                                }}
                                className="goal-input"
                            />
                        </label>
                    </div>
                ))}
                <button type="submit" className="submit-button">Save Changes</button>
            </form>
        </div>
    );
};

export default EditBehaviourGoalsReport;
