import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import './StudentSummativePerformance.css'; // Import CSS file for styling
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const StudentSummativePerformance = () => {
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStream, setSelectedStream] = useState('');
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();


  useEffect(() => {
    fetchYears();
    fetchGrades();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = students.filter((student) =>
        `${student.first_name} ${student.last_name}`.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(students);
    }
  }, [searchQuery, students]);

  const fetchYears = async () => {
    const response = await fetch('https://cbc-orfl.onrender.com/years', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setYears(data);
  };

  const fetchGrades = async () => {
    const response = await fetch('https://cbc-orfl.onrender.com/grades', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setGrades(data);
  };

  const fetchStreams = async (gradeId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setStreams(data);
  };

  const fetchStudents = async (gradeId, streamId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/students/grades/${gradeId}/streams/${streamId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setStudents(data);
  };

  const handleYearChange = (e) => setSelectedYear(e.target.value);
  const handleGradeChange = (e) => {
    const gradeId = e.target.value;
    setSelectedGrade(gradeId);
    setStreams([]);
    setStudents([]);
    if (gradeId) fetchStreams(gradeId);
  };
  
  const handleStreamChange = (e) => {
    const streamId = e.target.value;
    setSelectedStream(streamId);
    setStudents([]);
    if (streamId && selectedGrade) fetchStudents(selectedGrade, streamId);
  };

  const handleViewTermPerformance = (student) => {
    const selectedYearData = years.find((year) => year.id === selectedYear);
    const year_name = selectedYearData ? selectedYearData.year_name : '';
    const selectedGradeData = grades.find((grade) => grade.id === selectedGrade);
    const grade_name = selectedGradeData ? selectedGradeData.grade : '';
    const selectedStreamData = streams.find((stream) => stream.id === selectedStream);
    const stream_name = selectedStreamData ? selectedStreamData.stream_name : '';
    
    navigate('/view-admin-dashboard/student-term-performance-details', {
      state: { 
        student, 
        year_id: selectedYear, 
        year_name, 
        grade_id: selectedGrade, 
        stream_id: selectedStream, 
        grade_name, 
        stream_name 
      }
    });
  };

  const handleYearPerformanceClick = (student) => {
    const selectedYearData = years.find((year) => year.id === selectedYear);
    const year_name = selectedYearData ? selectedYearData.year_name : '';
    const selectedGradeData = grades.find((grade) => grade.id === selectedGrade);
    const grade_name = selectedGradeData ? selectedGradeData.grade : '';
    const selectedStreamData = streams.find((stream) => stream.id === selectedStream);
    const stream_name = selectedStreamData ? selectedStreamData.stream_name : '';

    navigate('/view-admin-dashboard/student-year-performance-details', {
      state: { student, year_id: selectedYear, year_name, grade_id: selectedGrade, stream_id: selectedStream, grade_name, stream_name }
    });
  };

  return (
    <div className="container">
      <h2 className="header">View Student Summative Performance</h2>
      
      <div className="select-group">
        <label>
          Select Year:
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">--Select Year--</option>
            {years.map((year) => (
              <option key={year.id} value={year.id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </label>
      </div>
      
      <div className="select-group">
        <label>
          Select Grade:
          <select value={selectedGrade} onChange={handleGradeChange}>
            <option value="">--Select Grade--</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.grade}
              </option>
            ))}
          </select>
        </label>
      </div>
      
      {streams.length > 0 && (
        <div className="select-group">
          <label>
            Select Stream:
            <select value={selectedStream} onChange={handleStreamChange}>
              <option value="">--Select Stream--</option>
              {streams.map((stream) => (
                <option key={stream.id} value={stream.id}>
                  {stream.stream_name}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {students.length > 0 && (
        <div className="student-search">
          <h3>Students in Selected Grade and Stream</h3>
          <label>
            Search Student:
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by name"
            />
          </label>
          
          <div className="student-table">
            <table>
              <thead>
                <tr>
                <th style={{ width: "60%" }}>Name</th>
                <th style={{ width: "40%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => (
                  <tr key={student.id}>
                    <td>{`${student.first_name} ${student.last_name}`}</td>
                    <td className="actions">
                      <button style={{ backgroundColor:"#8ac429", fontFamily:"cursive", fontSize:"1.2em" }} onClick={() => handleViewTermPerformance(student)}>
                        Term Performance
                      </button>
                      <button style={{ backgroundColor:"#8ac429", fontFamily:"cursive", fontSize:"1.2em" }} onClick={() => handleYearPerformanceClick(student)}>
                        Yearly Performance
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentSummativePerformance;
