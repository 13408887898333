import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { FaDownload } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const ClassPerformanceFormative = () => {
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStream, setSelectedStream] = useState('');
  const [classPerformanceData, setClassPerformanceData] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false); // New state to toggle button visibility
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  

  // Fetch years from the backend
  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/years', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => setYears(data))
      .catch((error) => console.error('Error fetching years:', error));
  }, []);

  // Fetch grades from the backend
  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/grades', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => setGrades(data))
      .catch((error) => console.error('Error fetching grades:', error));
  }, []);

  // Fetch streams based on selected grade
  const fetchStreams = async (gradeId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setStreams(data);
  };

  // Fetch class performance data
  const fetchClassPerformanceData = async () => {
    if (selectedYear && selectedGrade && selectedStream) {
      const response = await fetch(
        `https://cbc-orfl.onrender.com/class/performance/${selectedGrade}/${selectedStream}/${selectedYear}`,
        {
          headers: { Authorization: `Bearer ${retrieve().access_token}` },
        }
      );
      const data = await response.json();
      setClassPerformanceData(data);
      setIsDataFetched(true); // Set data fetched state
    }
  };

  // Handle year selection
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    resetData(); // Reset when year is changed
  };

  // Handle grade selection
  const handleGradeChange = (e) => {
    const gradeId = e.target.value;
    setSelectedGrade(gradeId);
    setStreams([]);
    resetData(); // Reset when grade is changed
    if (gradeId) fetchStreams(gradeId);
  };

  // Handle stream selection
  const handleStreamChange = (e) => {
    setSelectedStream(e.target.value);
    resetData(); // Reset when stream is changed
  };

  // Reset data and button visibility
  const resetData = () => {
    setClassPerformanceData(null);
    setIsDataFetched(false);
  };

  // Download PDF functionality
  const handleDownloadPDF = async() => {
    const doc = new jsPDF();
   
    doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
      
      // Center the title by calculating the X position
      const title = 'Class Formative Performance Report';
      const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
      doc.text(title, titleX, 42);

      doc.setFontSize(10);
      const fontSizeSmall = 6; // Font size for smaller text like address and contact
      
      const fetchLogo = async () => {
        try {
            let logoUrl = schoolLogo;

            // Replace 'http' with 'https' if necessary
            if (logoUrl.startsWith('http://')) {
                logoUrl = logoUrl.replace('http://', 'https://');
            }

            const response = await fetch(logoUrl);
            if (!response.ok) throw new Error("Failed to fetch logo");

            const blob = await response.blob();
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Failed to load logo:", error);
            return null; // Provide a fallback or skip adding the logo
        }
    };

    const logoBase64 = await fetchLogo();

    // Add the logo if successfully fetched
    if (logoBase64) {
        const imageWidth = 10; // Set the small width for the logo
        const imageHeight = 10; // Set the small height for the logo
        const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
        const yPosition = 10; // Top margin for the logo

        // Draw circular border around the logo
        const centerX = xPosition + imageWidth / 2;
        const centerY = yPosition + imageHeight / 2;
        const radius = imageWidth / 2;

        doc.setLineWidth(0.5);
        doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
    }

    // Center the school name and contact details
    const textX = pageWidth / 2;
    doc.text(schoolName, textX, 25, { align: 'center' });
    doc.setFontSize(fontSizeSmall);
    doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
    doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
    doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });

    const columns = ['Subject', 'Total Marks', 'Average Marks'];
    const rows = classPerformanceData.subjects.map((subject) => [
      subject.subject_name,
      subject.total_marks,
      subject.average_marks.toFixed(2),
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 47,
      styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
      headStyles: { halign: 'center' }, // Center align header cells
      bodyStyles: { halign: 'center' }, // Center align body cells
      columnStyles: { 0: { cellWidth: 70 }, 1: { cellWidth: 50 }, 2: { cellWidth: 50 } },
    });

    doc.save('Class_Performance_Formative_Report.pdf');
  };

  return (
    <div className="container">
      <h2 className="header">View Class Formative Performance</h2>

      <div>
        <label>
          Select Year:
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">--Select Year--</option>
            {years.map((year) => (
              <option key={year.id} value={year.id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div>
        <label>
          Select Grade:
          <select value={selectedGrade} onChange={handleGradeChange}>
            <option value="">--Select Grade--</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.grade}
              </option>
            ))}
          </select>
        </label>
      </div>

      {selectedGrade && streams.length > 0 && (
        <div>
          <label>
            Select Stream:
            <select value={selectedStream} onChange={handleStreamChange}>
              <option value="">--Select Stream--</option>
              {streams.map((stream) => (
                <option key={stream.id} value={stream.id}>
                  {stream.stream_name}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {selectedYear && selectedGrade && selectedStream && !isDataFetched && (
        <button style={{ marginTop: '40px' }} onClick={fetchClassPerformanceData}>
          Fetch Class Performance
        </button>
      )}

      {isDataFetched && (
        <button style={{ marginTop: '20px' }} onClick={handleDownloadPDF}>
          <FaDownload /> Download PDF
        </button>
      )}

      {classPerformanceData && (
        <div style={{ marginTop: '40px' }}>
          <h3>
            Class Performance for {classPerformanceData.grade_name} - {classPerformanceData.stream_name} (
            {classPerformanceData.year})
          </h3>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Subject</th>
                <th>Total Marks</th>
                <th>Average Marks</th>
              </tr>
            </thead>
            <tbody>
              {classPerformanceData.subjects.map((subject, index) => (
                <tr key={index}>
                  <td>{subject.subject_name}</td>
                  <td>{subject.total_marks}</td>
                  <td>{subject.average_marks.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClassPerformanceFormative;
