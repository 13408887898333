import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';
import "./form.css";

const UploadSchoolLogo = () => {
    const [logo, setLogo] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const MAX_FILE_SIZE = 10000000; // 10MB
    const validFileExtensions = ["jpg", "png", "jpeg", "webp"];

    const getExtension = (fileName) => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLowerCase();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const size = file.size;
            const extension = getExtension(file.name);
            const isValid = validFileExtensions.includes(extension);
            if (size > MAX_FILE_SIZE) {
                setError("The file is too large. Maximum size is 10MB.");
                setLogo(null);
            } else if (!isValid) {
                setError("The file type is not supported. Allowed types: jpg, jpeg, png, webp.");
                setLogo(null);
            } else {
                setError('');
                setLogo(file);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!logo) {
            setError("Please select a logo to upload.");
            return;
        }

        const form = new FormData();
        form.append("logo", logo);

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/logo_upload', {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
                body: form,
            });

            if (response.ok) {
                const data = await response.json();
                setSuccess("Logo uploaded successfully!");
                setError('');
                console.log("Uploaded Logo Details:", data);
                navigate(-1)
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to upload logo.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error uploading logo:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>

            <h2 className='header'>Upload School Logo</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}

                    <div className="field">
                        <label>School Logo:</label>
                        <input
                            type="file"
                            name="logo"
                            accept=".jpg,.jpeg,.png,.webp"
                            onChange={handleFileChange}
                        />
                        {error && error.toLowerCase().includes("file") && (
                            <div className="error-message">{error}</div>
                        )}
                    </div>

                    <div>
                        <button type="submit" className='submit'>
                            Upload Logo
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadSchoolLogo;
