import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import './EditBehaviourComments.css';
import { FaArrowLeft } from 'react-icons/fa';

const EditTermClassTeachersComments = () => {
    const location = useLocation();
    const { studentId } = useParams();
    const { gradeId, streamName, student, selectedTermId, classTeachersComments, classTeachersRemarks, grade, studentAttendanceDays } = location.state;
    const [comments, setComments] = useState(classTeachersComments || '');
    const [remarks, setRemarks] = useState(classTeachersRemarks || '');
    const [attendance, setAttendance] = useState(studentAttendanceDays || '');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Handle input changes
    const handleCommentsChange = (e) => setComments(e.target.value);
    const handleRemarksChange = (e) => setRemarks(e.target.value);
    const handleAttendanceChange = (e) => setAttendance(e.target.value);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/term_comments_remarks/${gradeId}/${studentId}/${selectedTermId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({ class_teachers_comments: comments, general_remarks: remarks, attendance_days: attendance }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage('Details have been submitted successfully.');

                // Navigate back after 10 seconds
                setTimeout(() => {
                    setSuccessMessage('');
                    navigate(-1);
                }, 10000);
            } else {
                console.error('Failed to update comments');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="edit-comments-container">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>
            <h2>Edit Class Teacher's Term Comments</h2>
            <div className="personal-details">
                <p><strong>Name:</strong> {student.first_name} {student.last_name}</p>
                <p><strong>Grade:</strong> {grade}</p>
                <p><strong>Stream:</strong> {streamName}</p>
            </div>

            {/* Display success message */}
            {successMessage && <div className="success-message5">{successMessage}</div>}

            <form onSubmit={handleSubmit} className="comments-form">
                <label htmlFor="attendance">Attendance:</label>
                <input
                    type="number"
                    id="attendance"
                    value={attendance}
                    onChange={handleAttendanceChange}
                    placeholder="Enter attendance days"
                />
                <label htmlFor="remarks">General Remarks:</label>
                <textarea
                    id="remarks"
                    value={remarks}
                    onChange={handleRemarksChange}
                    rows="5"
                    placeholder="Enter your general remarks here..."
                />
                <label htmlFor="comments">Class Teacher's Comments:</label>
                <textarea
                    id="comments"
                    value={comments}
                    onChange={handleCommentsChange}
                    rows="5"
                    placeholder="Enter your comments here..."
                />
                <div className="button-container">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="cancel-button2"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="submit" style={{ color: "black" }}>
                        Save Comments
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditTermClassTeachersComments;
