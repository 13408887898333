import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Header, Segment, Message, Image } from 'semantic-ui-react';
import { retrieve } from './Encryption'; 
import { gradeImages, placeholderImage } from './GradeImages'; 
import './ViewGrades.css'; 

const AdminAssessmentGrades = () => {
    const [categories, setCategories] = useState([]);
    const [grades, setGrades] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [errorCategories, setErrorCategories] = useState('');
    const [errorGrades, setErrorGrades] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/categories', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCategories(data);
                } else {
                    const errorData = await response.json();
                    setErrorCategories(errorData.error || 'Failed to fetch categories');
                }
            } catch (error) {
                setErrorCategories('An error occurred while fetching categories.');
                console.error('Fetch Categories Error:', error);
            } finally {
                setLoadingCategories(false);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    const getGradesByCategory = () => {
        const grouped = {};

        categories.forEach(category => {
            grouped[category.category_name] = [];
        });

        grades.forEach(grade => {
            const category = categories.find(cat => cat.id === grade.category_id);
            if (category) {
                grouped[category.category_name].push(grade);
            }
        });

        return grouped;
    };

    const groupedGrades = getGradesByCategory();

    const handleImageClick = (gradeId) => {
        navigate(`/view-admin-dashboard/admin-view-assessments/${gradeId}`); // Pass grade.id in the URL
    };
    
    return (
        <div className="grid-container">
            <Segment className="view-grades-container">
                <Header as="h2" textAlign="center" className='header'>
                    View Grades
                </Header>

                {(loadingCategories || loadingGrades) ? (
                    <Message info>
                        <Message.Header>Loading data...</Message.Header>
                    </Message>
                ) : (errorCategories || errorGrades) ? (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{errorCategories || errorGrades}</p>
                    </Message>
                ) : (categories.length === 0) ? (
                    <Message warning>
                        <Message.Header>No Categories Found</Message.Header>
                        <p>No categories are available to display.</p>
                    </Message>
                ) : (grades.length === 0) ? (
                    <Message warning>
                        <Message.Header>No Grades Found</Message.Header>
                        <p>No grades are available to display.</p>
                    </Message>
                ) : (
                    <div className="categories-container">
                        {categories.map(category => (
                            <div key={category.id} className="category-section">
                                <Header as="h3">{category.category_name}</Header>
                                {groupedGrades[category.category_name].length > 0 ? (
                                    <Card.Group>
                                        {groupedGrades[category.category_name].map(grade => (
                                            <Card key={grade.id} className="grade-card">
                                                <div className="card-content-wrapper">
                                                    <Image
                                                        src={ placeholderImage}
                                                        wrapped
                                                        ui={false}
                                                        className="grade-image"
                                                        onClick={() => handleImageClick(grade.id)}
                                                        style={{ cursor: 'pointer' }}
                                                        alt={`Grade ${grade.grade}`}
                                                    />
                                                    <Header as='h3' className="grade-header">
                                                        {grade.grade}
                                                    </Header>
                                                </div>
                                            </Card>
                                        ))}
                                    </Card.Group>
                                ) : (
                                    <Message info>
                                        <p>No grades in this category.</p>
                                    </Message>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </Segment>
        </div>
    );
};

export default AdminAssessmentGrades;
