import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaDownload } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './StudentFormativePerformanceDetails.css'; // Import the CSS file
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import { Box, CssBaseline,Typography } from '@mui/material';



const StudentFormativePerformanceDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { performanceData, selectedStudent } = location.state || {};
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();


  if (!selectedStudent) {
    return <div className="no-student">No student selected.</div>;
  }

  // Function to download the report as a PDF
  const handleDownloadReport = async () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
  

    const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
        const fontSizeHeader = 10; // Font size for headers
        const fontSizeBody = 8; // Font size for body text
        const fontSizeSmall = 6; // Font size for smaller text like address and contact
    
        const fetchLogo = async () => {
            try {
                let logoUrl = schoolLogo;
    
                // Replace 'http' with 'https' if necessary
                if (logoUrl.startsWith('http://')) {
                    logoUrl = logoUrl.replace('http://', 'https://');
                }
    
                const response = await fetch(logoUrl);
                if (!response.ok) throw new Error("Failed to fetch logo");
    
                const blob = await response.blob();
                const reader = new FileReader();
    
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error("Failed to load logo:", error);
                return null; // Provide a fallback or skip adding the logo
            }
        };
    
        const logoBase64 = await fetchLogo();
    
        // Add the logo if successfully fetched
        if (logoBase64) {
            const imageWidth = 10; // Set the small width for the logo
            const imageHeight = 10; // Set the small height for the logo
            const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
            const yPosition = 10; // Top margin for the logo
    
            // Draw circular border around the logo
            const centerX = xPosition + imageWidth / 2;
            const centerY = yPosition + imageHeight / 2;
            const radius = imageWidth / 2;
    
            doc.setLineWidth(0.5);
    
            doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
        }
    
        // // Find the selected subject's name from the subjects array
        // const subject = subjects.find((subject) => subject.id === selectedSubject);
        // const selectedSubjectName = subject ? subject.subject_name : "Unknown Subject";
        
        // Add school name below the logo
        doc.setFontSize(fontSizeHeader);
        doc.text(`${schoolName}`, pageWidth / 2, 25, { align: 'center' });
    
        // Add school contact information
        doc.setFontSize(fontSizeSmall);
        doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 30, { align: 'center' });
        doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 33, { align: 'center' });
        doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 36, { align: 'center' });

        doc.setFontSize(fontSizeHeader);
      
    
        doc.text(
          `Formative Performance Report for ${selectedStudent.first_name} ${selectedStudent.last_name}`,
          pageWidth / 2,
          43,
          { align: 'center' }
        );
        
        // Draw a bordered and shaded box for student information
        const studentInfoX = 34; // X position for the box
        const studentInfoY = 47; // Y position for the box
        const studentInfoWidth = pageWidth - 65; // Box width (centered with 20px padding on each side)
        const studentInfoHeight = 23; // Box height
    
        // Set border and fill colors
        doc.setLineWidth(0.1); // Reduced border weight for a thinner line      
        doc.setFillColor(243, 243, 244); // Light gray fill (RGB)
    
        // Draw the rectangle
        doc.rect(studentInfoX, studentInfoY, studentInfoWidth, studentInfoHeight, 'FD'); // 'FD' = Fill and Draw
    
        // Add the report title and student details inside the box
      
        
        doc.setFontSize(fontSizeBody);
        doc.text(`Name: ${selectedStudent.first_name} ${selectedStudent.last_name}`, pageWidth / 2, studentInfoY + 5, { align: 'center' });
        doc.text(`Admission Number: ${selectedStudent.admission_number}`, pageWidth / 2, studentInfoY + 10, { align: 'center' });
        doc.text(`Grade: ${performanceData.grade_name} | Stream: ${performanceData.stream_name}`, pageWidth / 2, studentInfoY + 15, { align: 'center' });
        doc.text(
          `Year: ${performanceData.year}`,
          pageWidth / 2,
          studentInfoY + 20,
          { align: 'center' }   );    
    









    const columns = ['Subject', 'Performance (%)'];
    const rows = performanceData.subjects.map((subject) => [
      subject.subject_name,
      subject.average_marks.toFixed(2),
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 73,
      styles: { fontSize: 10, cellPadding: 2 },
      headStyles: { halign: 'center' },
    });

    doc.save(`${selectedStudent.first_name}_${selectedStudent.last_name}_Formative_Performance.pdf`);
  };

  return (
    <div className="performance-container">
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          <FaArrowLeft /> Back
        </button>
        <button
         
          onClick={handleDownloadReport}
          style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginTop:"50px" }}
        >
          <FaDownload style={{ marginRight: '10px' }} /> Download Report
        </button>
      </div>
      <h3 className="header">
        Formative Performance Report for {selectedStudent.first_name} {selectedStudent.last_name}
      </h3>
      <div className="student-details">
        <p>
          <strong>Grade:</strong> {performanceData.grade_name}
        </p>
        <p>
          <strong>Stream:</strong> {performanceData.stream_name}
        </p>
        <p>
          <strong>Year:</strong> {performanceData.year}
        </p>
      </div>
      <table className="performance-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Performance (%)</th>
          </tr>
        </thead>
        <tbody>
          {performanceData.subjects.map((subject, index) => (
            <tr key={index}>
              <td>{subject.subject_name}</td>
              <td>{subject.average_marks.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentFormativePerformanceDetails;
