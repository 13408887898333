import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import "./AddFormativeReportDetails.css";


const AddFormativeReportDetails = () => {
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [strandName, setStrandName] = useState('');
  const [subStrands, setSubStrands] = useState([
    { 
      substrand_name: '', 
      learning_outcomes: [ 
        { 
          learning_outcome: '', 
          assessment_rubrics: [ 
            { 
              assessment_rubrics: '', 
              assessment_rubic_mark: '' 
            } 
          ] 
        }
      ]
    }
  ]);

  const [loadingGrades, setLoadingGrades] = useState(true);
  const [errorGrades, setErrorGrades] = useState('');
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [errorSubjects, setErrorSubjects] = useState('');

  const navigate = useNavigate();

  // Fetch grades from backend when component mounts
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await fetch('https://cbc-orfl.onrender.com/grades', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${retrieve().access_token}`,
            'Content-Type': 'application/json',
          }
        });
        if (response.ok) {
          const data = await response.json();
          setGrades(data);
        } else {
          const errorData = await response.json();
          setErrorGrades(errorData.error || 'Failed to fetch grades');
        }
      } catch (error) {
        console.error('Error fetching grades:', error);
        setErrorGrades('An error occurred while fetching grades.');
      } finally {
        setLoadingGrades(false);
      }
    };

    fetchGrades();
  }, []);

  // Fetch subjects when a grade is selected
  useEffect(() => {
    if (selectedGrade) {
      const fetchSubjects = async () => {
        setLoadingSubjects(true);
        setErrorSubjects('');
        try {
          const response = await fetch(`https://cbc-orfl.onrender.com/subjects/grade/${selectedGrade}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${retrieve().access_token}`,
              'Content-Type': 'application/json',
            }
          });
          if (response.ok) {
            const data = await response.json();
            setSubjects(data);
          } else {
            const errorData = await response.json();
            setErrorSubjects(errorData.error || 'Failed to fetch subjects for the selected grade.');
          }
        } catch (error) {
          console.error('Error fetching subjects:', error);
          setErrorSubjects('An error occurred while fetching subjects.');
        } finally {
          setLoadingSubjects(false);
        }
      };

      fetchSubjects();
    } else {
      setSubjects([]);
      setSelectedSubject('');
    }
  }, [selectedGrade]);

  // Handle change for strand name
  const handleStrandNameChange = (e) => {
    setStrandName(e.target.value);
  };

  // Handle SubStrand input changes
  const handleSubStrandChange = (index, event) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[index].substrand_name = event.target.value;
    setSubStrands(updatedSubStrands);
  };

  // Handle LearningOutcome input changes
  const handleLearningOutcomeChange = (subIndex, loIndex, event) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[subIndex].learning_outcomes[loIndex].learning_outcome = event.target.value;
    setSubStrands(updatedSubStrands);
  };

  // Handle AssessmentRubric input changes
  const handleRubricChange = (subIndex, loIndex, rubricIndex, event) => {
    const updatedSubStrands = [...subStrands];
    const { name, value } = event.target;
    updatedSubStrands[subIndex].learning_outcomes[loIndex].assessment_rubrics[rubricIndex][name] = value;
    setSubStrands(updatedSubStrands);
  };

  // Add a new SubStrand
  const addSubStrand = () => {
    setSubStrands([...subStrands, { 
      substrand_name: '', 
      learning_outcomes: [{ 
        learning_outcome: '', 
        assessment_rubrics: [{ assessment_rubrics: '', assessment_rubic_mark: '' }] 
      }] 
    }]);
  };

  // Add a new Learning Outcome
  const addLearningOutcome = (subIndex) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[subIndex].learning_outcomes.push({ 
      learning_outcome: '', 
      assessment_rubrics: [{ assessment_rubrics: '', assessment_rubic_mark: '' }] 
    });
    setSubStrands(updatedSubStrands);
  };

  // Add a new Assessment Rubric
  const addAssessmentRubric = (subIndex, loIndex) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[subIndex].learning_outcomes[loIndex].assessment_rubrics.push({ 
      assessment_rubrics: '', 
      assessment_rubic_mark: '' 
    });
    setSubStrands(updatedSubStrands);
  };

  // Remove SubStrand, Learning Outcome, or Rubric
  const removeSubStrand = (index) => setSubStrands(subStrands.filter((_, i) => i !== index));

  const removeLearningOutcome = (subIndex, loIndex) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[subIndex].learning_outcomes = updatedSubStrands[subIndex].learning_outcomes.filter((_, i) => i !== loIndex);
    setSubStrands(updatedSubStrands);
  };

  const removeRubric = (subIndex, loIndex, rubricIndex) => {
    const updatedSubStrands = [...subStrands];
    updatedSubStrands[subIndex].learning_outcomes[loIndex].assessment_rubrics = updatedSubStrands[subIndex].learning_outcomes[loIndex].assessment_rubrics.filter((_, i) => i !== rubricIndex);
    setSubStrands(updatedSubStrands);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedGrade || !selectedSubject) {
      alert('Please select both a grade and a subject.');
      return;
    }

    const payload = {
      strand_name: strandName,
      substrands: subStrands
    };

    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/grades/${selectedGrade}/subjects/${selectedSubject}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Strand with SubStrands, Learning Outcomes, and Rubrics added successfully!');
        resetForm();
       
      } else {
        const errorData = await response.json();
        alert(errorData.error || 'Failed to add data');
      }
    } catch (error) {
      console.error('Error adding strand:', error);
      alert('An error occurred. Please try again.');
    }
  };
  // Function to reset the form fields
const resetForm = () => {
  setSelectedGrade('');
  setSelectedSubject('');
  setStrandName('');
  setSubStrands([
    { 
      substrand_name: '', 
      learning_outcomes: [ 
        { 
          learning_outcome: '', 
          assessment_rubrics: [ 
            { 
              assessment_rubrics: '', 
              assessment_rubic_mark: '' 
            } 
          ] 
        } 
      ]
    }
  ]);
};

  return (
   
    <div className='content-wrapper6'>
      <h2 className="header"> Add New Strand</h2>
      <div className="form-container6" >

        {/* Grade Dropdown */}
       
          <label htmlFor="grade">Grade</label>
          {loadingGrades ? (
            <p>Loading grades...</p>
          ) : errorGrades ? (
            <p className="error">{errorGrades}</p>
          ) : (
            <select
              id="grade"
              value={selectedGrade}
              onChange={(e) => setSelectedGrade(e.target.value)}
              required
            >
              <option value="">Select Grade</option>
              {grades.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.grade}
                </option>
              ))}
            </select>
            
          )}
   

        {/* Subject Dropdown */}
        {selectedGrade && (
          <div >
            <label htmlFor="subject">Subject</label>
            {loadingSubjects ? (
              <p>Loading subjects...</p>
            ) : errorSubjects ? (
              <p className="error">{errorSubjects}</p>
            ) : (
              <select
                id="subject"
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                required
              >
                <option value="">Select Subject</option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subject_name}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}

        {/* Display selected Grade and Subject */}
        {selectedGrade && selectedSubject && (
          <div className="selected-info">
            <p>
              <h2>Selected Grade:{grades.find(g => g.id === selectedGrade)?.grade}</h2> 
            </p>
            <p>
              <h2>Selected Subject: {subjects.find(s => s.id === selectedSubject)?.subject_name}</h2>
            </p>
          </div>
        )}

        {/* Strand input */}
        {selectedGrade && selectedSubject && (
          <>
            <div >
              <label htmlFor="strandName">Strand Name</label>
              <input
                type="text"
                id="strandName"
                value={strandName}
                onChange={handleStrandNameChange}
                placeholder="Enter Strand Name"
                required
              />
            </div>

            {/* SubStrands, Learning Outcomes, and Rubrics inputs */}
            {subStrands.map((substrand, subIndex) => (
              <div key={subIndex} className="substrand-section">
                <h3>SubStrand {subIndex + 1}</h3>
                <div >
                  <label htmlFor={`substrand_name_${subIndex}`}>SubStrand Name</label>
                  <input
                    type="text"
                    id={`substrand_name_${subIndex}`}
                    placeholder="Enter SubStrand Name"
                    value={substrand.substrand_name}
                    onChange={(e) => handleSubStrandChange(subIndex, e)}
                    required
                  />
                  
                  <button
                    type="button"
                    onClick={() => removeSubStrand(subIndex)}
                    style={{backgroundColor:"red", marginLeft:"500px", marginBottom:"20px"}}
                
                    title="Remove SubStrand"
                  >
                    <FaTrash /> Delete Substrand
                  </button>
                </div>

                {/* Learning Outcomes */}
                {substrand.learning_outcomes.map((lo, loIndex) => (
                  <div key={loIndex} className="learning-outcome-section">
                    <h4>Learning Outcome {loIndex + 1}</h4>
                    <div >
                      <label htmlFor={`learning_outcome_${subIndex}_${loIndex}`}>Learning Outcome</label>
                      <input
                        type="text"
                        id={`learning_outcome_${subIndex}_${loIndex}`}
                        placeholder="Enter Learning Outcome"
                        value={lo.learning_outcome}
                        onChange={(e) => handleLearningOutcomeChange(subIndex, loIndex, e)}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => removeLearningOutcome(subIndex, loIndex)}
                        style={{backgroundColor:"red", marginLeft:"450px", marginBottom:"20px"}}
                        title="Remove Learning Outcome"
                      >
                        <FaTrash /> Delete Learning Outcome
                      </button>
                    </div>

                    {/* Assessment Rubrics */}
                    {lo.assessment_rubrics.map((rubric, rubricIndex) => (
                      <div key={rubricIndex} className="assessment-rubric-section">
                        <div >
                          <label htmlFor={`assessment_rubric_${subIndex}_${loIndex}_${rubricIndex}`}>Assessment Rubric</label>
                          <input
                            type="text"
                            id={`assessment_rubric_${subIndex}_${loIndex}_${rubricIndex}`}
                            name="assessment_rubrics"
                            placeholder="Enter Assessment Rubric"
                            value={rubric.assessment_rubrics}
                            onChange={(e) => handleRubricChange(subIndex, loIndex, rubricIndex, e)}
                            required
                          />
                        </div>
                        <div >
                          <label htmlFor={`assessment_rubic_mark_${subIndex}_${loIndex}_${rubricIndex}`}>Rubric Mark</label>
                          <input
                            type="number"
                            id={`assessment_rubic_mark_${subIndex}_${loIndex}_${rubricIndex}`}
                            name="assessment_rubic_mark"
                            placeholder="Enter Rubric Mark"
                            value={rubric.assessment_rubic_mark}
                            onChange={(e) => handleRubricChange(subIndex, loIndex, rubricIndex, e)}
                            required
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => removeRubric(subIndex, loIndex, rubricIndex)}
                          style={{backgroundColor:"red", marginLeft:"450px", marginBottom:"20px"}}
                          title="Remove Assessment Rubric"
                        >
                          <FaTrash /> Delete Assessment Rubric
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addAssessmentRubric(subIndex, loIndex)}
                      style={{ marginLeft:"500px", marginTop: "60px", marginBottom:"20px"}}
                    >
                      <FaPlus /> Add Rubric
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addLearningOutcome(subIndex)}
                  style={{ marginLeft:"480px", marginTop: "40px", marginBottom:"20px"}}
                >
                  <FaPlus /> Add Learning Outcome
                </button>
              </div>
            ))}

            <button
              type="button"
              onClick={addSubStrand}
           
            >
              <FaPlus /> Add SubStrand
            </button>
          </>
        )}

        {/* Submit Button */}
        {selectedGrade && selectedSubject && (
  <button onClick={handleSubmit} type="submit" style={{marginLeft:"450px",marginTop: "40px", maxWidth:"300px", fontFamily:"cursive", backgroundColor:"#8ac429", fontSize:"2em", color:"black"}}>
    Submit Strand
  </button>
)}

      </div>
    </div>
  );
};

export default AddFormativeReportDetails;
