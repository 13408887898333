import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { retrieve } from "./Encryption";

const COLORS = [
  "#8ac429",
  "#a4d645",
  "#6ca51e",
  "#ffb134",
  "#ffc965",
  "#e6952c",
  "#ff615d",
  "#ff7d79",
  "#e54844",
  "#79b820",
  "#ff9f1c",
  "#e63946",
];

const ExamPerformance = ({ studentId }) => {
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [examPerformance, setExamPerformance] = useState(null);
  const [termPerformance, setTermPerformance] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const termResponse = await fetch(`https://cbc-orfl.onrender.com/terms`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${retrieve().access_token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await termResponse.json();
        setTerms(data || []);

        if (data.length > 0) {
          setSelectedTerm(data[1]?.id || data[0].id); // Default to the first term
        }
      } catch (error) {
        console.error("Error fetching terms:", error);
      }
    };

    fetchTerms();
  }, []);

  useEffect(() => {
    if (!selectedTerm) return;

    const fetchExamPerformance = async () => {
      try {
        const performanceResponse = await fetch(
          `https://cbc-orfl.onrender.com/calculate_exam_performance/${studentId}/${selectedTerm}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${retrieve().access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await performanceResponse.json();
        setExamPerformance(data.exam_performance || null);
      } catch (error) {
        console.error("Error fetching exam performance:", error);
      }
    };

    const fetchTermPerformance = async () => {
      try {
        const termResponse = await fetch(
          `https://cbc-orfl.onrender.com/calculate_term_performance/${studentId}/${selectedTerm}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${retrieve().access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await termResponse.json();
        setTermPerformance(data.subject_performance || null);
      } catch (error) {
        console.error("Error fetching term performance:", error);
      }
    };

    fetchExamPerformance();
    fetchTermPerformance();
  }, [selectedTerm, studentId]);

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
  };

  const renderBarChart = (examData, examName) => {
    const chartData = Object.entries(examData).map(([subject, details], index) => ({
      subject,
      marks: details.marks,
      performance: details.performance_percentage,
      color: COLORS[index % COLORS.length],
    }));

    const formattedExamName = examName.replace(/exam(\d+)/, "Exam $1 Performance");

    return (
      <div style={{ width: "30%", margin: "0 10px" }}>
        <h3 style={{ marginTop: "20px" }}>{formattedExamName}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 20, left: 20, bottom: 50 }}
          >
            <XAxis
              dataKey="subject"
              angle={-45}
              textAnchor="end"
              interval={0}
              tick={{ fontSize: 10 }}
              height={60}
            />
            <YAxis />
            <Tooltip />
          
            <Bar dataKey="marks" label={{ position: "top" }}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderTermPerformance = () => {
    if (!termPerformance) return null;

    const chartData = Object.entries(termPerformance).map(([subject, details], index) => ({
      subject,
      performance: details.performance_percentage,
      color: COLORS[index % COLORS.length],
    }));

    return (
      <div style={{ marginTop: "70px" }}>
        <h3>Average Term Performance</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 20, left: 20, bottom: 50 }}
          >
            <XAxis
              dataKey="subject"
              angle={-45}
              textAnchor="end"
              interval={0}
              tick={{ fontSize: 10 }}
              height={70}
            />
            <YAxis />
            <Tooltip />
           
            <Bar dataKey="performance" label={{ position: "top" }}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div>
      <h2>Term Performance</h2>
      <div>
        <label htmlFor="term-select">Select Term:</label>
        <select id="term-select" value={selectedTerm} onChange={handleTermChange}>
          {terms.map((term) => (
            <option key={term.id} value={term.id}>
              {term.term_name}
            </option>
          ))}
        </select>
      </div>

      {examPerformance ? (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {Object.entries(examPerformance).map(([examName, examData]) =>
              renderBarChart(examData, examName)
            )}
          </div>
          {renderTermPerformance()}
        </div>
      ) : (
        <p>Loading exam performance...</p>
      )}
    </div>
  );
};

export default ExamPerformance;
