import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";
import "./DashboardLayout.css"; // Import the CSS file
import { retrieve } from "./Encryption";

const SidebarSuperAdmin = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("Unknown Role");
  const [designations, setDesignations] = useState([]);

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  // Fetch designations from the server
  const fetchDesignations = async () => {
    try {
      const response = await fetch("https://cbc-orfl.onrender.com/designations", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDesignations(data); // Set designations in state
      } else {
        console.error("Failed to fetch designations");
      }
    } catch (error) {
      console.error("An error occurred while fetching designations:", error);
    }
  };

  // Fetch user data and role based on userId
const fetchUserData = async (id) => {
  try {
    const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${retrieve().access_token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setUserName(`${data.first_name} ${data.last_name || "Unknown User"}`);

      // Find and set the user's role/designation based on matching the designation_id from staff data
      const userDesignation = designations.find(
        (designation) => designation.id === data.designation_id
      );
      setUserRole(userDesignation ? userDesignation.designation_name : "Unknown Role");
    } else {
      console.error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("An error occurred while fetching user data:", error);
  }
};


  useEffect(() => {
    // Fetch designations on component mount
    fetchDesignations();

    // Retrieve the decrypted object from localStorage
    const retrievedData = retrieve();
    if (retrievedData && retrievedData.access_token) {
      // Decode the access token to get the payload
      const decodedToken = parseJwt(retrievedData.access_token);
      setUserId(decodedToken.staff_id);
    } else {
      console.log("No access token found");
    }
  }, []); // Only run on mount

  useEffect(() => {
    // Fetch user data only if designations are available and userId is set
    if (userId && designations.length > 0) {
      fetchUserData(userId);
    }
  }, [userId, designations]); // Run this effect when userId or designations change

  return (
    <div className="sidebar">
      {/* Profile Section */}
      <Card className="profile-card">
        <Card.Content>
          <div className="profile-picture">
            <img src="/profile_photo.png" alt="Profile" />
          </div>
          <Card.Header>{userName}</Card.Header>
          <Card.Meta>{userRole}</Card.Meta>
        </Card.Content>
      </Card>

      {/* Menu Items */}
      <ul className="sidebar-menu">
        <li>
          <button onClick={() => navigate("/view-superadmin-dashboard")}>
            <Icon name="dashboard" className="menu-icon" style={{ marginRight: '10px' }} /> 
            Dashboard
          </button>
        </li>
        
        <li>
          <button onClick={() => navigate("/view-superadmin-dashboard/view-staff")}>
            <Icon name="users" className="menu-icon" style={{ marginRight: '10px' }} /> 
            Staff
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-superadmin-dashboard/upload-logo")}>
            <Icon name="users" className="menu-icon" style={{ marginRight: '10px' }} /> 
          Upload School Logo
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-superadmin-dashboard/add-formative-report-details")}>
            <Icon name="users" className="menu-icon" style={{ marginRight: '10px' }} /> 
          Add Formative Details
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-superadmin-dashboard/view-strands")}>
            <Icon name="users" className="menu-icon" style={{ marginRight: '10px' }} /> 
          View Strands
          </button>
        </li>
        
      </ul>
    </div>
  );
};

export default SidebarSuperAdmin;
