import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import { useLocation } from 'react-router-dom';
import './StudentFormativePerformance.css'; // Import the CSS file

const StudentFormativePerformance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [years, setYears] = useState([]);
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStream, setSelectedStream] = useState('');
  const [performanceData, setPerformanceData] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/years', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then(response => response.json())
      .then(data => setYears(data))
      .catch(error => console.error('Error fetching years:', error));
  }, []);

  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/grades', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then(response => response.json())
      .then(data => setGrades(data))
      .catch(error => console.error('Error fetching grades:', error));
  }, []);

  const fetchStreams = async (gradeId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setStreams(data);
  };

  const fetchStudents = async (gradeId, streamId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/students/grades/${gradeId}/streams/${streamId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    setStudents(data);
    setFilteredStudents(data);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = students.filter((student) =>
      `${student.first_name} ${student.last_name}`.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredStudents(filtered);
  };

  const fetchStudentPerformance = async (studentId, gradeId, streamId, yearId) => {
    const response = await fetch(`https://cbc-orfl.onrender.com/students/${studentId}/performance/${gradeId}/${streamId}/${yearId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    });
    const data = await response.json();
    return data;
  };

  const handleYearChange = (e) => setSelectedYear(e.target.value);
  
  const handleGradeChange = (e) => {
    const gradeId = e.target.value;
    setSelectedGrade(gradeId);
    setStreams([]);
    setStudents([]);
    setFilteredStudents([]);
    setSelectedStream('');
    if (gradeId) fetchStreams(gradeId);
  };

  const handleStreamChange = (e) => {
    const streamId = e.target.value;
    setSelectedStream(streamId);
    setStudents([]);
    setFilteredStudents([]);
    if (streamId && selectedGrade) fetchStudents(selectedGrade, streamId);
  };

  const handleStudentClick = async (student) => {
    if (selectedYear && selectedGrade && selectedStream) {
      const performanceData = await fetchStudentPerformance(student.id, selectedGrade, selectedStream, selectedYear);
      setPerformanceData(performanceData);
      setSelectedStudent(student);
      navigate('/view-admin-dashboard/performance-details', { state: { performanceData, selectedStudent: student } });
    }
  };

  return (
    <div className="container">
      <h2 className='header'>View Student Formative Performance</h2>

      <div className="select-group" style={{ marginTop: "60px" }}>
        <label>Select Year:
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">--Select Year--</option>
            {years.map((year) => (
              <option key={year.id} value={year.id}>{year.year_name}</option>
            ))}
          </select>
        </label>
      </div>

      <div className="select-group">
        <label>Select Grade:
          <select value={selectedGrade} onChange={handleGradeChange}>
            <option value="">--Select Grade--</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>{grade.grade}</option>
            ))}
          </select>
        </label>
      </div>

      {streams.length > 0 && (
        <div className="select-group">
          <label>Select Stream:
            <select value={selectedStream} onChange={handleStreamChange}>
              <option value="">--Select Stream--</option>
              {streams.map((stream) => (
                <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
              ))}
            </select>
          </label>
        </div>
      )}

      {students.length > 0 && (
        <div className="student-search">
          <label>Search Students:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by name"
            />
          </label>

          <div className="student-table">
            <h3>Students in Selected Grade and Stream</h3>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => (
                  <tr key={student.id}>
                    <td>{`${student.first_name} ${student.last_name}`}</td>
                    <td>
                      <button  style={{ backgroundColor:"#8ac429", fontFamily:"cursive", fontSize:"1.2em" }}onClick={() => handleStudentClick(student)}>
                        View Performance
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentFormativePerformance;
