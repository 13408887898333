import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';

const AddParent = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        phone_number: '',
        alternative_contact: '',
        email_address: '',
        relationship: '',
        student_ids: [],
    });
    const location = useLocation();
    const { grades } = location.state;
    const [streams, setStreams] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [additionalSelections, setAdditionalSelections] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedGrade) {
            fetchStreams(selectedGrade);
        }
    }, [selectedGrade]);

    useEffect(() => {
        if (selectedStream) {
            fetchStudents(selectedGrade, selectedStream);
        }
    }, [selectedStream]);

    const fetchStreams = async (gradeId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setStreams(data);
            } else {
                throw new Error('Failed to fetch streams');
            }
        } catch (error) {
            console.error('Error fetching streams:', error);
            setError('Unable to fetch streams');
        }
    };

    const fetchStudents = async (gradeId, streamId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/students/grades/${gradeId}/streams/${streamId}`, {
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setStudents(data);
            } else {
                throw new Error('Failed to fetch students');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Unable to fetch students');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleStudentSelection = (studentId) => {
        setFormData(prevData => {
            const updatedStudentIds = prevData.student_ids.includes(studentId)
                ? prevData.student_ids.filter(id => id !== studentId)
                : [...prevData.student_ids, studentId];
            return { ...prevData, student_ids: updatedStudentIds };
        });
    };

    const handleAdditionalSelection = () => {
        setAdditionalSelections([...additionalSelections, { grade: selectedGrade, stream: selectedStream }]);
        setSelectedGrade('');
        setSelectedStream('');
        setStudents([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${retrieve().access_token}`,
            },
            body: JSON.stringify(formData),
        };

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/parents', options);
            if (response.ok) {
                setSuccess('Parent added successfully!');
                setError('');
                setTimeout(() => navigate('/view-admin-dashboard/admin-view-parents'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to add parent.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>

            <h2 className='header'>Add New Parent</h2>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {error && <div className="ui negative message">{error}</div>}
                {success && <div className="ui positive message">{success}</div>}

                <div style={{ flex: '1 1 45%' }}>
                    <label>First Name:</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div style={{ flex: '1 1 45%' }}>
                    <label>Last Name:</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="field">
                        <label>Gender:</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                        >
                            <option value="">-- Select Gender --</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>
                    </div>

                    <div className="field">
                        <label>Date of Birth:</label>
                        <input
                            type="date"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="field">
                        <label>Phone Number:</label>
                        <input
                            type="text"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="field">
                        <label>Alternative Contact:</label>
                        <input
                            type="text"
                            name="alternative_contact"
                            value={formData.alternative_contact}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field">
                        <label>Email Address:</label>
                        <input
                            type="email"
                            name="email_address"
                            value={formData.email_address}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="field">
                        <label>Relationship:</label>
                        <select
                            name="relationship"
                            value={formData.relationship}
                            onChange={handleChange}
                            required
                        >
                            <option value="">-- Select Relationship --</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Guardian">Guardian</option>
                        </select>
                    </div>

                <div style={{ flex: '1 1 45%' }}>
                    <label>Select Grade:</label>
                    <select
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(e.target.value)}
                    >
                        <option value="">-- Select Grade --</option>
                        {grades.map(grade => (
                            <option key={grade.id} value={grade.id}>{grade.grade}</option>
                        ))}
                    </select>
                </div>

                <div style={{ flex: '1 1 45%' }}>
                    <label>Select Stream:</label>
                    <select
                        value={selectedStream}
                        onChange={(e) => setSelectedStream(e.target.value)}
                        disabled={!selectedGrade}
                    >
                        <option value="">-- Select Stream --</option>
                        {streams.map(stream => (
                            <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
                        ))}
                    </select>
                </div>

                <div style={{ flex: '1 1 100%' }}>
                    <label>Associate Students:</label>
                    <div>
                        {students.map(student => (
                            <label key={student.id} style={{ marginRight: '10px' }}>
                                <input
                                    type="checkbox"
                                    value={student.id}
                                    checked={formData.student_ids.includes(student.id)}
                                    onChange={() => handleStudentSelection(student.id)}
                                />
                                {student.first_name} {student.last_name}
                            </label>
                        ))}
                    </div>
                </div>

                <button type="button" onClick={handleAdditionalSelection} style={{ marginTop: '10px' }}>
                    Add Another Grade and Stream
                </button>

                <button type="submit" className="submit-button" style={{ marginTop: '20px' }}>Submit</button>
            </form>
        </div>
    );
};

export default AddParent;
