// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import ViewStaff from './components/ViewStaff';
import AddStaff from './components/AddStaff';
import AdminDashboard from "./components/AdminDashboard";
import DashboardLayout from './components/DashboardLayout'; // Layout with Sidebar & Header

import SuperAdminDashboard from './components/SuperAdminDashboard';
import AddAdmin from './components/AddAdmin';
import AdminViewStaff from './components/AdminViewStaff';
import EditAdmin from './components/EditAdmin';
import AddFormativeReportDetails from './components/AddFormativeReportDetails';

import AdminEditStaff from './components/AdminEditStaff';
import ViewStudents from './components/ViewStudents';
import AddStudent from './components/AddStudent';
import ViewStreams from './components/ViewStreams';
import AddStream from './components/AddStreams';
import EditStudent from './components/EditStudent';
import EditStream from './components/EditStream';
import ViewBehaviourGoals from './components/ViewBehaviourGoals';
import AddBehaviourGoals from './components/AddBehaviourGoals';
import EditBehaviourGoal from './components/EditBehaviourGoal';
import AdminViewGrades from './components/AdminViewGrades';
import ViewTeachers from './components/ViewTeachers';
import AssignTeacherGrade from './components/AssignTeacherGrade';
import AssignTeacherSubject from './components/AssignTeacherSubject';
import AssignClassTeacher from './components/AssignClassTeacher';
import TeacherDetails from './components/TeacherDetails';
import GradeDetails from './components/GradeDetails';
import ViewStrands from './components/ViewStrands';
import EditSubstrand from './components/EditSubstrand';
import EditLearningOutcome from './components/EditLearningOutcome';
import EditAssessmentRubic from './components/EditAssessmentRubic';
import GenerateFormativeReport from './components/FormativeReportGenerator';
import AdminAssessmentGrades from './components/AdminAssessmentGrades';
import AdminViewAssessments from './components/AdminViewAssessments';
import FormativeAssessmentAdminView from './components/FormativeAssessmentAdminView';

import BehaviourGoalAssessmentAdminView from './components/BehaviourGoalAssessmentAdminView';
import AdminViewStudentFormativeReport from './components/AdminViewStudentFormativeReport';
import AssessmentGrades from './components/AssessmentGrades';
import ViewAssessments from './components/ViewAssessments';
import ViewFormativeAssessments from './components/ViewFormativeAssessments';
import EditStudentFormativeReport from './components/EditStudentFormativeReport';
import FormativeAssessmentView from './components/FormativeAssessmentView';
import AdminViewSummativeAssessments from './components/AdminViewSummativeAssessments';
import AdminViewStudentSummativeReport from './components/AdminViewStudentSummativeReport';
import EditSummativeAssessments from './components/EditSummativeAssessments';
import SummativeAssessmentView from './components/SummativeAssessmentView';
import BehaviourGoalsAssessmentsView from './components/BehaviourGoalsAssessmentsView';
import DashBoardTeacher from './components/DashBoardTeacher';
import SummativeReportGenerator from './components/SummativeReportGenerator';
import EditTermlyMarks from './components/EditTermlyMarks';
import AdminGenerateReports from './components/AdminGenerateReports';
import GenerateBehaviourGoalsReport from './components/GenerateBehaviourGoalsReports';
import AdminViewStudentBehaviourReport from './components/AdminViewStudentBehaviourReport';
import EditStudentBehaviourGoalsReport from './components/EditStudentBehaviourGoalsReport';
import EditBehaviourGoalsReport from './components/EditBehaviourGoalsReports';
import EditBehaviourComments from './components/EditBehaviourComments';
import EditTermClassTeachersComments from './components/EditTermClassTeachersComments';
import EditYearClassTeachersComments from './components/EditYearClassTeachersComments';
import DashboardAdmin from './components/DashBoardAdmin';
import SubjectFormativePerformance from './components/SubjectFormativePerformance';
import SubjectSummativePerformance from './components/SubjectSummativePerformance';
import StudentFormativePerformance from './components/StudentFormativePerformance';
import StudentFormativePerformanceDetails from './components/StudentFormativePerformanceDetails';
import StudentSummativePerformance from './components/StudentSummativePerformance';
import StudentSummativeTermPerformance from './components/StudentSummativeTermPerfomance';
import StudentSummativeYearPerformance from './components/StudentSummativeYearPerformance';
import ClassPerformanceFormative from './components/ClassPerformanceFormative';
import ClassPerformanceSummative from './components/ClassPerfomanceSummative';
import TeacherFormativePerformance from './components/TeacherFormativePerformance';
import TeacherSummativePerformance from './components/TeacherSummativePerformance';
import ResetStaffPassword from './components/ResetStaffPassword';
import LoginChangePassword from './components/LoginChangePassword';
import IdleLogout from './components/IdleLogout';
import AdminChangeOwnPassword from './components/AdminChangeOwnPassword';
import SuperAdminResetAdminPassword from './components/SuperAdminResetAdminPassword';

import TeacherViewStudents from './components/TeacherViewStudents';
import TeacherViewStaff from './components/TeacherViewStaff';
import TeachersViewTeachers from './components/TeachersViewTeachers';
import TeachersViewGrades from './components/TeachersViewGrades';
import TeacherViewStreams from './components/TeacherViewStreams';
import TeachersViewBehaviourGoals from './components/TeachersViewBehaviourGoals';
import TeacherChangeOwnPassword from './components/TeacherChangeOwnPassword';
import TeachersViewTeacherDetails from './components/TeachersViewTeacherDetails';
import TeacherViewGradeDetails from './components/TeacherViewGradeDetails';
import BulkAddStaff from './components/BulkAddStaff';
import BulkAddStudents from './components/BulkAddStudents';
import DashboardSuperAdmin from './components/DashboardSuperAdmin';
import UploadSchoolLogo from './components/UploadSchoolLogo';
import DashBoardParent from './components/DashBoardParent';
import AdminViewParents from './components/AdminViewParents';
import AddParent from './components/AddParent';
import EditParent from './components/EditParent';
import ParentDetails from './components/ParentDetails';
import LinkParentWithStudents from './components/LinkParentWithStudents';
import BulkAddParents from './components/BulkAddParents';
import ParentViewReports from './components/ParentViewReports';
import ParentViewStudentFormativeReport from './components/ParentViewStudentFormativeReport';



// Inside your routing setup



const App = () => {
  // Load environment variables from .env.local for local development

  return (
    <div>
        <IdleLogout />
      <Routes>
        <Route path="/" element={<Login />} />
      
        {/* Wrap the dashboard routes inside the DashboardLayout */}
        <Route path="login-change-password" element={<LoginChangePassword />} />
       
        
        <Route path="/view-admin-dashboard" element={<DashboardLayout />}>
          <Route path="dashboard-admin" element={<DashboardAdmin />} />
          <Route index element={<DashboardAdmin />} /> {/* Default dashboard home */}
          <Route path="subject-performance/formative" element={<SubjectFormativePerformance />} />
          <Route path="subject-performance/summative" element={<SubjectSummativePerformance/>} />
          <Route path="student-performance/formative" element={<StudentFormativePerformance/>} />
          <Route path="student-performance/summative" element={<StudentSummativePerformance/>} />
          <Route path="class-performance/formative" element={<ClassPerformanceFormative/>} />
          <Route path="class-performance/summative" element={<ClassPerformanceSummative/>} />
          <Route path="teacher-performance/formative" element={<TeacherFormativePerformance/>} />
          <Route path="teacher-performance/summative" element={<TeacherSummativePerformance/>} />
          <Route path="reset-password/:staffId" element={<ResetStaffPassword/>} />
          <Route path="change-own-password/:userId" element={<AdminChangeOwnPassword/>} />  
          <Route path="performance-details" element={<StudentFormativePerformanceDetails/>} />
          <Route path="student-term-performance-details" element={<StudentSummativeTermPerformance/>} />
          <Route path="student-year-performance-details" element={<StudentSummativeYearPerformance/>} />
          <Route path="admin-view-staff" element={<AdminViewStaff />} />
          <Route path="admin-add-staff" element={<AddStaff />} />
          <Route path="admin-add-staff-in-bulk" element={<BulkAddStaff />} />
          <Route path="admin-edit-staff/:staffId" element={<AdminEditStaff />} />
          <Route path="admin-view-students" element={<ViewStudents />} />
          <Route path="add-parents-in-bulk" element={<BulkAddParents/>} />         
          <Route path="add-student" element={<AddStudent />} />
          <Route path="add-parent" element={<AddParent />} />
          <Route path="edit-parent/:parentId" element={<EditParent />} />
          <Route path="parent-details/:parentId" element={<ParentDetails />} />
          <Route path="link-parents-with-students/:parentId" element={<LinkParentWithStudents />} />
          <Route path="add-students-in-bulk" element={<BulkAddStudents />} />
          <Route path="admin-view-parents" element={<AdminViewParents/>} />
          <Route path="admin-view-streams" element={<ViewStreams/>} />
          <Route path="add-stream" element={<AddStream/>} />
          <Route path="edit-student/:studentId" element={<EditStudent/>} />
          <Route path="edit-stream/:streamId" element={<EditStream/>} />
          <Route path="admin-view-behaviour-goals" element={<ViewBehaviourGoals/>} />
          <Route path="add-behaviour-goals" element={<AddBehaviourGoals/>} />
          <Route path="edit-behaviour-goal/:goalId" element={<EditBehaviourGoal/>} />
          <Route path="admin-view-grades" element={<AdminViewGrades/>} />
          <Route path="admin-view-teachers" element={<ViewTeachers/>} />
          <Route path="assign-teacher-grade" element={<AssignTeacherGrade/>} />
          <Route path="assign-teacher-subject" element={<AssignTeacherSubject/>} />
          <Route path="assign-class-teacher" element={<AssignClassTeacher/>} />
          <Route path="teacher-details/:teacherId" element={<TeacherDetails />} />
          <Route path="grade-details/:gradeId" element={<GradeDetails />} />
          <Route path="generate-reports" element={<AdminGenerateReports />} />
          <Route path="generate-formative-report" element={<GenerateFormativeReport />} />
          <Route path="generate-summative-report" element={<SummativeReportGenerator />} />
          <Route path="generate-behaviour-goals-report" element={<GenerateBehaviourGoalsReport/>} />
          <Route path="admin-assessment-grades" element={<AdminAssessmentGrades/>} />
          <Route path="admin-view-assessments/:gradeId" element={<AdminViewAssessments/>} />
          <Route path="admin-view-student-formative-report/:studentId" element={<AdminViewStudentFormativeReport/>} />
          <Route path="admin-view-summative-assessments/:gradeId" element={<AdminViewSummativeAssessments/>} />
          <Route path="admin-view-student-summative-report/:studentId" element={<AdminViewStudentSummativeReport/>} />
          <Route path="admin-view-behaviour-goals-report/:studentId" element={<AdminViewStudentBehaviourReport/>} />
          

       
          <Route path="admin-view-formative-assessments/:gradeId" element={<FormativeAssessmentAdminView/>} />
          {/* <Route path="admin-view-summative-reports/:gradeId" element={<SummativeAssessmentAdminView />} /> */}
          <Route path="admin-view-behaviour-goals-assessments/:gradeId" element={<BehaviourGoalAssessmentAdminView />} />

         
         
         

        </Route>

        <Route path="/view-teacher-dashboard" element={<DashboardLayout />}>
          <Route index element={<DashBoardTeacher />} />
          <Route path="dashboard-teacher" element={<DashBoardTeacher />} />
          <Route path="teacher-view-staff" element={<TeacherViewStaff />} />
          <Route path="teacher-view-students" element={<TeacherViewStudents/>} />
          <Route path="teachers-view-self" element={<TeachersViewTeachers/>} />
          <Route path="teachers-view-grades" element={<TeachersViewGrades/>} />
          <Route path="teachers-view-streams" element={<TeacherViewStreams/>} />
          <Route path="teachers-view-behaviour-goals" element={<TeachersViewBehaviourGoals/>} />
          <Route path="teacher-change-own-password/:userId" element={<TeacherChangeOwnPassword/>} />
          <Route path="view-teacher-details/:teacherId" element={<TeachersViewTeacherDetails/>} />
          <Route path="teacher-view-grade-details/:gradeId" element={<TeacherViewGradeDetails />} />

          <Route path="view-assessments-grades" element={<AssessmentGrades/>} />
          <Route path="view-formative-assessments" element={<ViewFormativeAssessments/>} />
          <Route path="view-assessments/:gradeId" element={<ViewAssessments/>} />
          <Route path="edit-formative-assessments/:studentId" element={<EditStudentFormativeReport/>} />
          <Route path="formative-assessments-view/:gradeId" element={<FormativeAssessmentView/>} />
          <Route path="edit-summative-assessments/:studentId" element={<EditSummativeAssessments/>} />
          <Route path="summative-assessments-view/:gradeId" element={<SummativeAssessmentView/>} />
          <Route path="behaviour-goals-assessments-view/:gradeId" element={<BehaviourGoalsAssessmentsView/>} />
          <Route path="edit-behaviour-goals-assessments/:studentId" element={<EditStudentBehaviourGoalsReport/>} />
          <Route path="edit-termly-marks/:studentId/:selectedTermId/:subjectId" element={<EditTermlyMarks/>} />
          <Route path="edit-behaviour-goals-report/:studentId" element={<EditBehaviourGoalsReport/>} />
          <Route path="edit-behaviour-comments-report/:studentId" element={<EditBehaviourComments/>} />
          <Route path="edit-term-comments-report/:studentId" element={<EditTermClassTeachersComments/>} />
          <Route path="edit-summative-year-comments-report/:studentId" element={<EditYearClassTeachersComments/>} />
          
         
      
       
      
          
         
        
        </Route>
        <Route path="/view-superadmin-dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardSuperAdmin />} /> 
          <Route path="dashboard-super-admin" element={<DashboardSuperAdmin />} />
          <Route path="view-staff" element={<ViewStaff />} />
          <Route path="add-admin" element={<AddAdmin />} />
          <Route path="edit-admin/:staffId" element={<EditAdmin />} />
          <Route path="add-formative-report-details" element={<AddFormativeReportDetails />} />
          <Route path="view-strands" element={<ViewStrands/>} />
          <Route path="edit-substrands/:substrandId" element={<EditSubstrand/>}/>
          <Route path="edit-learning-outcome/:learningOutcomeId" element={<EditLearningOutcome/>}/>
          <Route path="edit-assessment-rubic/:rubicId" element={<EditAssessmentRubic/>}/>
          <Route path="reset-admin-password/:staffId" element={<SuperAdminResetAdminPassword/>} />
          <Route path="upload-logo" element={<UploadSchoolLogo/>} />
   
        </Route>

        <Route path="/view-parent-dashboard" element={<DashboardLayout />}>
          <Route index element={<DashBoardParent />} />
          <Route path="dashboard-parent" element={<DashBoardParent/>} />
          <Route path="parent-view-reports" element={<ParentViewReports/>} />
          <Route path="parent-view-formative-assessments/:studentId/:gradeId/:streamId" element={<ParentViewStudentFormativeReport />} />
          
          
          </Route>

      </Routes>

    </div>
  );
};

export default App;
