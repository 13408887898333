import React, { useState, useEffect } from 'react';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { retrieve } from './Encryption';
import { Segment } from 'semantic-ui-react';
import { FaDownload } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
function TeacherSummativePerformance() {
    const [view, setView] = useState(null);
    const [overallPerformance, setOverallPerformance] = useState(null);
    const [termPerformance, setTermPerformance] = useState(null);
    const [comparisonPerformance, setComparisonPerformance] = useState(null);
    const [error, setError] = useState(null);
    const [years, setYears] = useState([]);
    const [grades, setGrades] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [terms, setTerms] = useState([]);
    const [streams, setStreams] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedTerm, setSelectedTerm] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState('');
     // New state variables for button visibility
     const [showFetchButton, setShowFetchButton] = useState(true);
     const [showDownloadButton, setShowDownloadButton] = useState(false);
     const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  
 

    const fetchData = async (url, setState) => {
        try {
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${retrieve().access_token}` }
            });
            const data = await response.json();
            setState(data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchData('https://cbc-orfl.onrender.com/years', setYears);
        fetchData('https://cbc-orfl.onrender.com/grades', setGrades);
    }, []);

    useEffect(() => {
        if (selectedGrade) {
            fetchData(`https://cbc-orfl.onrender.com/teachers/grades/${selectedGrade}`, (data) => {
                const teacherList = data.map(teacher => ({
                    teacher_id: teacher.id,
                    teacher_name: `${teacher.first_name} ${teacher.last_name}`
                }));
                setTeachers(teacherList);
            });
            fetchData(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, setStreams);
        }
    }, [selectedGrade]);

    useEffect(() => {
        if (selectedYear) {
            fetchData(`https://cbc-orfl.onrender.com/terms`, setTerms);
        }
    }, [selectedYear]);

    const fetchOverallPerformance = async () => {
        try {
            const response = await fetch(
                `https://cbc-orfl.onrender.com/teacher_performance/${selectedTeacher}/${selectedYear}/${selectedGrade}/${selectedStream}`,
                { headers: { Authorization: `Bearer ${retrieve().access_token}` } }
            );
            const data = await response.json();
            setOverallPerformance(data.performance_data);
        } catch (error) {
            setError("Error fetching overall performance data");
        }
    };

    const fetchTermPerformance = async () => {
        try {
            const response = await fetch(
                `https://cbc-orfl.onrender.com/teacher_performance/${selectedTeacher}/${selectedTerm}/${selectedYear}`,
                { headers: { Authorization: `Bearer ${retrieve().access_token}` } }
            );
            const data = await response.json();
            setTermPerformance(data.performance);
        } catch (error) {
            setError("Error fetching term performance data");
        }
    };

    const fetchComparisonPerformance = async () => {
        try {
            const response = await fetch(
                `https://cbc-orfl.onrender.com/teacher_performance_comparison/${selectedTeacher}/${selectedYear}`,
                { headers: { Authorization: `Bearer ${retrieve().access_token}` } }
            );
            const data = await response.json();
            setComparisonPerformance(data.performance_by_term);
        } catch (error) {
            setError("Error fetching comparison performance data");
        }
    };

    const handleViewChange = (newView) => {
        setView(newView);
        setOverallPerformance(null);
        setTermPerformance(null);
        setComparisonPerformance(null);
        setError(null);
    };
    const fetchPerformanceData = async (fetchFunction) => {
        await fetchFunction();
        setShowFetchButton(false);
        setShowDownloadButton(true);
    };

    const resetUI = () => {
        setOverallPerformance(null);
        setTermPerformance(null);
        setComparisonPerformance(null);
        setError(null);
        setShowFetchButton(true);
        setShowDownloadButton(false);
    };

    const handleDropdownChange = (setter) => (event) => {
        resetUI();
        setter(event.target.value);
    };

    const handleAssignButtonClick = (newView) => {
        resetUI();
        setView(newView);
    };

    const handleDownloadReport = async () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
      
      // Center the title by calculating the X position
      const title = 'Teacher Summative Performance Report';
      const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
      doc.text(title, titleX, 42);

      doc.setFontSize(10);
      const fontSizeSmall = 6; // Font size for smaller text like address and contact
      
      const fetchLogo = async () => {
        try {
            let logoUrl = schoolLogo;

            // Replace 'http' with 'https' if necessary
            if (logoUrl.startsWith('http://')) {
                logoUrl = logoUrl.replace('http://', 'https://');
            }

            const response = await fetch(logoUrl);
            if (!response.ok) throw new Error("Failed to fetch logo");

            const blob = await response.blob();
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Failed to load logo:", error);
            return null; // Provide a fallback or skip adding the logo
        }
    };

    const logoBase64 = await fetchLogo();

    // Add the logo if successfully fetched
    if (logoBase64) {
        const imageWidth = 10; // Set the small width for the logo
        const imageHeight = 10; // Set the small height for the logo
        const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
        const yPosition = 10; // Top margin for the logo

        // Draw circular border around the logo
        const centerX = xPosition + imageWidth / 2;
        const centerY = yPosition + imageHeight / 2;
        const radius = imageWidth / 2;

        doc.setLineWidth(0.5);
        doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
    }

    // Center the school name and contact details
    const textX = pageWidth / 2;
    doc.text(schoolName, textX, 25, { align: 'center' });
    doc.setFontSize(fontSizeSmall);
    doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
    doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
    doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });

        const columns = ["Grade", "Stream", "Subject", "Performance Percentage"];
        const rows = overallPerformance?.map((item) => [
            item.grade,
            item.stream,
            item.subject,
            `${item.performance_percentage.toFixed(2)}%`,
        ]) || [];

        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 47,
            styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
            headStyles: { halign: 'center' }, // Center align header cells
            bodyStyles: { halign: 'center' }, // Center align body cells
        });

        doc.save("Teacher_Sumative_Performance_Insights.pdf");
    }
    return (
        <div className='view-teachers-container'>
               <h2 className='header'>Teacher Performance Overview</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Segment className="button-container" style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: "40px" }}>
                <button
                    className="assign-button2"
                    style={{ fontSize: "17px", backgroundColor: "#8ac429", fontFamily: "cursive" }}
                    onClick={() => handleAssignButtonClick('term')}
                >
                    View Term Performance
                </button>

                <button
                    className="assign-button2"
                    style={{ fontSize: "17px", backgroundColor: "#ffb134", fontFamily: "cursive" }}
                    onClick={() => handleAssignButtonClick('overall')}
                >
                    View Overall Performance
                </button>

                <button
                    className="assign-button2"
                    style={{ fontSize: "17px", backgroundColor: "#ff615d", fontFamily: "cursive" }}
                    onClick={() => handleAssignButtonClick('comparison')}
                >
                    Compare Performance Across Terms
                </button>
            </Segment>
            {view && (
                <div>
                    <div>
                        <label>Year:</label>
                        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                            <option value="">Select Year</option>
                            {years.map((year) => (
                                <option key={year.id} value={year.id}>{year.year_name}</option>
                            ))}
                        </select>
                    </div>
                    {view === 'term' && (
                        <div>
                            <label>Term:</label>
                            <select value={selectedTerm} onChange={handleDropdownChange(setSelectedTerm)}>
                                <option value="">Select Term</option>
                                {terms.map((term) => (
                                    <option key={term.id} value={term.id}>{term.term_name}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div>
                        <label>Grade:</label>
                        <select value={selectedGrade} onChange={handleDropdownChange(setSelectedGrade)}>
                            <option value="">Select Grade</option>
                            {grades.map((grade) => (
                                <option key={grade.id} value={grade.id}>{grade.grade}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Stream:</label>
                        <select value={selectedStream} onChange={handleDropdownChange(setSelectedStream)}>
                            <option value="">Select Stream</option>
                            {streams.map((stream) => (
                                <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Teacher:</label>
                        <select value={selectedTeacher} onChange={handleDropdownChange(setSelectedTeacher)}>
                            <option value="">Select Teacher</option>
                            {teachers.map((teacher) => (
                                <option key={teacher.teacher_id} value={teacher.teacher_id}>{teacher.teacher_name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px"  }}>

                    {showFetchButton && (
                        <button
                            onClick={() => fetchPerformanceData(
                                view === 'overall' ? fetchOverallPerformance :
                                view === 'term' ? fetchTermPerformance :
                                fetchComparisonPerformance
                            )}
                            className='submit'
                            style={{ color: "black", marginTop: "20px" }}
                        >
                            Fetch Performance
                        </button>
                    )}
                    </div>

                    {((overallPerformance && view === 'overall') ||
  (termPerformance && view === 'term') ||
  (comparisonPerformance && view === 'comparison')) && 
  showDownloadButton && (
    <button onClick={handleDownloadReport} style={{ marginLeft: '500px', marginTop:"20px", marginBottom:"20px" }} ><FaDownload style={{ marginRight: '10px' }}/>Download Report as PDF</button>
  )
}


                    {/* Display Overall Performance Table */}
                    {view === 'overall' && overallPerformance && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Grade</th>
                                    <th>Stream</th>
                                    <th>Subject</th>
                                    <th>Performance Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {overallPerformance.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.grade}</td>
                                        <td>{item.stream}</td>
                                        <td>{item.subject}</td>
                                        <td>{item.performance_percentage}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {/* Display Term Performance Table */}
                    {view === 'term' && termPerformance && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Grade</th>
                                    <th>Stream</th>
                                    <th>Subject</th>
                                    <th>Performance Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {termPerformance.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.grade}</td>
                                        <td>{item.stream_name}</td>
                                        <td>{item.subject_name}</td>
                                        <td>{item.performance_percentage}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {/* Display Comparison Performance Table */}
                    {view === 'comparison' && comparisonPerformance && (
                        <div>
                            {Object.values(comparisonPerformance).map((term, termIndex) => (
                                <table key={termIndex}>
                                    <thead>
                                        <tr>
                                            <th colSpan="4">{term.term_name}</th>
                                        </tr>
                                        <tr>
                                            <th>Grade</th>
                                            <th>Stream</th>
                                            <th>Subject</th>
                                            <th>Performance Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {term.subjects.map((subject, subIndex) => (
                                            <tr key={subIndex}>
                                                <td>{subject.grade}</td>
                                                <td>{subject.stream_name}</td>
                                                <td>{subject.subject_name}</td>
                                                <td>{subject.performance_percentage}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    )}
                </div>
            )}


        </div>
    );
}

export default TeacherSummativePerformance;
