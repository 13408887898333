import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { retrieve } from './Encryption';

const SuperAdminResetAdminPassword= () => {
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { staffId } = useParams();
    const { staffData } = location.state; // Receive staff data from props
    const data = staffData.find(staff => staff.id === staffId);

    const handlePasswordReset = async () => {
        const confirmReset = window.confirm(`Are you sure you want to reset the password for ${data.first_name} ${data.last_name}?`);
        
        if (!confirmReset) return;  

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/reset_password', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${retrieve().access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: data.id })
            });

            if (response.ok) {
                setSuccessMessage('Password has been reset to the default successfully.');
                setTimeout(() => navigate(-1), 2000); // Redirect after 2 seconds
            } else {
                const errorData = await response.json();
                alert(errorData.detail || 'Failed to reset password.');
            }
        } catch (error) {
            console.error('An error occurred during password reset:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="reset-staff-password">
            <h2>Reset Password for Staff Member</h2>
            <p><strong>Name:</strong> {data.first_name} {data.last_name}</p>
            <p><strong>Email:</strong> {data.email_address}</p>
            <p><strong>Payroll Number:</strong> {data.payroll_number}</p>
            <button className="ui red button" onClick={handlePasswordReset}>
                Reset Password
            </button>
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div>
    );
};

export default SuperAdminResetAdminPassword;
