import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Cell } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem, Typography, Box } from '@mui/material';
import { retrieve } from './Encryption';

// Define the color scheme to cycle through
const COLORS = ['#8ac429', '#ffb134', '#ff615d'];

const TeacherSubjectDistribution = () => {
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('PP1');
  const [data, setData] = useState([]);

  // Fetch grades when the component mounts
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/grades_list`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${retrieve().access_token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setGrades(result);

        // Set PP1 as default grade if it exists
        const pp1Grade = result.find(grade => grade.grade === 'PP1');
        if (pp1Grade) {
          setSelectedGrade(pp1Grade.id);
        }
      } catch (error) {
        console.error('Error fetching grades:', error);
      }
    };
    fetchGrades();
  }, []);

  // Fetch data when the selected grade changes
  useEffect(() => {
    const fetchData = async () => {
      if (selectedGrade) {
        try {
          const response = await fetch(`https://cbc-orfl.onrender.com/teacher_subject_distribution/${selectedGrade}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${retrieve().access_token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
  }, [selectedGrade]);

  // Handle grade selection change
  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Teacher Distribution by Subject for Selected Grade</h3>
      
      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2, textAlign: 'center' }}>
        <InputLabel sx={{ textAlign: 'center' }}>Grade</InputLabel>
        <Select 
          value={selectedGrade} 
          onChange={handleGradeChange} 
          label="Grade" 
          sx={{ textAlign: 'center' }}
          MenuProps={{
            PaperProps: {
              sx: { textAlign: 'center' },
            },
          }}
        >
          {grades.map((grade) => (
            <MenuItem key={grade.id} value={grade.id}>{grade.grade}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {data.length === 0 ? (
        <Box textAlign="center" mt={2}>
          <Typography variant="h6" sx={{ color: 'red' }}>
            No assigned teachers for the subjects in this grade.
          </Typography>
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
            <XAxis dataKey="subject" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="teachers" name="Total Teachers">
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[index % COLORS.length]} // Cycle through colors
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default TeacherSubjectDistribution;
