import React, { useEffect, useState } from 'react';
import { retrieve } from './Encryption';
import { useParams, useNavigate } from 'react-router-dom';

const EditSubstrand = () => {
  const { substrandId } = useParams(); // Getting substrandId from the route params
  const navigate = useNavigate();
  const [substrand, setSubstrand] = useState({
    substrand_name: '',
  
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch the substrand details when the component mounts
  useEffect(() => {
    const fetchSubstrandDetails = async () => {
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/substrands/${substrandId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubstrand(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch substrand details.');
        }
      } catch (error) {
        setError('An error occurred while fetching substrand details.');
      }
    };

    fetchSubstrandDetails();
  }, [substrandId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubstrand((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/substrands/${substrandId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
        body: JSON.stringify(substrand),
      });

      if (response.ok) {
        setSuccessMessage('Substrand updated successfully!');
        setTimeout(() => {
          navigate('/view-superadmin-dashboard/view-strands');
        }, 2000); // Redirect after 2 seconds
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to update substrand.');
      }
    } catch (error) {
      setError('An error occurred while updating the substrand.');
    }
  };

  return (
    <div className="edit-substrand-container">
      <h2>Edit Substrand</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="substrand_name">Substrand Name:</label>
          <input
            type="text"
            id="substrand_name"
            name="substrand_name"
            value={substrand.substrand_name}
            onChange={handleChange}
            required
          />
        </div>

        

        <button type="submit" className="ui button green">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditSubstrand;
