import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';

const LinkParentWithStudents = () => {
  const [streams, setStreams] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedStream, setSelectedStream] = useState("");
  const [students, setStudents] = useState([]);
  const [linkedStudents, setLinkedStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { grades,parent } = location.state;
  const navigate = useNavigate();
  const { parentId } = useParams();

  useEffect(() => {
    if (selectedGrade) fetchStreams(selectedGrade);
  }, [selectedGrade]);

  useEffect(() => {
    if (selectedStream) fetchStudents(selectedGrade, selectedStream);
  }, [selectedStream]);

  const fetchStreams = async (gradeId) => {
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStreams(data);
      } else {
        throw new Error("Failed to fetch streams");
      }
    } catch (error) {
      console.error("Error fetching streams:", error);
      setErrorMessage("Unable to fetch streams. Please try again.");
    }
  };

  const fetchStudents = async (gradeId, streamId) => {
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/students/grades/${gradeId}/streams/${streamId}`, {
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
      } else {
        throw new Error("Failed to fetch students");
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setErrorMessage("Unable to fetch students. Please try again.");
    }
  };

  const handleGradeChange = (e) => {
    const gradeId = e.target.value;
    setSelectedGrade(gradeId);
    setSelectedStream("");
    setStudents([]);
    fetchStreams(gradeId);
  };

  const handleStreamChange = (e) => {
    const streamId = e.target.value;
    setSelectedStream(streamId);
    fetchStudents(selectedGrade, streamId);
  };

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [studentId]: !prev[studentId],
    }));
  };

  const addLinkedStudents = () => {
    const newlyLinkedStudents = students.filter((student) => selectedStudents[student.id]);
    setLinkedStudents((prev) => [...prev, ...newlyLinkedStudents]);
    setSelectedStudents({});
    setSelectedGrade("");
    setSelectedStream("");
    setStudents([]);
  };

  const resetForm = () => {
    setSelectedGrade("");
    setSelectedStream("");
    setStudents([]);
    setSelectedStudents({});
    setLinkedStudents([]);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    const studentIds = linkedStudents.map((student) => student.id);

    fetch(`https://cbc-orfl.onrender.com/parents/${parentId}/students`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retrieve().access_token}`,
      },
      body: JSON.stringify({ student_ids: studentIds }),
    })
      .then(async (response) => {
        if (response.ok) {
          alert("Students successfully linked to parent.");
          setTimeout(() => navigate(-1), 2000);
        } else {
          const errorData = await response.json();
          setTimeout(() => resetForm(), 10000); // Display error message for 10 seconds before resetting form
        }
      })
      .catch((error) => {
        console.error("Error linking students:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => resetForm(), 10000); // Display error message for 10 seconds before resetting form
      });
  };

  return (
    <div className="link-parent-students">
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>
      <h2>Link Parent with Students</h2>
      {parent.staff_details ? (
                <>
                    <h4><strong>Name:</strong> {`${parent.staff_details.first_name} ${parent.staff_details.last_name}`}</h4>
                   
                   
                </>
            ) : (
                <p>No staff details available.</p>
            )}
   

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="selection-section">
        <label>
          Select Grade:
          <select value={selectedGrade} onChange={handleGradeChange}>
            <option value="">-- Select Grade --</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.grade}
              </option>
            ))}
          </select>
        </label>

        {selectedGrade && (
          <label>
            Select Stream:
            <select value={selectedStream} onChange={handleStreamChange}>
              <option value="">-- Select Stream --</option>
              {streams.map((stream) => (
                <option key={stream.id} value={stream.id}>
                  {stream.stream_name}
                </option>
              ))}
            </select>
          </label>
        )}
      </div>

      {students.length > 0 && (
        <div className="students-section">
          <h3>Students</h3>

          <div>
            {students.map((student) => (
              <div key={student.id} className="student-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedStudents[student.id] || false}
                    onChange={() => handleStudentSelection(student.id)}
                  />
                  {student.first_name} {student.last_name}
                </label>
              </div>
            ))}
          </div>

          <button onClick={addLinkedStudents}>Add Students</button>
        </div>
      )}

      {linkedStudents.length > 0 && (
        <div className="linked-students-section">
          <h3>Linked Students</h3>
          <ul>
            {linkedStudents.map((student) => (
              <li key={student.id}>
                {student.first_name} {student.last_name}
              </li>
            ))}
          </ul>

          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </div>
  );
};

export default LinkParentWithStudents;
