import React, { useEffect, useState } from 'react';
import { retrieve } from './Encryption';
import { useNavigate } from 'react-router-dom';
import "./ViewStrands.css"

const ViewStrands = () => {
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedStrand, setSelectedStrand] = useState(null);
  const [strands, setStrands] = useState([]);
  const [substrands, setSubstrands] = useState([]);
  const [selectedSubstrand, setSelectedSubstrand] = useState(null);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [selectedLearningOutcome, setSelectedLearningOutcome] = useState(null);
  const [assessmentRubrics, setAssessmentRubrics] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();


  // Fetch grades on component mount
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await fetch('https://cbc-orfl.onrender.com/grades', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setGrades(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch grades.');
        }
      } catch (error) {
        setError('An error occurred while fetching grades.');
      }
    };

    fetchGrades();
  }, []);

  // Fetch subjects when a grade is selected
  useEffect(() => {
    const fetchSubjects = async () => {
      if (!selectedGrade) return;
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/subjects/grade/${selectedGrade}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubjects(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch subjects.');
        }
      } catch (error) {
        setError('An error occurred while fetching subjects.');
      }
    };

    fetchSubjects();
  }, [selectedGrade]);

  // Fetch strands based on selected grade and subject
  useEffect(() => {
    const fetchStrands = async () => {
      if (!selectedGrade || !selectedSubject) return;
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/strands/${selectedGrade}/${selectedSubject}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setStrands(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch strands.');
        }
      } catch (error) {
        setError('An error occurred while fetching strands.');
      }
    };

    fetchStrands();
  }, [selectedGrade, selectedSubject]);

  // Fetch substrands when a strand is selected
  useEffect(() => {
    const fetchSubstrands = async () => {
      if (!selectedStrand) return;
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/substrands_details/${selectedStrand.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubstrands(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch substrands.');
        }
      } catch (error) {
        setError('An error occurred while fetching substrands.');
      }
    };

    fetchSubstrands();
  }, [selectedStrand]);

  // Fetch learning outcomes when a substrand is selected
  const fetchLearningOutcomes = async (substrandId) => {
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/learning_outcomes_details/${substrandId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLearningOutcomes(data); // Set learning outcomes based on fetched data
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch learning outcomes.');
      }
    } catch (error) {
      setError('An error occurred while fetching learning outcomes.');
    }
  };

  const fetchAssessmentRubrics = async (learningOutcomeId) => {
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/assessment_rubrics_details/${learningOutcomeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setAssessmentRubrics(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch assessment rubrics.');
      }
    } catch (error) {
      setError('An error occurred while fetching assessment rubrics.');
    }
  };

  const handleDelete = async (strandId) => {
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/delete_strand/${strandId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
      });
  
      if (response.ok) {
        alert('Strand deleted successfully');
        // Remove the deleted strand from the local state
        setStrands(strands.filter((strand) => strand.id !== strandId));
        setSelectedStrand(null);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to delete strand.');
      }
    } catch (error) {
      setError('An error occurred while deleting the strand.');
    }
  };
  

  const handleEditLearningOutcome = (learningOutcomeId) => {
    // Implement edit functionality for substrands, learning outcomes, and assessment rubrics
    navigate(`/view-superadmin-dashboard/edit-learning-outcome/${learningOutcomeId}`);
  };
  const handleEditRubric = (rubicId) => {
    // Implement edit functionality for substrands, learning outcomes, and assessment rubrics
    navigate(`/view-superadmin-dashboard/edit-assessment-rubic/${rubicId}`);
  };
  const handleEditSubstrand = (substrandId) => {
    // Implement edit functionality for substrands, learning outcomes, and assessment rubrics
   
    navigate(`/view-superadmin-dashboard/edit-substrands/${substrandId}`);
   

  };



    return (
        <div className="content-wrapper6">
          <h2 className="header">View Strands</h2>
          <div className="form-container6">
            <h2>Select Grade and Subject</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
      
            <div className="select-container">
              <label className="select-label">Grade:</label>
              <select className="select-input"
                onChange={(e) => setSelectedGrade(e.target.value)} value={selectedGrade || ''}>
                <option value="" disabled>Select a grade</option>
                {grades.map((grade) => (
                  <option key={grade.id} value={grade.id}>
                    {grade.grade}
                  </option>
                ))}
              </select>
            </div>
      
            {selectedGrade && (
              <div className="select-container">
                <label className="select-label">Subject:</label>
                <select className="select-input"
                  onChange={(e) => setSelectedSubject(e.target.value)} value={selectedSubject || ''}>
                  <option value="" disabled>Select a subject</option>
                  {subjects.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.subject_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
      
      {selectedGrade && selectedSubject && (
  <div className='strand-section'>
    <h3>
      Strands for Grade: {grades.find(g => g.id === selectedGrade)?.grade}, 
      Subject: {subjects.find(s => s.id === selectedSubject)?.subject_name}
    </h3>
    <div className="select-container">
      <label className="select-label">Strand:</label>
      <select
        className="select-input" 
        onChange={(e) => setSelectedStrand(strands.find(strand => strand.id === e.target.value))} 
        value={selectedStrand?.id || ''}
      >
        <option value="" disabled>Select a strand</option>
        {strands.map((strand) => (
          <option key={strand.id} value={strand.id}>
            {strand.strand_name}
          </option>
        ))}
      </select>
    </div>
    {selectedStrand && (
        <>
            <h4>Selected Strand: {selectedStrand.strand_name}</h4>
            <button className="ui button red" onClick={() => handleDelete(selectedStrand.id)}>
            Delete Strand
            </button>
        </>
)}

  </div>
)}

            
      
            {selectedStrand && (
  <div className='substrand-section'>
    

    <div className="select-container">
      <h4 className="select-label">Substrand:</h4>
      <select
        className="select-input"
        onChange={(e) => {
          const substrand = substrands.find(sub => sub.id === e.target.value);
          setSelectedSubstrand(substrand);
          fetchLearningOutcomes(substrand.id);
        }}
        value={selectedSubstrand?.id || ''}
      >
        <option value="" disabled>
          Select a substrand
        </option>
        {substrands.map((substrand) => (
          <option key={substrand.id} value={substrand.id}>
            {substrand.substrand_name}
          </option>
        ))}
      </select>
    </div>

    {selectedSubstrand && (
      <div>
        <h4>Selected Substrand: {selectedSubstrand.substrand_name}</h4>
        <button className="ui button green" onClick={() => handleEditSubstrand(selectedSubstrand.id)}>
          Edit Substrand
        </button>
      </div>
    )}
  </div>
)}

            
      
            {learningOutcomes.length > 0 && (
              <div className="learning-outcome-section">
                <label>Learning Outcome:</label>
                <select className="select-container"
                  onChange={(e) => {
                    const learningOutcome = learningOutcomes.find(lo => lo.id === e.target.value);
                    setSelectedLearningOutcome(learningOutcome);
                    fetchAssessmentRubrics(learningOutcome.id);
                  }} 
                  value={selectedLearningOutcome?.id || ''}
                >
                  <option value="" disabled>Select a learning outcome</option>
                  {learningOutcomes.map((learningOutcome) => (
                    <option key={learningOutcome.id} value={learningOutcome.id}>
                      {learningOutcome.learning_outcomes}
                    </option>
                  ))}
                </select>
      
                {selectedLearningOutcome && (
                  <div>
                    <h4>Selected Learning Outcome: {selectedLearningOutcome.learning_outcomes}</h4>
                    <button className="ui button green" onClick={() => handleEditLearningOutcome(selectedLearningOutcome.id)}>Edit Learning Outcome</button>
                  </div>
                )}
              </div>
            )}
      
            {assessmentRubrics.length > 0 && (
              <div className="assessment-rubrics-section">
                <h4>Assessment Rubrics</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Marks</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentRubrics.map((rubric) => (
                      <tr key={rubric.id}>
                        <td>{rubric.assessment_rubics}</td>
                        <td>{rubric.assessment_rubic_mark}</td>
                        <td>
                          <button className="ui button green" onClick={() => handleEditRubric(rubric.id)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );
      
};

export default ViewStrands;
