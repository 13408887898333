import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import { retrieve } from "./Encryption";

import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import ExamPerformance from './ExamPerformance';

const DashBoardParent = () => {
  const [parent, setParent] = useState(null);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);

  // Access schoolId from Outlet context
  const { schoolId,parentId, schoolName, schoolLogo, schoolContact, schoolAddress, schoolEmail } = useOutletContext();

  useEffect(() => {
    const fetchParentDetails = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setParent(data); // Set the full parent object
                setStudents(data.students || []); // Extract students array
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to fetch parent details');
            }
        } catch (err) {
            setError('An error occurred while fetching parent details.');
            console.error('Fetch Parent Details Error:', err);
        }
    };

    fetchParentDetails();
}, [parentId]);

  const renderStudentDetails = (student) => (
    <Box key={student.id} sx={{ marginBottom: 4 }}   style={{
      backgroundColor: "#d9f8c4",
      padding: "10px",
      borderRadius: "5px",
      marginBottom: "20px",
    }}>
      {/* Student Details */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h6">
          {student.first_name} {student.last_name}
        </Typography>
        <Typography>
          <strong>Admission Number:</strong> {student.admission_number}
        </Typography>
        <Typography>
          <strong>Grade:</strong> {student.grade || 'N/A'}
        </Typography>
        <Typography>
          <strong>Stream:</strong> {student.stream_name || 'N/A'}
        </Typography>
      </Paper>

      {/* Student Performance Chart */}
      <Paper elevation={3} sx={{ padding: 3 }}>
        <ExamPerformance studentId={student.id} />
      </Paper>
    </Box>
  );

  return (
    <Box
      className="dashboard-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        position: 'relative',
        zIndex: 0,
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'contain',
                borderRadius: '50%',
                border: '2px solid #ccc',
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
          {schoolName ? `${schoolName} Dashboard` : 'School Dashboard'}
        </Typography>
    
      </Box>

      {/* Welcome Message */}
{parent && parent.staff_details ? (
  <Box sx={{ marginBottom: 4, display: 'flex', alignItems: 'center', gap: 1 }}>
    <Typography variant="h4" sx={{ margin: 0 }}>
      Welcome,
    </Typography>
    <Typography
      variant="h4" // Slightly larger font size
      sx={{
        margin: 0,
        fontWeight: 'bold',
        color: '#ff615d',
      }}
    >
      {`${parent.staff_details.first_name} ${parent.staff_details.last_name}`}
    </Typography>
  </Box>
) : (
  <Typography>Loading parent details...</Typography>
)}


      {/* Students and Charts */}
      {students.length > 0 ? (
        students.map(renderStudentDetails)
      ) : (
        <Typography>No students linked to this parent.</Typography>
      )}

  
    </Box>
  );
};

export default DashBoardParent;
