import React, { useEffect, useState } from 'react';
import { retrieve } from './Encryption';
import { useParams, useNavigate } from 'react-router-dom';

const EditAssessmentRubic = () => {
  const { rubicId } = useParams(); 
  const navigate = useNavigate();
  const [assessmentRubic, setAssessmentRubic] = useState({
    assessment_rubics: '',
    assessment_rubic_mark:''

  
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  
  useEffect(() => {
    const fetchAssessmentRubicsDetails = async () => {
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/assessment_rubrics/${rubicId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
           
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAssessmentRubic(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch Assessment Rubics details.');
        }
      } catch (error) {
        setError('An error occurred while fetching Assessment Rubics details.');
      }
    };

    fetchAssessmentRubicsDetails();
  }, [rubicId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssessmentRubic((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/assessment_rubrics/${rubicId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
        body: JSON.stringify(assessmentRubic),
      });

      if (response.ok) {
        setSuccessMessage('Assessment Rubric updated successfully!');
        setTimeout(() => {
          navigate('/view-superadmin-dashboard/view-strands');
        }, 2000); // Redirect after 2 seconds
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to update Assessment Rubric.');
      }
    } catch (error) {
      setError('An error occurred while updating the lAssessment Rubric');
    }
  };

  return (
    <div className="edit-substrand-container">
      <h2>Edit Assessment Rubric</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <form onSubmit={handleSubmit}>
  <div className="form-group">
    <label htmlFor="assessment_rubics">Assessement Rubric:</label>
    <input
      type="text"
      id="assessment_rubics"
      name="assessment_rubics"
      value={assessmentRubic.assessment_rubics}
      onChange={handleChange}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="assessment_rubic_mark">Assessement Rubric Mark:</label>
    <input
      type="integer"
      id="assessment_rubic_mark"
      name="assessment_rubic_mark"
      value={assessmentRubic.assessment_rubic_mark}
      onChange={handleChange}
      required
    />
  </div>

  <button type="submit" className="ui button green">
    Save Changes
  </button>
</form>

    </div>
  );
};

export default EditAssessmentRubic ;
