import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from "./Encryption";
import "./form.css"
import { IoArrowBackCircle } from "react-icons/io5";
import { FaArrowLeft } from 'react-icons/fa';

const AddStaff = () => {
    const [formData, setFormData] = useState({
        payroll_number: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        alternative_contact: '',
        email_address: '',
        designation_id: '',
        gender: '',
    });
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [designations, setDesignations] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Fetch designations
    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/designations', {
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setDesignations(data);
                } else {
                    throw new Error('Failed to fetch designations');
                }
            } catch (error) {
                console.error('Error fetching designations:', error);
                setError('Unable to fetch designations');
            }
        };
        fetchDesignations();
    }, []);

    const MAX_FILE_SIZE = 10000000; // 10MB
    const validFileExtensions = ["jpg", "png", "jpeg", "webp"];

    const getExtension = (fileName) => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLowerCase();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const size = file.size;
            const extension = getExtension(file.name);
            const isValid = validFileExtensions.includes(extension);
            if (size > MAX_FILE_SIZE) {
                setError("The file is too large. Maximum size is 10MB.");
                setProfilePhoto(null);
            } else if (!isValid) {
                setError("The file type is not supported. Allowed types: jpg, jpeg, png, webp.");
                setProfilePhoto(null);
            } else {
                setError(''); // Clear any previous error
                setProfilePhoto(file); // Set the profile photo
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDesignationChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            designation_id: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object
        const form = new FormData();
        form.append('payroll_number', formData.payroll_number);
        form.append('first_name', formData.first_name);
        form.append('last_name', formData.last_name);
        form.append('date_of_birth', formData.date_of_birth);
        form.append('phone_number', formData.phone_number);
        form.append('alternative_contact', formData.alternative_contact);
        form.append('email_address', formData.email_address);
        form.append('designation_id', formData.designation_id);
        form.append('gender', formData.gender);

        // Append profile photo if available
        if (profilePhoto) {
            form.append('profile_photo', profilePhoto);
        }

        // Define fetch options
        const options = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
            },
            body: form,
        };

        try {
            const response = await fetch('https://cbc-orfl.onrender.com/staffs', options);
            if (response.ok) {
                setSuccess('Staff added successfully!');
                setError('');
                setTimeout(() => navigate('/view-superadmin-dashboard/view-staff'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to add staff.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    function formatLabel(key) {
        return key
            .replace(/_/g, ' ')
            .replace(/\b\w/g, c => c.toUpperCase());
    }

    function determineInputType(key) {
        switch (key) {
            case 'date_of_birth':
                return 'date';
            case 'email_address':
                return 'email';
            case 'phone_number':
            case 'alternative_contact':
                return 'tel';
            default:
                return 'text';
        }
    }

    return (
        <div className='content-wrapper'>
                      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                      <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
</div>
            <h2 className='header'>Add New Staff</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}
                    
                    {Object.entries(formData).map(([key, value]) => (
                        key !== 'designation_id' && key !== 'gender' && (
                            <div className="field" key={key}>
                                <label>{formatLabel(key)}:</label>
                                <input
                                    type={determineInputType(key)}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    required={key !== 'alternative_contact'}
                                />
                            </div>
                        )
                    ))}
                    
                    <div className="field">
                        <label>Designation:</label>
                        <select
                            name="designation_id"
                            value={formData.designation_id}
                            onChange={handleDesignationChange}
                            required
                        >
                            <option value="">Select Designation</option>
                            {designations.map((designation) => (
                                <option key={designation.id} value={designation.id}>
                                    {designation.designation_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    <div className="field">
                        <label>Gender:</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    
                    <div className="field">
                        <label>Profile Photo:</label>
                        <input
                            type="file"
                            name="profile_photo"
                            accept=".jpg,.jpeg,.png,.webp"
                            onChange={handleFileChange}
                        />
                        {error && error.toLowerCase().includes("file") && (
                            <div className="error-message">{error}</div>
                        )}
                    </div>
                    
                    <div >
                        <button type="submit" className='submit'>
                            Add Staff
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddStaff;
