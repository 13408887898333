import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import { retrieve } from './Encryption';
import './ViewStudents.css';

const TeacherViewStudents = () => {
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [students, setStudents] = useState([]);
    const [streams, setStreams] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingStudents, setLoadingStudents] = useState(false);
    const [loadingStreams, setLoadingStreams] = useState(false);
    const [deletingStudent, setDeletingStudent] = useState(false);
    const [errorGrades, setErrorGrades] = useState('');
    const [errorStudents, setErrorStudents] = useState('');
    const [errorStreams, setErrorStreams] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // New state for search term
    const navigate = useNavigate();

    // Fetch grades on component mount
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);

                    const defaultGrade = data.find(grade => grade.grade === "PP1");
                    setSelectedGrade(defaultGrade ? defaultGrade.id : data[0]?.id);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch streams when selectedGrade changes
    useEffect(() => {
        const fetchStreams = async () => {
            if (selectedGrade) {
                setLoadingStreams(true);
                setErrorStreams('');
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${selectedGrade}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setStreams(data);
                    } else {
                        const errorData = await response.json();
                        setErrorStreams(errorData.error || 'Failed to fetch streams');
                    }
                } catch (error) {
                    setErrorStreams('An error occurred while fetching streams.');
                    console.error('Fetch Streams Error:', error);
                } finally {
                    setLoadingStreams(false);
                }
            }
        };

        fetchStreams();
    }, [selectedGrade]);

    // Fetch students when selectedGrade changes
    useEffect(() => {
        const fetchStudents = async () => {
            if (selectedGrade) {
                setLoadingStudents(true);
                setErrorStudents('');
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/students/grade/${selectedGrade}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setStudents(data);
                    } else {
                        const errorData = await response.json();
                        setErrorStudents(errorData.error || 'Failed to fetch students');
                        setStudents([]);
                    }
                } catch (error) {
                    setErrorStudents('An error occurred while fetching students.');
                    console.error('Fetch Students Error:', error);
                    setStudents([]);
                } finally {
                    setLoadingStudents(false);
                }
            } else {
                setStudents([]);
            }
        };

        fetchStudents();
    }, [selectedGrade]);

  
    

    // Filter students based on search term
    const filteredStudents = students.filter(student =>
        student.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.admission_number.toString().toLowerCase().includes(searchTerm.toLowerCase())||
        student.birth_certificate_number.toString().includes(searchTerm.toLowerCase())

    );

    return (
        <div className="view-students-container">
            <h2 className='header'>View Students by Grade</h2>
            <div className="controls">
                <div className="grade-select">
                    <label htmlFor="grade">Select Grade:</label>
                    {loadingGrades ? (
                        <p>Loading grades...</p>
                    ) : errorGrades ? (
                        <p style={{ color: 'red' }}>{errorGrades}</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                        >
                            <option value="">-- Select Grade --</option>
                            {grades.map(grade => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <input
                    type="text"
                    placeholder="Search by name or admission number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
              
            </div>

            {selectedGrade && (
                <div className="students-table">
                    {loadingStudents ? (
                        <p>Loading students...</p>
                    ) : errorStudents ? (
                        <p style={{ color: 'red' }}>{errorStudents}</p>
                    ) : filteredStudents.length === 0 ? (
                        <p>No students found for the selected grade or search term.</p>
                    ) : (
                        <table className="ui striped table">
                            <thead>
                                <tr>
                                    <th>Admission Number</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Gender</th>
                                    <th>Birth Certificate No</th>
                                    <th>Joined Date</th>
                                    <th>Grade</th>
                                    <th>Stream</th>
                                    <th>Status</th>
                                    <th>Photo</th>                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map(student => (
                                    <tr key={student.id}>
                                        <td>{student.admission_number}</td>
                                        <td>{student.first_name}</td>
                                        <td>{student.last_name}</td>
                                        <td>{student.gender}</td>
                                        <td>{student.birth_certificate_number}</td>
                                        <td>{student.joined_date}</td>
                                        <td>{grades.find(grade => grade.id === student.grade_id)?.grade || 'N/A'}</td>
                                        <td>{streams.find(stream => stream.id === student.stream_id)?.stream_name || 'N/A'}</td>
                                        <td>{student.active_status ? 'Active' : 'Deactivated'}</td>
                                        <td>
                                            <img src={student.photo_url} alt={`${student.first_name} ${student.last_name}`} style={{ width: '50px', height: '50px' }} />
                                        </td>
                                        <td>
  
</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default TeacherViewStudents;
