import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import { MdLockReset } from "react-icons/md";
import { retrieve } from './Encryption';
import './ViewStaff.css';

const AdminViewStaff = () => {
    const [staffData, setStaffData] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/designations', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setDesignations(result);
                } else {
                    const errorData = await response.json();
                    console.error(errorData.error || 'Failed to fetch designations');
                    setError(errorData.error || 'Failed to fetch designations');
                }
            } catch (error) {
                console.error('An error occurred while fetching designations:', error);
                setError('An error occurred while fetching designations');
            }
        };

        const fetchStaffData = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/staffs', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setStaffData(result);
                } else {
                    const errorData = await response.json();
                    setError(errorData.error || 'Failed to fetch staff data');
                }
            } catch (error) {
                setError('An error occurred while fetching staff data');
            } finally {
                setLoading(false);
            }
        };

        fetchDesignations();
        fetchStaffData();
    }, []);

    const handleEditClick = (staffId) => {
        navigate(`/view-admin-dashboard/admin-edit-staff/${staffId}`, {
            state: {
                staffData,
                designations,
            }
        });
    };

    const handleResetPassword = (staffId) => {
        navigate(`/view-admin-dashboard/reset-password/${staffId}`, {
            state: {
                staffData,
              
            }
        });
    };

    const handleDeleteClick = async (staffId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this staff member?");
        if (!confirmDelete) return;

        setDeleteLoading(true);

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${staffId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setStaffData(prevData => prevData.filter(staff => staff.id !== staffId));
                setError('');
                alert('Staff member deleted successfully!');
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to delete staff member');
            }
        } catch (error) {
            console.error('An error occurred while deleting staff:', error);
            setError('An error occurred while deleting staff member');
        } finally {
            setDeleteLoading(false);
        }
    };

    const filteredStaffData = staffData.filter(staff => {
        const designation = designations.find(d => d.id === staff.designation_id)?.designation_name || '';
        return (
            staff.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            staff.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            designation.toLowerCase().includes(searchTerm.toLowerCase())||
            staff.payroll_number.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    if (loading) {
        return <p>Loading staff data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div className="view-staff-container">
            <h2 className='header'>Staff List</h2>
            <div className="search-and-add-container">
            <input
                type="text"
                placeholder="Search by Name or Payroll number, Designation"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <div className='button-container-add' style={{
        display: 'flex', 
        justifyContent: 'center', // Center buttons horizontally
        gap: '10px', // Add spacing between the buttons
    }}>
            <button
                className="add-staff-button"
                onClick={() => navigate('/view-admin-dashboard/admin-add-staff')}
            >
                Add Staff
            </button>
            <button
                className="add-staff-button"
                onClick={() => navigate('/view-admin-dashboard/admin-add-staff-in-bulk')}
            >
                Add Staff in Bulk
            </button>
            </div>
        </div>
            {filteredStaffData.length === 0 ? (
                <p>No staff members found.</p>
            ) : (
                <table className="ui striped table">
                    <thead>
                        <tr>
                            <th>Payroll Number</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Designation</th>
                            <th>Profile Photo</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStaffData.map(staff => (
                            <tr key={staff.id}>
                                <td>{staff.payroll_number}</td>
                                <td>{staff.first_name}</td>
                                <td>{staff.last_name}</td>
                                <td>{staff.email_address}</td>
                                <td>{staff.phone_number}</td>
                                <td>{staff.gender}</td>
                                <td>{designations.find(designation => designation.id === staff.designation_id)?.designation_name || 'N/A'}</td>
                                <td>
                                    {staff.photo_url ? (
                                        <img
                                            src={staff.photo_url}
                                            alt={`${staff.first_name} ${staff.last_name}`}
                                            width="50"
                                            height="50"
                                        />
                                    ) : (
                                        'No Photo'
                                    )}
                                </td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <div 
                                            style={{ cursor: 'pointer', marginRight: '10px',marginRight:"20px" }} 
                                            onClick={() => handleEditClick(staff.id)}
                                        >
                                            <FaUserEdit style={{ color: 'green', fontSize: '20px'}} />
                                            <span style={{ fontSize: '12px' }}>Edit</span>
                                        </div>
                                        <div 
                                            style={{ cursor: 'pointer', marginRight:"20px" }} 
                                            onClick={() => handleDeleteClick(staff.id)}
                                        >
                                            <FaUserTimes style={{ color: 'red', fontSize: '20px' }} />
                                            <span style={{ fontSize: '12px' }}>Delete</span>
                                        </div>
                                        <div 
                                            style={{ cursor: 'pointer' }} 
                                            onClick={() => handleResetPassword(staff.id)}
                                        >
                                       <MdLockReset style={{ color: 'red', fontSize: '20px'}} />
                                            <span style={{ fontSize: '12px' }}>Reset Password</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {deleteLoading && <p>Deleting staff member...</p>}
        </div>
    );
};

export default AdminViewStaff;
