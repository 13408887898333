import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { retrieve } from './Encryption';

const SchoolStats = () => {
  const [stats, setStats] = useState([]);

  // Define the color scheme to cycle through
  const colors = ['#8ac429', '#ffb134', '#ff615d',  '#8ac429', '#ffb134'];

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cbc-orfl.onrender.com/school_stats', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${retrieve().access_token}`, // Replace with your actual token
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid container spacing={2} justifyContent="center">
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={2} key={index} display="flex" justifyContent="center">
          <Paper
            elevation={2}
            sx={{
              padding: 2,
              textAlign: 'center',
              width: '100%',
              backgroundColor: colors[index % colors.length], // Cycle through colors
              color: '#ffffff', // Set text color to white for better contrast
            }}
          >
            <Typography variant="h6">{stat.label}</Typography>
            <Typography variant="h4">{stat.value}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default SchoolStats;
