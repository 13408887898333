import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";
import "./DashboardLayout.css"; // Import the CSS file
import { retrieve } from "./Encryption";
import { PiStudentFill } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiDashboard3Fill,RiLockPasswordFill } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { MdStream , MdAssessment,MdOutlineAssessment, MdSubject } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { HiDocumentReport } from "react-icons/hi";
import { AiFillCodepenCircle } from "react-icons/ai";
import { AiFillCodepenSquare } from "react-icons/ai";

const SidebarTeacher = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("Unknown Role");
  const[userData,setuserData]=useState([]);
  const [designations, setDesignations] = useState([]);
  const [isReportsOpen, setIsReportsOpen] = useState(false); // Track if "View Reports" submenu is open
  const [subjectPerformanceOpen, setSubjectPerformanceOpen] = useState(false); // "Subject Performance" submenu
  const [isFormativeOpen, setIsFormativeOpen] = useState(false); // "Formative" submenu
  const [isSummativeOpen, setIsSummativeOpen] = useState(false); // "Summative" submenu
  const [studentPerformanceOpen, setStudentPerformanceOpen] = useState(false);
  const [classPerformanceOpen, setClassPerformanceOpen] = useState(false);
  const [teacherPerformanceOpen, setTeacherPerformanceOpen] = useState(false);
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };


  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  // Fetch designations from the server
  const fetchDesignations = async () => {
    try {
      const response = await fetch("https://cbc-orfl.onrender.com/designations", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${retrieve().access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDesignations(data); // Set designations in state
      } else {
        console.error("Failed to fetch designations");
      }
    } catch (error) {
      console.error("An error occurred while fetching designations:", error);
    }
  };

  // Fetch user data and role based on userId
const fetchUserData = async (id) => {
  try {
    const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${retrieve().access_token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setUserName(`${data.first_name} ${data.last_name || "Unknown User"}`);
      setuserData(data)

      // Find and set the user's role/designation based on matching the designation_id from staff data
      const userDesignation = designations.find(
        (designation) => designation.id === data.designation_id
      );
      setUserRole(userDesignation ? userDesignation.designation_name : "Unknown Role");
    } else {
      console.error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("An error occurred while fetching user data:", error);
  }
};


  useEffect(() => {
    // Fetch designations on component mount
    fetchDesignations();

    // Retrieve the decrypted object from localStorage
    const retrievedData = retrieve();
    if (retrievedData && retrievedData.access_token) {
      // Decode the access token to get the payload
      const decodedToken = parseJwt(retrievedData.access_token);
      setUserId(decodedToken.staff_id);
    } else {
      console.log("No access token found");
    }
  }, []); // Only run on mount

  useEffect(() => {
    // Fetch user data only if designations are available and userId is set
    if (userId && designations.length > 0) {
      fetchUserData(userId);
    }
  }, [userId, designations]); // Run this effect when userId or designations change

  useEffect(() => {
    // Scroll to top when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]); // Trigger on route change

  return (
    <div className="sidebar">
      {/* Profile Section */}
   
        <Card className="profile-card">
          <Card.Content>
            <div className="profile-picture">
              <img src="/profile_photo.png" alt="Profile" />
            </div>
            <Card.Header>{userName}</Card.Header>
            <Card.Meta>{userRole}</Card.Meta>
          </Card.Content>
        </Card>
   

      {/* Menu Items */}
      <ul className="sidebar-menu">
        <li>
        <button 
          onClick={() => navigate("/view-teacher-dashboard/dashboard-teacher")}
          style={{ backgroundColor: isActive("/view-teacher-dashboard/dashboard-teacher") ? "#d4e5b0" : "" }}
        >
          <RiDashboard3Fill  className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
          Dashboard
        </button>

        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teacher-view-students")}
           style={{ backgroundColor: isActive("/view-teacher-dashboard/teacher-view-students") ? "#d4e5b0" : "" }}
          
            
            >
            <PiStudentFill className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Students
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teacher-view-staff")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/teacher-view-staff") ? "#d4e5b0" : "" }}
            
            
            
            >
            <FaPeopleGroup className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Staff
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teachers-view-self")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/teachers-view-self") ? "#d4e5b0" : "" }}
            
            
            
            >
            <FaChalkboardTeacher className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Teachers
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teachers-view-grades")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/teachers-view-grades") ? "#d4e5b0" : "" }}
            
            
            >
            <SiGoogleclassroom name="users" className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Grades
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teachers-view-streams")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/teachers-view-streams") ? "#d4e5b0" : "" }}
            
            >
            <MdStream className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Streams
          </button>
        </li>
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/teachers-view-behaviour-goals")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/teachers-view-behaviour-goals") ? "#d4e5b0" : "" }}
            
            >
            <GoGoal name="users" className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
            Behaviour Goals
          </button>
        </li>
    
        <li>
          <button onClick={() => navigate("/view-teacher-dashboard/view-assessments-grades")}
            style={{ backgroundColor: isActive("/view-teacher-dashboard/view-assessments-grades") ? "#d4e5b0" : "" }}
            
            >
            <MdOutlineAssessment  name="users" className="menu-icon" style={{ marginRight: '10px', fontSize:"25px" }} /> 
             View Assessments
          </button>
        </li>
             
        <li>
          <button onClick={() => setIsReportsOpen(!isReportsOpen)}>
            <HiDocumentReport name="users" className="menu-icon" style={{ marginRight: "10px", fontSize:"25px" }} />
            View Reports
            <Icon name={isReportsOpen ? "angle up" : "angle down"} style={{ marginLeft: "5px" }} />
          </button>
          {isReportsOpen && (
            <ul className="submenu">
              
                <button onClick={() => setSubjectPerformanceOpen(!subjectPerformanceOpen)}><MdSubject style={{ marginRight: "10px", fontSize:"25px" }}/>
                  Subject Performance
                  <Icon name={studentPerformanceOpen ? "angle up" : "angle down"} style={{ marginLeft: "5px" }} />
                </button>
                {subjectPerformanceOpen && (
                  <ul className="submenu">
                    <button onClick={() => navigate("/view-admin-dashboard/subject-performance/formative")}  style={{ backgroundColor: isActive("/view-admin-dashboard/subject-performance/formative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenCircle style={{ marginRight:"10px", fontSize:"20px" }} />Formative</button>
                    <button onClick={() => navigate("/view-admin-dashboard/subject-performance/summative")}  style={{ backgroundColor: isActive("/view-admin-dashboard/subject-performance/summative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenSquare  style={{ marginRight:"10px", fontSize:"20px"  }}/>Summative</button>
                  </ul>
                )}
          
              
                <button onClick={() => setStudentPerformanceOpen(!studentPerformanceOpen)}>
                <PiStudentFill style={{ marginRight: "10px", fontSize:"25px" }}/>  Student Performance
                  <Icon name={studentPerformanceOpen ? "angle up" : "angle down"} style={{ marginLeft: "5px" }} />
                </button>
                {studentPerformanceOpen && (
                  <ul className="submenu">
                    <button onClick={() => navigate("/view-admin-dashboard/student-performance/formative")} style={{ backgroundColor: isActive("/view-admin-dashboard/student-performance/formative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenCircle style={{ marginRight:"10px", fontSize:"20px" }} />Formative</button>
                    <button onClick={() => navigate("/view-admin-dashboard/student-performance/summative")} style={{ backgroundColor: isActive("/view-admin-dashboard/student-performance/summative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenSquare  style={{ marginRight:"10px", fontSize:"20px"  }}/>Summative</button>
                  </ul>
                )}
            
           
                <button onClick={() => setClassPerformanceOpen(!classPerformanceOpen)}>
                <SiGoogleclassroom style={{ marginRight: "10px", fontSize:"25px" }}/> Class Performance
                  <Icon name={classPerformanceOpen ? "angle up" : "angle down"} style={{ marginLeft: "5px" }} />
                </button>
                {classPerformanceOpen && (
                  <ul className="submenu">
                    <button onClick={() => navigate("/view-admin-dashboard/class-performance/formative")} style={{ backgroundColor: isActive("/view-admin-dashboard/class-performance/formative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenCircle style={{ marginRight:"10px", fontSize:"20px" }} />Formative</button>
                    <button onClick={() => navigate("/view-admin-dashboard/class-performance/summative")}  style={{ backgroundColor: isActive("/view-admin-dashboard/class-performance/summative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenSquare  style={{ marginRight:"10px", fontSize:"20px"  }}/>Summative</button>
                  </ul>
                )}
            
                <button onClick={() => setTeacherPerformanceOpen(!teacherPerformanceOpen)}>
                <FaChalkboardTeacher style={{ marginRight: "10px", fontSize:"25px" }}/>  Teacher Performance
                  <Icon name={teacherPerformanceOpen ? "angle up" : "angle down"} style={{ marginLeft: "5px" }} />
                </button>
                {teacherPerformanceOpen && (
                  <ul className="submenu">
                    <button onClick={() => navigate("/view-admin-dashboard/teacher-performance/formative")} style={{ backgroundColor: isActive("/view-admin-dashboard/teacher-performance/formative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenCircle style={{ marginRight:"10px", fontSize:"20px" }} />Formative</button>
                    <button onClick={() => navigate("/view-admin-dashboard/teacher-performance/summative")} style={{ backgroundColor: isActive("/view-admin-dashboard/teacher-performance/summative") ? "#d4e5b0" : "" }}
            ><AiFillCodepenSquare  style={{ marginRight:"10px", fontSize:"20px"  }}/>Summative</button>
                  </ul>
                )}
           
            </ul>
          )}
        </li>
        <li>
          <button onClick={() => navigate(`/view-teacher-dashboard/teacher-change-own-password/${userId}`)}  style={{ backgroundColor: isActive(`/view-admin-dashboard/admin-change-own-password/${userId}`) ? "#d4e5b0" : "" }}
            >
            <RiLockPasswordFill  className="menu-icon" style={{ marginRight: '10px', fontSize:"25px"  }} /> 
             Change Password
          </button>
        </li>
        
        
        {/* Add more menu items here */}
      </ul>
    </div>
  );
};

export default SidebarTeacher;
