import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { Card, Button, Header, Segment, Message } from 'semantic-ui-react';
import { retrieve } from './Encryption';
import './AdminViewAssessments.css';

const ViewAssessments = () => {
    const { gradeId} = useParams(); // Get the grade_id from the URL
    const [grades, setGrades] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [errorGrades, setErrorGrades] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/grades/${gradeId}`, {
                  
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    const handleNavigation = (assessmentType) => {
        navigate(`/view-teacher-dashboard/${assessmentType}/${gradeId}`); // Use grade_id from useParams
    };

    return (
        <div className="view-teachers-container">
            <Header as="h2" textAlign="center" className='header'>
                View Assessments
            </Header>

            {loadingGrades ? (
                <Message info>
                    <Message.Header>Loading data...</Message.Header>
                </Message>
            ) : errorGrades ? (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorGrades}</p>
                </Message>
            ) : (
                <Segment style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: "40px" }}>

                    <Button className="assign-button2" style={{ backgroundColor:"#8ac429" , fontFamily:"cursive" , fontSize:"17px"}} onClick={() => handleNavigation('formative-assessments-view')}>
                        View Formative Assessments
                    </Button>
                    <Button style={{ backgroundColor: "#ffb134", fontFamily:"cursive" , fontSize:"17px"}} onClick={() => handleNavigation('summative-assessments-view')}>
                        View Summative Assessments
                    </Button>
                    <Button style={{ backgroundColor: "#ff615d", fontFamily:"cursive" , fontSize:"17px"}} onClick={() => handleNavigation('behaviour-goals-assessments-view')}>
                        View Behaviour Goals Assessments
                    </Button>
                </Segment>
            )}
        </div>
    );
};

export default ViewAssessments;
