import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { retrieve } from "./Encryption";

const EditAdmin = () => {
    const { staffId } = useParams(); // Get staff ID from URL parameters
    const [formData, setFormData] = useState({
        payroll_number: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone_number: '',
        alternative_contact: '',
        email_address: '',
        designation_id: '',
        gender: '',
        is_class_teacher: false,
        active_status: false,
        photo_url: '', // Initialize photo_url in state
    });
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [designations, setDesignations] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Fetch existing staff data by ID
    useEffect(() => {
        const fetchStaffData = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${staffId}`, {
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setFormData(prevData => ({
                        ...prevData,
                        ...data,
                        is_class_teacher: data.is_class_teacher || false,
                        active_status: data.active_status || false,
                        photo_url: data.photo_url || '',
                    }));
                } else {
                    throw new Error('Failed to fetch staff data');
                }
            } catch (error) {
                console.error('Error fetching staff data:', error);
                setError('Unable to fetch staff data');
            }
        };
        fetchStaffData();
    }, [staffId]);

    // Fetch designations
    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/designations', {
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setDesignations(data);
                } else {
                    throw new Error('Failed to fetch designations');
                }
            } catch (error) {
                console.error('Error fetching designations:', error);
                setError('Unable to fetch designations');
            }
        };
        fetchDesignations();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePhoto(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== undefined && key !== 'photo_url') {
                form.append(key, formData[key]);
            }
        });

        if (profilePhoto) {
            form.append('profile_photo', profilePhoto);
        }

        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${retrieve().access_token}`,
            },
            body: form,
        };

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/staffs/${staffId}`, options);
            if (response.ok) {
                const updatedStaffData = await response.json();
                setFormData(prevData => ({
                    ...prevData,
                    photo_url: updatedStaffData.photo_url,
                }));
                setSuccess('Staff updated successfully!');
                setError('');
                setTimeout(() => navigate('/view-superadmin-dashboard/view-staff'), 2000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update staff.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error updating staff:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <h2 className='header'>Edit Admin</h2>

            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {/* Profile Photo */}
                    <div className="field">
                        <label>
                            Profile Photo:
                            <div>
                                {formData.photo_url ? (
                                    <img src={formData.photo_url} alt="Profile" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                ) : (
                                    <img src="/path/to/placeholder.png" alt="Placeholder" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                )}
                            </div>
                        </label>
                    </div>

                    {/* Payroll Number */}
                    <div className="field">
                        <label>
                            Payroll Number:
                            <input
                                type="text"
                                name="payroll_number"
                                value={formData.payroll_number}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>

                    {/* First Name */}
                    <div className="field">
                        <label>
                            First Name:
                            <input
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>

                    {/* Last Name */}
                    <div className="field">
                        <label>
                            Last Name:
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>

                    {/* Remaining Fields */}
                    {Object.entries(formData).map(([key, value]) =>
                        key !== 'payroll_number' &&
                        key !== 'first_name' &&
                        key !== 'last_name' &&
                        key !== 'designation_id' &&
                        key !== 'gender' &&
                        key !== 'is_class_teacher' &&
                        key !== 'active_status' &&
                        key !== 'id' &&
                        key !== 'photo_url' &&
                        key !== 'password' && // Exclude password
                        key !== 'school_id' && // Exclude school ID
                        (
                            <div className="field" key={key}>
                                <label>
                                    {formatLabel(key)}:
                                    <input
                                        type={determineInputType(key)}
                                        name={key}
                                        value={value}
                                        onChange={handleChange}
                                        required={key !== 'alternative_contact'}
                                    />
                                </label>
                            </div>
                        )
                    )}

                    {/* Designation Field */}
                    <div className="field">
                        <label>
                            Designation:
                            <select
                                name="designation_id"
                                value={formData.designation_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Designation</option>
                                {designations.map((designation) => (
                                    <option key={designation.id} value={designation.id}>
                                        {designation.designation_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    {/* Gender Field */}
                    <div className="field">
                        <label>
                            Gender:
                            <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </label>
                    </div>

                    {/* Class Teacher Checkbox */}
                    <div className="field">
                        <label>
                            Class Teacher:
                            <input
                                type="checkbox"
                                name="is_class_teacher"
                                checked={formData.is_class_teacher}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </div>

                    {/* Active Status Checkbox */}
                    <div className="field">
                        <label>
                            Active Status:
                            <input
                                type="checkbox"
                                name="active_status"
                                checked={formData.active_status}
                                onChange={handleCheckboxChange}
                            />
                        </label>
                    </div>

                    {/* File Upload for Profile Photo */}
                    <div className="field">
                        <label>
                            Upload Profile Photo:
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            type="submit"
                            className='ui red button'
                            style={{ width: "100%", maxWidth: "200px" }}
                        >
                            Update Staff
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                </form>
            </div>
        </div>
    );
};

const formatLabel = (key) => {
    return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

const determineInputType = (key) => {
    switch (key) {
        case 'email_address':
            return 'email';
        case 'phone_number':
        case 'alternative_contact':
            return 'tel';
        case 'date_of_birth':
            return 'date';
        default:
            return 'text';
    }
};

export default EditAdmin;
