import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { retrieve } from './Encryption'; // Adjust the import based on your project structure
import './TeacherDetails.css'; // Ensure this CSS file exists and is correctly styled

const TeacherDetails = () => {
    const { teacherId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { teachers, grades } = location.state;

    const teacher = teachers.find(teacher => teacher.id === teacherId); // Use passed data directly

    const [assignedGrades, setAssignedGrades] = useState([]);
    const [classTeacherAssignments, setClassTeacherAssignments] = useState([]);
    const [teacherSubjects, setTeacherSubjects] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
   
    const [deleteClassTeacherLoading, setDeleteClassTeacherLoading] = useState(false);
    const [deleteClassTeacherError, setDeleteClassTeacherError] = useState('');

    // Deletion states for teacher subjects
    const [deleteTeacherSubjectLoading, setDeleteTeacherSubjectLoading] = useState(false);
    const [deleteTeacherSubjectError, setDeleteTeacherSubjectError] = useState('');

    // Fetch assigned grades
    const fetchAssignedGrades = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/get_assigned_grades/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch assigned grades.');

            const data = await response.json();
            setAssignedGrades(data.assigned_grades);
        } catch (err) {
            console.error(err);
        }
    };

    // Fetch class teacher assignments
    const fetchClassTeacherAssignments = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/get_class_teacher/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch class teacher assignments.');

            const data = await response.json();
            setClassTeacherAssignments(Array.isArray(data) ? data : []);
        } catch (err) {
            console.error(err);
        }
    };

    // Fetch teacher subjects
    const fetchTeacherSubjects = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/teacher/${teacherId}/subjects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch teacher subjects.');

            const data = await response.json();
            setTeacherSubjects(Array.isArray(data.assignments) ? data.assignments : []);
        } catch (err) {
            console.error(err);
        }
    };
    // Function to handle deletion of an assigned grade
    const handleDeleteGrade = async (gradeId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this grade assignment?');
        if (!confirmDelete) return;

        try {
            const deleteResponse = await fetch(`https://cbc-orfl.onrender.com/delete_teacher_grade_assignment/${teacherId}/${gradeId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!deleteResponse.ok) {
                const errorData = await deleteResponse.json();
                throw new Error(errorData.error || 'Failed to delete grade assignment.');
            }

            const result = await deleteResponse.json();
            setSuccessMessage(result.message || 'Grade assignment deleted successfully.');

            // Remove the deleted grade from the state
            setAssignedGrades((prevGrades) => prevGrades.filter((grade) => grade.grade_id !== gradeId));
        } catch (err) {
            console.error(err);
            setGradesError(err.message);
        }
    };

    // Function to handle deletion of a class teacher assignment
    const handleDeleteClassTeacherAssignment = async (assignmentId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this class teacher assignment?');
        if (!confirmDelete) return;

        setDeleteClassTeacherLoading(true);
        setDeleteClassTeacherError('');
        setSuccessMessage('');

        try {
            const deleteResponse = await fetch(`https://cbc-orfl.onrender.com/delete_class_teacher/${assignmentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!deleteResponse.ok) {
                const errorData = await deleteResponse.json();
                throw new Error(errorData.error || 'Failed to delete class teacher assignment.');
            }

            const result = await deleteResponse.json();
            setSuccessMessage(result.message || 'Class teacher assignment deleted successfully.');

            // Remove the deleted assignment from the state
            setClassTeacherAssignments((prevAssignments) =>
                prevAssignments.filter((assignment) => assignment.id !== assignmentId)
            );
        } catch (err) {
            console.error(err);
            setDeleteClassTeacherError(err.message);
        } finally {
            setDeleteClassTeacherLoading(false);
        }
    };

    // Function to handle deletion of a teacher subject assignment
    const handleDeleteTeacherSubjectAssignment = async (assignmentId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this subject assignment?');
        if (!confirmDelete) return;

        setDeleteTeacherSubjectLoading(true);
        setDeleteTeacherSubjectError('');
        setSuccessMessage('');

        try {
            const deleteResponse = await fetch(`https://cbc-orfl.onrender.com/delete_teacher_subject/${assignmentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!deleteResponse.ok) {
                const errorData = await deleteResponse.json();
                throw new Error(errorData.error || 'Failed to delete subject assignment.');
            }

            const result = await deleteResponse.json();
            setSuccessMessage(result.message || 'Subject assignment deleted successfully.');

            // **Corrected Filter Logic:**
            // Ensure we filter by 'id' instead of 'subject_id'
            setTeacherSubjects((prevSubjects) =>
                prevSubjects.filter((assignment) => assignment.id !== assignmentId)
            );
        } catch (err) {
            console.error(err);
            setDeleteTeacherSubjectError(err.message);
        } finally {
            setDeleteTeacherSubjectLoading(false);
        }
    };


    useEffect(() => {
        fetchAssignedGrades();
        fetchClassTeacherAssignments();
        fetchTeacherSubjects();
        setDeleteClassTeacherError('');
        setDeleteTeacherSubjectError('');

    }, [teacherId]);

    return (
        <div className="teacher-details-container">
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>

            {teacher ? (
                <>
                    <h2 style={{ color: '#3CB043' }}>
                        {teacher.first_name} {teacher.last_name}
                    </h2>
                    <div className="personal-details">
                        <img
                            src={teacher.photo_url}
                            alt={`${teacher.first_name} ${teacher.last_name}`}
                            className="teacher-photo"
                        />
                        <div className="details">
                            <p><strong>Payroll Number:</strong> {teacher.payroll_number}</p>
                            <p><strong>Email:</strong> {teacher.email_address}</p>
                            <p><strong>Phone Number:</strong> {teacher.phone_number}</p>
                        </div>
                    </div>

                    {/* Assigned Grades Section */}
                    <div className="assigned-grades">
                        <h3 style={{ textAlign: 'left' }}>Assigned Grades</h3>
                        {assignedGrades.length > 0 ? (
                            <ul>
                                {assignedGrades.map((grade) => (
                                    <li key={grade.grade_id} className="grade-item">
                                        {grade.grade_name}
                                        <FaTrash
                                            className="delete-icon"
                                            onClick={() => handleDeleteGrade(grade.grade_id)}
                                            title="Delete Grade Assignment"
                                            aria-label={`Delete grade assignment for ${grade.grade_name}`}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No grades assigned to this teacher.</p>
                        )}
                    </div>

                    {/* Class Teacher Assignments Section */}
                    <div className="class-teacher-assignments">
                        <h3 style={{ textAlign: 'left' }}>Class Teacher Assignments</h3>
                        {classTeacherAssignments.length > 0 ? (
                            <ul>
                                {classTeacherAssignments.map((assignment) => (
                                    <li key={assignment.id} className="assignment-item">
                                        <strong>Grade:</strong> {assignment.grade_name}{' '}
                                        <strong>Stream:</strong> {assignment.stream_name}
                                        <FaTrash
                                            className="delete-icon"
                                            onClick={() => handleDeleteClassTeacherAssignment(assignment.id)}
                                            title="Delete Class Teacher Assignment"
                                            aria-label="Delete Class Teacher Assignment"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No assigned class for class teacher assignments.</p>
                        )}
                    </div>

                    {/* Teacher Subjects Section */}
                    <div className="teacher-subjects">
                        <h3 style={{ textAlign: 'left' }}>Assigned Subjects</h3>
                        {teacherSubjects.length > 0 ? (
                            <ul>
                                {teacherSubjects.map((subject) => (
                                    <li key={subject.id} className="subject-item">
                                        {subject.subject_name}
                                        <FaTrash
                                            className="delete-icon"
                                            onClick={() => handleDeleteTeacherSubjectAssignment(subject.id)}
                                            title="Delete Subject Assignment"
                                            aria-label={`Delete subject assignment for ${subject.subject_name}`}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No assigned subjects.</p>
                        )}
                    </div>

                    {successMessage && <p className="success-message">{successMessage}</p>}
                </>
            ) : (
                <p>Teacher not found.</p>
            )}
        </div>
    );
};

export default TeacherDetails;
