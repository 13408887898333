import React, { useState, useEffect } from 'react';
import { retrieve } from "./Encryption";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './SubjectFormativePerformance.css';
import { FaArrowLeft,FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Box, CssBaseline,Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context

const SubjectFormativePerformance = () => {
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStream, setSelectedStream] = useState('');
  const [reportType, setReportType] = useState(null);
  const [results, setResults] = useState([]);
  const [showGetReportButton, setShowGetReportButton] = useState(true); // New state to control button visibility
  const navigate =useNavigate()
  const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
  
  

  useEffect(() => {
    fetch('https://cbc-orfl.onrender.com/grades', {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => setGrades(data))
      .catch((error) => console.error('Error fetching grades:', error));
  }, []);

  const fetchStreams = (gradeId) => {
    fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => setStreams(data))
      .catch((error) => console.error('Error fetching streams:', error));
  };

  const fetchReportByGrade = () => {
    fetch(`https://cbc-orfl.onrender.com/subject_perfomance_formative_grade/${selectedGrade}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
        setShowGetReportButton(false); // Hide the Get Report button
      })
      .catch((error) => console.error('Error fetching grade report:', error));
  };

  const fetchReportByStream = () => {
    fetch(`https://cbc-orfl.onrender.com/subject_performance_formative_stream/${selectedGrade}/${selectedStream}`, {
      headers: { Authorization: `Bearer ${retrieve().access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
        setShowGetReportButton(false); // Hide the Get Report button
      })
      .catch((error) => console.error('Error fetching stream report:', error));
  };

  const handleReportTypeClick = (type) => {
    setReportType(type);
    setResults([]);
    setSelectedGrade('');
    setSelectedStream('');
    setShowGetReportButton(true); // Reset button visibility
  };

  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
    if (reportType === 'stream') fetchStreams(e.target.value);
    setShowGetReportButton(true); // Reset button visibility when selecting a new grade
  };

  const handleSubmit = () => {
    if (reportType === 'grade') {
      fetchReportByGrade();
    } else if (reportType === 'stream') {
      fetchReportByStream();
    }
  };
  

const handleDownloadReport = async () => {
  const doc = new jsPDF();
  doc.setFontSize(12);
  const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
  
  // Center the title by calculating the X position
  const title = 'Subject Formative Performance Report';
  const titleWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
  const titleX = (pageWidth - titleWidth) / 2; // Center horizontally
  doc.text(title, titleX, 42);

  doc.setFontSize(10);
  const fontSizeSmall = 6; // Font size for smaller text like address and contact
  
  const fetchLogo = async () => {
    try {
        let logoUrl = schoolLogo;

        // Replace 'http' with 'https' if necessary
        if (logoUrl.startsWith('http://')) {
            logoUrl = logoUrl.replace('http://', 'https://');
        }

        const response = await fetch(logoUrl);
        if (!response.ok) throw new Error("Failed to fetch logo");

        const blob = await response.blob();
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error("Failed to load logo:", error);
        return null; // Provide a fallback or skip adding the logo
    }
};

const logoBase64 = await fetchLogo();

// Add the logo if successfully fetched
if (logoBase64) {
    const imageWidth = 10; // Set the small width for the logo
    const imageHeight = 10; // Set the small height for the logo
    const xPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
    const yPosition = 10; // Top margin for the logo

    // Draw circular border around the logo
    const centerX = xPosition + imageWidth / 2;
    const centerY = yPosition + imageHeight / 2;
    const radius = imageWidth / 2;

    doc.setLineWidth(0.5);
    doc.addImage(logoBase64, 'JPEG', xPosition, yPosition, imageWidth, imageHeight);
}

// Center the school name and contact details
const textX = pageWidth / 2;
doc.text(schoolName, textX, 25, { align: 'center' });
doc.setFontSize(fontSizeSmall);
doc.text(`Address: ${schoolAddress}`, textX, 28, { align: 'center' });
doc.text(`Contact: ${schoolContact}`, textX, 31, { align: 'center' });
doc.text(`Email: ${schoolEmail}`, textX, 34, { align: 'center' });

const columns = ["Grade", reportType === 'stream' ? "Stream" : "", "Subject", "Average Score"];
const rows = results.map(result => [
  result.grade,
  reportType === 'stream' ? result.stream_name : '',
  result.subject_name,
  parseFloat(result.average_score).toFixed(2),
]);

// Center the table and adjust column widths
doc.autoTable({
  head: [columns],
  body: rows,
  startY: 47,
  styles: { fontSize: 10, cellPadding: 2, halign: 'center' }, // Center align all cells by default
  headStyles: { halign: 'center' }, // Center align header cells
  bodyStyles: { halign: 'center' }, // Center align body cells
});


doc.save('Subject_Formative_Performance_Report.pdf');
};


  return (
    <div className="container">
      <h2 className="header">View Subject Formative Performance</h2>
      
      <div className="button-group" style={{ marginTop: "60px" }}>
        <button
          onClick={() => handleReportTypeClick('grade')}
          className={`assign-button2 ${reportType === 'grade' ? 'button-selected' : ''}`}
          style={{ backgroundColor: "#8ac429" }}
        >
          View Report by Grade
        </button>
        <button
          onClick={() => handleReportTypeClick('stream')}
          className={`assign-button2 ${reportType === 'stream' ? 'button-selected' : ''}`}
          style={{ backgroundColor: "#ffb134" }}
        >
          View Report by Stream
        </button>
      </div>
  
      {reportType && (
        <div className="form-group">
          <label className="label">
            Select Grade:
            <select value={selectedGrade} onChange={handleGradeChange} className="select">
              <option value="">--Select Grade--</option>
              {grades.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.grade}
                </option>
              ))}
            </select>
          </label>
  
          {reportType === 'stream' && selectedGrade && (
            <label className="label">
              Select Stream:
              <select value={selectedStream} onChange={(e) => setSelectedStream(e.target.value)} className="select">
                <option value="">--Select Stream--</option>
                {streams.map((stream) => (
                  <option key={stream.id} value={stream.id}>
                    {stream.stream_name}
                  </option>
                ))}
              </select>
            </label>
          )}
  
          {showGetReportButton && (
            <div className="form-group5">
              <button
                onClick={handleSubmit}
                disabled={!selectedGrade || (reportType === 'stream' && !selectedStream)}
                className="submit-button4"
              >
                Get Report
              </button>
            </div>
          )}
        </div>
      )}
  
      <div>
        {results.length > 0 && (
          <>
          <button
              onClick={handleDownloadReport}
              style={{
                marginTop: "20px",
                padding: "10px 20px",
               
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <FaDownload /> Download Report
            </button>
            <table className="table">
              <thead>
                <tr>
                  <th>Grade</th>
                  {reportType === 'stream' && <th>Stream</th>}
                  <th>Subject</th>
                  <th>Average Score</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.grade}</td>
                    {reportType === 'stream' && <td>{result.stream_name}</td>}
                    <td>{result.subject_name}</td>
                    <td>{parseFloat(result.average_score).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
  
            
          </>
        )}
      </div>
    </div>
  );
  
 
};

export default SubjectFormativePerformance;
