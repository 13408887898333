import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { Select, MenuItem, FormControl, InputLabel, Typography, Box } from '@mui/material';
import { retrieve } from './Encryption';

const COLORS = ['#f7c7c0','#d4e5b0'];

const StudentChart = () => {
  const [gradeData, setGradeData] = useState({});
  const [selectedGrade, setSelectedGrade] = useState('PP1'); // Default grade set to PP1
  const [selectedStream, setSelectedStream] = useState('');
  const [currentData, setCurrentData] = useState({ male: 0, female: 0 });

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cbc-orfl.onrender.com/students_stats', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${retrieve().access_token}`, // Replace with your actual token
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setGradeData(result);
  
        // Set the default stream for the selected grade
        if (result[selectedGrade] && result[selectedGrade].default_stream) {
          setSelectedStream(result[selectedGrade].default_stream);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedGrade]);
  

  // Update current data based on selections
  useEffect(() => {
    if (selectedGrade && selectedStream) {
      const maleCount = gradeData[selectedGrade]?.[selectedStream]?.male || 0;
      const femaleCount = gradeData[selectedGrade]?.[selectedStream]?.female || 0;
      setCurrentData({ male: maleCount, female: femaleCount });
    }
  }, [selectedGrade, selectedStream, gradeData]);

  const data = [
    { name: 'Male', value: currentData.male },
    { name: 'Female', value: currentData.female },
  ];

  const noStudents = currentData.male === 0 && currentData.female === 0;

  // Sort grades as requested
  const sortedGrades = Object.keys(gradeData).sort((a, b) => {
    const gradeOrder = ['PP1', 'PP2', ...Array.from({ length: 12 }, (_, i) => `Grade ${i + 1}`)];
    return gradeOrder.indexOf(a) - gradeOrder.indexOf(b);
  });

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Student Gender Distribution for {selectedGrade} - {selectedStream}
      </Typography>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
        <InputLabel>Grade</InputLabel>
        <Select value={selectedGrade} onChange={(e) => setSelectedGrade(e.target.value)} label="Grade">
          {sortedGrades.map((grade) => (
            <MenuItem key={grade} value={grade}>{grade}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
        <InputLabel>Stream</InputLabel>
        <Select 
          value={selectedStream} 
          onChange={(e) => setSelectedStream(e.target.value)} 
          label="Stream"
        >
          {selectedGrade && Object.keys(gradeData[selectedGrade] || {}).map((stream) => (
            <MenuItem key={stream} value={stream}>{stream}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {noStudents ? (
        <Typography variant="h6" sx={{ color: 'red' }}>
          No students found for this grade and stream.
        </Typography>
        
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <PieChart width={300} height={300}>
            <Pie data={data} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value">
              {data.map((entry, index) => (
                <Cell key={`cell-${entry.name}-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>

          <Box sx={{ marginLeft: 2, fontSize: '1.25rem' }}>
            <Typography variant="body1">Counts:</Typography>
            <Box display="flex" alignItems="center" sx={{ marginTop: 1 }}>
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: COLORS[0],
                  marginRight: 1,
                }}
              />
              <Typography variant="body2">Male: {currentData.male}</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginTop: 1 }}>
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: COLORS[1],
                  marginRight: 1,
                }}
              />
              <Typography variant="body2">Female: {currentData.female}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default StudentChart;
