import React, { useState, useEffect } from 'react';
import { retrieve } from './Encryption';
import { useNavigate } from 'react-router-dom';
import './GenerateFormativeReport.css';
import { FaArrowLeft } from 'react-icons/fa';

const SummativeReportGenerator = () => {
    const [grades, setGrades] = useState([]);
    const [streams, setStreams] = useState([]);
    const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [years, setYears] = useState([]);
    const [terms, setTerms] = useState([]); // State for terms
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
  
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStream, setSelectedStream] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectAllStudents, setSelectAllStudents] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedTerm, setSelectedTerm] = useState('');
  
    const [currentTab, setCurrentTab] = useState('term'); // Tab state
    const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [gradesRes, yearsRes, termsRes] = await Promise.all([
          fetch('https://cbc-orfl.onrender.com/grades', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${retrieve().access_token}`,
            },
          }),
          fetch('https://cbc-orfl.onrender.com/current_year', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${retrieve().access_token}`,
            },
          }),
          fetch('https://cbc-orfl.onrender.com/terms', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${retrieve().access_token}`,
            },
          }),
        ]);

        if (!gradesRes.ok || !yearsRes.ok || !termsRes.ok) throw new Error('Network response was not ok');

        const gradesData = await gradesRes.json();
        const yearsData = await yearsRes.json();
        const termsData = await termsRes.json();

        setGrades(gradesData);
        setYears([yearsData]);
        setTerms(termsData);
      } catch (error) {
        setError('Failed to fetch data. Please try again.');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGradeChange = async (gradeId) => {
    setSelectedGrade(gradeId);
    setLoading(true);
    setError('');
    
    try {
      const [streamsRes, subjectsRes] = await Promise.all([
        fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        }),
        fetch(`https://cbc-orfl.onrender.com/subjects/grade/${gradeId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        }),
      ]);

      if (!streamsRes.ok || !subjectsRes.ok) throw new Error('Network response was not ok');
      
      const streamsData = await streamsRes.json();
      const subjectsData = await subjectsRes.json();
      
      setStreams(streamsData);
      setSubjects(subjectsData);
    } catch (error) {
      setError('Failed to fetch streams or subjects.');
      console.error('Error fetching streams or subjects:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStreamChange = async (streamId) => {
    setSelectedStream(streamId);
    setLoading(true);
    setError('');

    try {
      const studentsRes = await fetch(`https://cbc-orfl.onrender.com/students/grades/${selectedGrade}/streams/${streamId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
      });
      
      if (!studentsRes.ok) throw new Error('Network response was not ok');
      
      const studentsData = await studentsRes.json();
      setStudents(studentsData);
    } catch (error) {
      setError('Failed to fetch students.');
      console.error('Error fetching students:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = async (subjectId) => {
    setSelectedSubject(subjectId);
    setLoading(true);
    setError('');


  };

  const handleSelectStudent = (studentId) => {
    setSelectedStudents(prevSelected =>
      prevSelected.includes(studentId)
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleSelectAllStudents = () => {
    if (selectAllStudents) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(student => student.id));
    }
    setSelectAllStudents(!selectAllStudents);
  };


  const handleGenerateReportPerYear = async () => {
    const reportData = {
        grade_id: selectedGrade,
        stream_id: selectedStream,
        subject_id: selectedSubject,
        student_ids: selectedStudents,
        year_id: selectedYear,
    };

    try {
        const response = await fetch('https://cbc-orfl.onrender.com/generate_summative_report_year', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${retrieve().access_token}`,
            },
            body: JSON.stringify(reportData),
        });

        const data = await response.json();
        alert(data.message);
        navigate(-1)
       
    } catch (error) {
        console.error('Error generating report per year:', error);
        alert('Failed to generate report per year. Please try again.');
    }
};

const handleGenerateReportPerTerm = async () => {
    const reportData = {
        grade_id: selectedGrade,
        stream_id: selectedStream,
        subject_id: selectedSubject,
        student_ids: selectedStudents,
        year_id: selectedYear,
        term_id: selectedTerm,
    };

    try {
        const response = await fetch('https://cbc-orfl.onrender.com/generate_summative_report_term', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${retrieve().access_token}`,
            },
            body: JSON.stringify(reportData),
        });

        const data = await response.json();
        alert(data.message);
        navigate(-1)
    } catch (error) {
        console.error('Error generating report per term:', error);
        alert('Failed to generate report per term. Please try again.');
    }
};


  return (
    <div className="formative-report-container">
             <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                      <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
</div>
      <h2>Generate Summative Report</h2>

      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}

      <div className="tabs">
        <button
          className={currentTab === 'term' ? 'active' : ''}
          onClick={() => setCurrentTab('term')}
        >
          Per Term
        </button>
        <button
          className={currentTab === 'year' ? 'active' : ''}
          onClick={() => setCurrentTab('year')}
        >
          Per Year
        </button>
      </div>

      {currentTab === 'term' && (
        <div className="form-group">
           <label>Year:</label>
          <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
            <option value="">Select Year</option>
            {years.map(year => (
              <option key={year.id} value={year.id}>{year.year_name}</option>
            ))}
          </select>  
          <label>Term:</label>
          <select value={selectedTerm} onChange={(e) => setSelectedTerm(e.target.value)}>
            <option value="">Select Term</option>
            {terms.map(term => (
              <option key={term.id} value={term.id}>{term.term_name}</option>
            ))}
          </select>
        </div>
      )}

      {currentTab === 'year' && (
        <div className="form-group">
          <label>Year:</label>
          <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
            <option value="">Select Year</option>
            {years.map(year => (
              <option key={year.id} value={year.id}>{year.year_name}</option>
            ))}
          </select>
        </div>
      )}

<div className="form-group">
        <label>Grade:</label>
        <select value={selectedGrade} onChange={(e) => handleGradeChange(e.target.value)}>
          <option value="">Select Grade</option>
          {grades.map(grade => (
            <option key={grade.id} value={grade.id}>{grade.grade}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Stream:</label>
        <select value={selectedStream} onChange={(e) => handleStreamChange(e.target.value)}>
          <option value="">Select Stream</option>
          {streams.map(stream => (
            <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Subject:</label>
        <select value={selectedSubject} onChange={(e) => handleSubjectChange(e.target.value)}>
          <option value="">Select Subject</option>
          {subjects.map(subject => (
            <option key={subject.id} value={subject.id}>{subject.subject_name}</option>
          ))}
        </select>
      </div>

      

      <h3>Students</h3>
      <label>
        <input 
          type="checkbox" 
          checked={selectAllStudents} 
          onChange={handleSelectAllStudents} 
        />
        Select All Students
      </label>
      {students.map(student => (
        <div key={student.id} className="student-checkbox">
          <input 
            type="checkbox" 
            checked={selectedStudents.includes(student.id)} 
            onChange={() => handleSelectStudent(student.id)} 
          />
          {student.first_name} {student.last_name}
        </div>
      ))}

<button 
    className="assign-button3" 
    onClick={currentTab === 'year' ? handleGenerateReportPerYear : handleGenerateReportPerTerm}
>
    Generate Report
</button>

    </div>
  );
};

export default SummativeReportGenerator;
