import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { retrieve } from './Encryption'; // Ensure this utility is correctly implemented
import './EditStream.css'; // You can create a separate CSS file for styling
import { FaArrowLeft } from 'react-icons/fa';

const EditStream = () => {
    const { streamId } = useParams(); // Extract streamId from the URL parameters
    const [streamName, setStreamName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { streams, grades } = location.state;

    // Find the stream data in the passed location state
    const data = streams.find(stream => stream.id === streamId);

    useEffect(() => {
        if (data) {
            setStreamName(data.stream_name); // Set initial stream name from location state data
        } else {
            setError('Stream not found in the provided data.');
        }
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page refresh

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/streams/${streamId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
                body: JSON.stringify({ stream_name: streamName }), // Sending updated stream name
            });

            if (response.ok) {
                alert('Stream updated successfully.');
                navigate('/view-admin-dashboard/admin-view-streams'); // Navigate back to the streams view
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to update stream');
            }
        } catch (error) {
            setError('An error occurred while updating the stream.');
            console.error('Update Stream Error:', error);
        }
    };

    return (
        <div className="edit-stream-container">
             <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
                </div>
            <h2>Edit Stream</h2>

            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="streamName">Stream Name:</label>
                        <input
                            type="text"
                            id="streamName"
                            value={streamName}
                            onChange={(e) => setStreamName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" className="submit-button-special">Update Stream</button>
                        <button
                            type="button"
                            className="cancel-button-special"
                            onClick={() => navigate('/view-admin-dashboard/view-streams')}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditStream;
