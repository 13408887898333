import React, { useState, useEffect } from 'react';
import { retrieve } from './Encryption';
import { useNavigate } from 'react-router-dom';
import './GenerateFormativeReport.css';
import { FaArrowLeft } from 'react-icons/fa';

const GenerateBehaviourGoalsReport = () => {
  const [grades, setGrades] = useState([]);
  const [streams, setStreams] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedStream, setSelectedStream] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAllStudents, setSelectAllStudents] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const gradesRes = await fetch('https://cbc-orfl.onrender.com/grades', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (!gradesRes.ok) throw new Error('Network response was not ok');

        const gradesData = await gradesRes.json();
        setGrades(gradesData);
      } catch (error) {
        setError('Failed to fetch data. Please try again.');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGradeChange = async (gradeId) => {
    setSelectedGrade(gradeId);
    setSelectedStream('');
    setStudents([]);
   
    
    if (gradeId) {
      setLoading(true);
      try {
        const streamsRes = await fetch(`https://cbc-orfl.onrender.com/streams/grades/${gradeId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (!streamsRes.ok) throw new Error('Network response was not ok');

        const streamsData = await streamsRes.json();
        setStreams(streamsData);
      } catch (error) {
        setError('Failed to fetch streams.');
        console.error('Error fetching streams:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleStreamChange = async (streamId) => {
    setSelectedStream(streamId);
    setStudents([]);
   

    if (streamId && selectedGrade) {
      setLoading(true);
      try {
        const studentsRes = await fetch(`https://cbc-orfl.onrender.com/students/grades/${selectedGrade}/streams/${streamId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (!studentsRes.ok) throw new Error('Network response was not ok');

        const studentsData = await studentsRes.json();
        setStudents(studentsData);
        
        // Fetch class teacher when grade and stream are selected
       
      } catch (error) {
        setError('Failed to fetch students.');
        console.error('Error fetching students:', error);
      } finally {
        setLoading(false);
      }
    }
  };

   

  const handleSelectStudent = (studentId) => {
    setSelectedStudents(prevSelected =>
      prevSelected.includes(studentId)
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleSelectAllStudents = () => {
    if (selectAllStudents) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(student => student.id));
    }
    setSelectAllStudents(!selectAllStudents);
  };

  const handleGenerateReport = async () => {
    if (!selectedGrade || !selectedStream || !selectedStudents.length ) {
      alert('Please ensure all fields are selected, including students.');
      return;
    }

    const reportData = {
      grade_id: selectedGrade,
      stream_id: selectedStream,
      student_ids: selectedStudents,
    };

    try {
      const response = await fetch('https://cbc-orfl.onrender.com/generate_behaviour_report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${retrieve().access_token}`,
        },
        body: JSON.stringify(reportData),
      });

      const data = await response.json();
      alert(data.message);
      navigate(-1);
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Failed to generate report. Please try again.');
    }
  };

  return (
    <div className="formative-report-container">
             <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                      <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
</div>
      <h2>Generate Behaviour Goals Report</h2>
      
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      
      <div className="form-group">
        <label>Grade:</label>
        <select value={selectedGrade} onChange={(e) => handleGradeChange(e.target.value)}>
          <option value="">Select Grade</option>
          {grades.map(grade => (
            <option key={grade.id} value={grade.id}>{grade.grade}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Stream:</label>
        <select value={selectedStream} onChange={(e) => handleStreamChange(e.target.value)}>
          <option value="">Select Stream</option>
          {streams.map(stream => (
            <option key={stream.id} value={stream.id}>{stream.stream_name}</option>
          ))}
        </select>
      </div>

       <h3>Students</h3>
      <label>
        <input 
          type="checkbox" 
          checked={selectAllStudents} 
          onChange={handleSelectAllStudents} 
        />
        Select All Students
      </label>
      {students.map(student => (
        <div key={student.id} className="student-checkbox">
          <input 
            type="checkbox" 
            checked={selectedStudents.includes(student.id)} 
            onChange={() => handleSelectStudent(student.id)} 
          />
          {student.first_name} {student.last_name}
        </div>
      ))}

      <button className="assign-button3" onClick={handleGenerateReport}>Generate Report</button>
    </div>
  );
};

export default GenerateBehaviourGoalsReport;
