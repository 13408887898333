import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaChalkboardTeacher, FaBook, FaUserShield, FaUserEdit, FaUserTimes } from "react-icons/fa";
import { retrieve } from './Encryption';
import './ViewTeachers.css';
import { Segment} from 'semantic-ui-react';

const ViewTeachers = () => {
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [teachers, setTeachers] = useState([]);
    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingTeachers, setLoadingTeachers] = useState(false);
    const [errorGrades, setErrorGrades] = useState('');
    const [errorTeachers, setErrorTeachers] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    

    const navigate = useNavigate();

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    useEffect(() => {
        const fetchTeachers = async () => {
            setLoadingTeachers(true);
            setErrorTeachers('');
            try {
                let url = 'https://cbc-orfl.onrender.com/teachers';
                if (selectedGrade) {
                    url = `https://cbc-orfl.onrender.com/teachers/grades/${selectedGrade}`;
                }

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTeachers(data);
                    setFilteredTeachers(data); // Initialize filtered teachers
                } else {
                    const errorData = await response.json();
                    setErrorTeachers(errorData.error || 'Failed to fetch teachers');
                    setTeachers([]);
                    setFilteredTeachers([]);
                }
            } catch (error) {
                setErrorTeachers('An error occurred while fetching teachers.');
                console.error('Fetch Teachers Error:', error);
                setTeachers([]);
                setFilteredTeachers([]);
            } finally {
                setLoadingTeachers(false);
            }
        };

        fetchTeachers();
    }, [selectedGrade]);

    // Filter teachers based on search term
    useEffect(() => {
        const filtered = teachers.filter(teacher =>
            teacher.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            teacher.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            teacher.payroll_number.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredTeachers(filtered);
    }, [searchTerm, teachers]);

    const handleAssignGrade = () => {
        navigate('/view-admin-dashboard/assign-teacher-grade');
    };

    const handleAssignSubject = () => {
        navigate('/view-admin-dashboard/assign-teacher-subject');
    };

    const handleAssignClassTeacher = () => {
        navigate('/view-admin-dashboard/assign-class-teacher');
    };

    const handleTeacherClick = (teacherId) => {
        navigate(`/view-admin-dashboard/teacher-details/${teacherId}`, {
            state: {
                teachers,
                grades,
            }
        }); 
    };

    return (
        <div className="view-teachers-container">
            <h2 className='header'>View Teachers</h2>
            <div className="controls2">
            <Segment className="assign-buttons2">
                    <button className="assign-button2" style={{ backgroundColor: "#8ac429" }}  onClick={handleAssignGrade}>
                        <FaPlus /> Assign Teacher to Grade
                    </button>
                    <button   className="assign-button2"   style={{ backgroundColor: "#ffb134" }}   onClick={handleAssignSubject}
>
                    <FaBook /> Assign Teacher to Subject
                    </button>

                    <button className="assign-button2" style={{ backgroundColor: "#ff615d" }}  onClick={handleAssignClassTeacher}>
                        <FaUserShield /> Assign Class Teacher
                    </button>
                </Segment>
                <div className="grade-select">
                    <label htmlFor="grade">Filter by Grade:</label>
                    {loadingGrades ? (
                        <p>Loading grades...</p>
                    ) : errorGrades ? (
                        <p className="error-message">{errorGrades}</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                        >
                            <option value="">-- All Grades --</option>
                            {grades.map(grade => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by Payroll Number, First Name, or Last Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="teachers-table">
                {loadingTeachers ? (
                    <p>Loading teachers...</p>
                ) : errorTeachers ? (
                    <p className="error-message">{errorTeachers}</p>
                ) : filteredTeachers.length === 0 ? (
                    <p>No teachers found for the selected grade or search term.</p>
                ) : (
                    <table className="ui striped table">
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Payroll Number</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Is Class Teacher</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTeachers.map(teacher => (
                                <tr key={teacher.id} onClick={() => handleTeacherClick(teacher.id)} style={{ cursor: 'pointer' }}>
                                    <td>
                                        <img 
                                            src={teacher.photo_url} 
                                            alt={`${teacher.first_name} ${teacher.last_name}`} 
                                            style={{ width: '50px', height: '50px', borderRadius: '50%' }} 
                                        />
                                    </td>
                                    <td>{teacher.payroll_number}</td>
                                    <td>{teacher.first_name}</td>
                                    <td>{teacher.last_name}</td>
                                    <td>{teacher.email_address}</td>
                                    <td>{teacher.phone_number}</td>
                                    <td>{teacher.is_class_teacher ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ViewTeachers;
