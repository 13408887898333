import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { retrieve } from './Encryption'; // Adjust the import based on your project structure
import './TeacherDetails.css'; // Ensure this CSS file exists and is correctly styled

const TeachersViewTeacherDetails= () => {
    const { teacherId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { teachers, grades } = location.state;

    const teacher = teachers.find(teacher => teacher.id === teacherId); // Use passed data directly

    const [assignedGrades, setAssignedGrades] = useState([]);
    const [classTeacherAssignments, setClassTeacherAssignments] = useState([]);
    const [teacherSubjects, setTeacherSubjects] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
   
    const [deleteClassTeacherLoading, setDeleteClassTeacherLoading] = useState(false);
    const [deleteClassTeacherError, setDeleteClassTeacherError] = useState('');

    // Deletion states for teacher subjects
    const [deleteTeacherSubjectLoading, setDeleteTeacherSubjectLoading] = useState(false);
    const [deleteTeacherSubjectError, setDeleteTeacherSubjectError] = useState('');

    // Fetch assigned grades
    const fetchAssignedGrades = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/get_assigned_grades/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch assigned grades.');

            const data = await response.json();
            setAssignedGrades(data.assigned_grades);
        } catch (err) {
            console.error(err);
        }
    };

    // Fetch class teacher assignments
    const fetchClassTeacherAssignments = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/class_teacher/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch class teacher assignments.');

            const data = await response.json();
            setClassTeacherAssignments(Array.isArray(data) ? data : []);
        } catch (err) {
            console.error(err);
        }
    };

    // Fetch teacher subjects
    const fetchTeacherSubjects = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/teacher/${teacherId}/subjects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch teacher subjects.');

            const data = await response.json();
            setTeacherSubjects(Array.isArray(data.assignments) ? data.assignments : []);
        } catch (err) {
            console.error(err);
        }
    };
   




    useEffect(() => {
        fetchAssignedGrades();
        fetchClassTeacherAssignments();
        fetchTeacherSubjects();
      

    }, [teacherId]);

    return (
        <div className="teacher-details-container">
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>

            {teacher ? (
                <>
                    <h2 style={{ color: '#3CB043' }}>
                        {teacher.first_name} {teacher.last_name}
                    </h2>
                    <div className="personal-details">
                        <img
                            src={teacher.photo_url}
                            alt={`${teacher.first_name} ${teacher.last_name}`}
                            className="teacher-photo"
                        />
                        <div className="details">
                            <p><strong>Payroll Number:</strong> {teacher.payroll_number}</p>
                            <p><strong>Email:</strong> {teacher.email_address}</p>
                            <p><strong>Phone Number:</strong> {teacher.phone_number}</p>
                        </div>
                    </div>

                    {/* Assigned Grades Section */}
                    <div className="assigned-grades">
                        <h3 style={{ textAlign: 'left' }}>Assigned Grades</h3>
                        {assignedGrades.length > 0 ? (
                            <ul>
                                {assignedGrades.map((grade) => (
                                    <li key={grade.grade_id} className="grade-item">
                                        {grade.grade_name}
                                    
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No grades assigned to this teacher.</p>
                        )}
                    </div>

                    {/* Class Teacher Assignments Section */}
                    <div className="class-teacher-assignments">
                        <h3 style={{ textAlign: 'left' }}>Class Teacher Assignments</h3>
                        {classTeacherAssignments.length > 0 ? (
                            <ul>
                                {classTeacherAssignments.map((assignment) => (
                                    <li key={assignment.id} className="assignment-item">
                                        <strong>Grade:</strong> {assignment.grade_name}{' '}
                                        <strong>Stream:</strong> {assignment.stream_name}
                                    
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No assigned class for class teacher assignments.</p>
                        )}
                    </div>

                    {/* Teacher Subjects Section */}
                    <div className="teacher-subjects">
                        <h3 style={{ textAlign: 'left' }}>Assigned Subjects</h3>
                        {teacherSubjects.length > 0 ? (
                            <ul>
                                {teacherSubjects.map((subject) => (
                                    <li key={subject.id} className="subject-item">
                                        {subject.subject_name}
                                    
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No assigned subjects.</p>
                        )}
                    </div>

                    {successMessage && <p className="success-message">{successMessage}</p>}
                </>
            ) : (
                <p>Teacher not found.</p>
            )}
        </div>
    );
};

export default TeachersViewTeacherDetails;
