import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from "react-icons/fa";
import { retrieve } from './Encryption';
import './ViewBehaviourGoals.css'; // Ensure this CSS file exists for styling

const ViewBehaviourGoals = () => {
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [behaviourGoals, setBehaviourGoals] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingGoals, setLoadingGoals] = useState(false);
    const [errorGrades, setErrorGrades] = useState('');
    const [errorGoals, setErrorGoals] = useState('');
    const navigate = useNavigate();

    // Fetch grades when component mounts
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/grades', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGrades(data);

                    // Find the grade with name "PP1" to set as default
                    const defaultGrade = data.find(grade => grade.grade === "PP1");
                    if (defaultGrade) {
                        setSelectedGrade(defaultGrade.id);
                    } else if (data.length > 0) {
                        // Fallback to the first grade if "PP1" is not found
                        setSelectedGrade(data[0].id);
                    }
                } else {
                    const errorData = await response.json();
                    setErrorGrades(errorData.error || 'Failed to fetch grades');
                }
            } catch (error) {
                setErrorGrades('An error occurred while fetching grades.');
                console.error('Fetch Grades Error:', error);
            } finally {
                setLoadingGrades(false);
            }
        };

        fetchGrades();
    }, []);

    // Fetch behaviour goals when selectedGrade changes
    useEffect(() => {
        const fetchBehaviourGoals = async () => {
            if (selectedGrade) {
                setLoadingGoals(true);
                setErrorGoals('');
                try {
                    const response = await fetch(`https://cbc-orfl.onrender.com/fetch_behaviour_goals/${selectedGrade}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${retrieve().access_token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setBehaviourGoals(data);
                    } else {
                        const errorData = await response.json();
                        setErrorGoals(errorData.error || 'Failed to fetch behavior goals');
                    }
                } catch (error) {
                    setErrorGoals('An error occurred while fetching behavior goals.');
                    console.error('Fetch Behaviour Goals Error:', error);
                } finally {
                    setLoadingGoals(false);
                }
            } else {
                setBehaviourGoals([]);
            }
        };

        fetchBehaviourGoals();
    }, [selectedGrade]);

    const handleAddBehaviourGoal = () => {
        navigate('/view-admin-dashboard/add-behaviour-goals'); // Navigate to the add behavior goal page
    };

    const handleEditClick = (goalId) => {
        // Find the grade name based on the selectedGrade
        const grade = grades.find(g => g.id === selectedGrade);
        const gradeName = grade ? grade.grade : 'Unknown Grade';

        // Navigate to the edit page with gradeName passed as state
        navigate(`/view-admin-dashboard/edit-behaviour-goal/${goalId}`, { state: { grades, behaviourGoals, gradeName } });
    };

    const handleDeleteClick = async (goalId) => {
        if (window.confirm("Are you sure you want to delete this behavior goal?")) {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/behaviour_goals/${goalId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    setBehaviourGoals(prevGoals => prevGoals.filter(goal => goal.id !== goalId));
                    alert('Behavior goal deleted successfully.');
                } else {
                    const errorData = await response.json();
                    alert(errorData.error || 'Failed to delete behavior goal');
                }
            } catch (error) {
                console.error('Delete Behaviour Goal Error:', error);
                alert('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="view-behaviour-goals-container">
            <h2 className='header'>Behaviour Goals</h2>
            <div className="controls">
                <div className="grade-select">
                    <label htmlFor="grade">Select Grade:</label>
                    {loadingGrades ? (
                        <p>Loading grades...</p>
                    ) : errorGrades ? (
                        <p className="error-message">{errorGrades}</p>
                    ) : (
                        <select
                            id="grade"
                            value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                        >
                            <option value="">-- Select Grade --</option>
                            {grades.map(grade => (
                                <option key={grade.id} value={grade.id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <button
                    className="add-behaviour-goal-button"
                    onClick={handleAddBehaviourGoal}
                >
                    Add Behaviour Goal
                </button>
            </div>

            {loadingGoals ? (
                <p>Loading behavior goals...</p>
            ) : errorGoals ? (
                <p style={{ color: 'red' }}>{errorGoals}</p>
            ) : behaviourGoals.length === 0 ? (
                <p>No behavior goals found for this grade.</p>
            ) : (
                <table className="ui celled table">
                    <thead>
                        <tr>
                            <th>Behaviour Goal</th>
                            <th style={{ width: '300px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {behaviourGoals.map(goal => (
                            <tr key={goal.id}>
                                <td>{goal.behaviour_goal}</td>
                                <td>
                                                <div style={{ display: 'flex', alignItems: 'center', gap:"30px" }}>
                                                    <div 
                                                        style={{ cursor: 'pointer',marginLeft:"40px" }} 
                                                        onClick={() => handleEditClick(goal.id)}
                                                    >
                                                        <FaEdit style={{ color: 'green', fontSize: '20px' }} />
                                                        <span style={{ fontSize: '12px' }}>Edit</span>
                                                    </div>
                                                    <div 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => handleDeleteClick(goal.id)}
                                                    >
                                                        <FaTrash style={{ color: 'red', fontSize: '20px' }} />
                                                        <span style={{ fontSize: '12px' }}>Delete</span>
                                                    </div>
                                                </div>
                                            </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );

};

export default ViewBehaviourGoals;
