import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrieve } from "./Encryption"; // Import your decryption method
import {jwtDecode} from "jwt-decode"; // Import jwt-decode if not default-exported
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import eye icons for password visibility
import './LoginChangePassword.css';

import Header from "./Header"

const LoginChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState(""); // Added email address state
  const [error, setError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle confirm password visibility
  const navigate = useNavigate();

  // Retrieve and decode the token
  const decryptedToken = retrieve();
  let designationCode = null;

  if (decryptedToken && decryptedToken.access_token) {
    const decodedToken = jwtDecode(decryptedToken.access_token);
    designationCode = decodedToken.designation;
  }

  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
  const handleEmailAddressChange = (e) => setEmailAddress(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      // Call the API to change the password
      fetch("https://cbc-orfl.onrender.com/change_password_on_login", {
        method: "PATCH",
        headers: { 
          "Content-Type": "application/json",
          Authorization: `Bearer ${decryptedToken.access_token}` 
        },
        body: JSON.stringify({ email_address: emailAddress, newPassword, confirmPassword }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.detail) {
            // Password change successful, now log out the user
            navigate("/");
            fetch("https://cbc-orfl.onrender.com/logout", {
              method: "GET",
              headers: {
                Authorization: `Bearer ${decryptedToken.access_token}`,
              },
            })
              .then((logoutResponse) => logoutResponse.json())
              .then((logoutData) => {
                if (logoutData.detail) {
                  setTimeout(() => {
                    alert(data.detail); // Display success message
                    // Redirect to login page after successful logout
                  }, 5000); // Redirect after 5 seconds
                }
              })
              .catch((err) => setError("Logout failed: " + err.message));
          } else {
            setError("Password change failed.");
          }
        })
        .catch((err) => setError("An error occurred: " + err.message));
    } else {
      setError("Passwords do not match.");
    }
  };

  return (
    <div>  <Header/>
    <div className="change-password-container">
    
      <h2>Change Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email Address:</label>
          <input
            type="email"
            value={emailAddress}
            onChange={handleEmailAddressChange}
            required
          />
        </div>
        <div>
          <label>New Password:</label>
          <div className="password-input-container">
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleNewPasswordChange}
              required
            />
            <button
              type="button"
              className="eye-icon"
              onClick={() => setShowNewPassword((prev) => !prev)}
            >
              {showNewPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>
        <div>
          <label>Confirm New Password:</label>
          <div className="password-input-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            <button
              type="button"
              className="eye-icon"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>
        <button type="submit" style={{ backgroundColor:"#8ac429"}}>Submit</button>
        {error && <p>{error}</p>}
      </form>
    </div>
    </div>
  );
};

export default LoginChangePassword;
